<style scoped lang="scss">
  
</style>

<template>
  <div class="loginlogs_list_box">
    <el-table :data="tableData">
      <el-table-column type="expand" fixed>
        <template slot-scope="props">
          <el-form label-position="left" inline class="c-table-expand">
            <el-form-item label="ID">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="用户ID">
              <span>{{ props.row.userId }}</span>
            </el-form-item>
            <el-form-item label="用户名">
              <span>{{ props.row.userNameOrEmailAddress }}</span>
            </el-form-item>
            <el-form-item label="IP">
              <span>{{ props.row.clientIpAddress }}</span>
            </el-form-item>
            <el-form-item label="登录时间">
              <span>{{ props.row.creationTime }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="用户ID" prop="userId"></el-table-column>
      <el-table-column label="用户名" prop="userNameOrEmailAddress"></el-table-column>
      <el-table-column label="IP" prop="clientIpAddress" v-if="$root.isPc"></el-table-column>
      <el-table-column label="登录时间" prop="creationTime"></el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
      :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
      layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  import * as loginLogs from '@/api/system/user'
  export default {
    name: "loginLogs",
    data() {
      return {
        tableData: [],
        total: null,
        listQuery: {
          keywords: null,
          page: 1,
          size: window.$common.appPageSize
        },
        isPagination: false
      }
    },
    mounted() {
      this.getListData()
    },
    methods: {
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getListData()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getListData()
      },
      getListData() {
        window.$common.fullLoading()
        loginLogs.getLoginLogs(this.listQuery).then(response => {
          window.$common.closeFullLoading()
          this.total = response.totalCount
          this.isPagination = response.totalCount > 0 || false
          if (response && response.items && response.items.length > 0) {
            this.tableData = response.items
          } else {
            this.tableData = []
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
.page-analysis-vehicle-daily {}
</style>

<template>
    <div class="page-analysis-vehicle-daily">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <!--<el-upload class="dib mrgr5" :action="uploadUrl" :headers="uploadHeaders" 
                        :on-success="importData" :on-error="importData" :show-file-list="false">
                        <el-button type="warning" size="medium"><i class="fa fa-sign-in"></i> 导入车辆统计数据</el-button>
                    </el-upload>-->
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime mrgr5 mrgb5" v-model="selectedTime" type="date" size="medium" 
                        @change="_search" placeholder="选择日期" :clearable="false">
                    </el-date-picker>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="small">
                <el-table-column label="日期(星期)" prop="date" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.date }}<br />
                        <el-tag type="parmary" size="mini">{{ getFormatWeek(scope.row.date) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="服务区" prop="area"></el-table-column>
                <el-table-column label="车辆编号" prop="vehicleId"></el-table-column>
                <el-table-column label="设备号" prop="deviceNumber"></el-table-column>
                <el-table-column label="订单总数" prop="orderCount"></el-table-column>
                <el-table-column label="订单总价" prop="orderTotalPrice"></el-table-column>
                <el-table-column label="骑行里程" prop="ridingDistance"></el-table-column>
                <el-table-column label="骑行总时长" prop="ridingDuration"></el-table-column>
                <el-table-column label="低电丢单" prop="lowPowerOrderCount"></el-table-column>
                <el-table-column label="运维丢单" prop="maintenanceOrderCount"></el-table-column>
                <el-table-column label="换电次数" prop="batterySwapCount"></el-table-column>
                <el-table-column label="报修次数" prop="repairCount"></el-table-column>
                <el-table-column label="挪车次数" prop="relocationCount"></el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from "moment"
export default {
    name: "pageAnalysisVehicleDaily",
    data() {
        let yestoday = moment().subtract(1, "days") // 默认前一天的数据
        return {
            uploadUrl: window.$common.appBaseApi() + "/api/app/vehicle-daily-record/import",
            uploadHeaders: window.$common.appHeaders(),
            tableData: [],
            total: null,
            listQuery: {
                area: "",
                beginDate: yestoday.format("YYYY-MM-DD 00:00:00"),
                endDate: yestoday.format("YYYY-MM-DD 23:59:59"),
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            selectedTime: yestoday // 默认前一天的数据
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        },
        selectedTime(v) {
            if (v && moment(v).isValid()) {
                this.listQuery.beginDate = moment(v).format("YYYY-MM-DD 00:00:00")
                this.listQuery.endDate = moment(v).format("YYYY-MM-DD 23:59:59")
            } else {
                this.listQuery.beginDate = moment().format("YYYY-MM-DD 00:00:00")
                this.listQuery.endDate = moment().format("YYYY-MM-DD 23:59:59")
            }
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        getDataList() {
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funReport.GetVehicleDailyReports(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map((x, i) => {
                        // x.sort = (this.listQuery.page - 1) * this.listQuery.size + (i + 1)
                        x.date = moment(x.date).format("YYYY-MM-DD")
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        // 导入
        importData(res) {
            if (res >= 0) {
                this.alert("导入成功!").then(() => {
                    this._search()
                })
            } else {
                this.alert("导入失败，可能数据错误或者有重复数据!")
            }
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>
<style scoped lang="scss">
    .page-vehicle-statistics {
        .c-datetime-range {
            min-width: 360px;
        }
    }
</style>

<template>
    <div class="page-vehicle-statistics">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="daterange" size="medium"
                        :picker-options="pickerOptions" @change="getDataList()"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                    <el-button type="primary" size="medium" @click="getDataList()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" content="导出订单车辆统计数据" placement="top-start">
                        <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 导出</el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="重新处理订单数据，便于获得更准确的订单车辆统计数据" placement="top-start">
                        <el-button class="mrgr5 mrgb5" type="danger" size="medium" @click="_updateState()"><i class="el-icon-refresh"></i> 处理订单数据</el-button>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <el-table :data="tableData" style="width: 100%" border size="medium">
            <el-table-column label="日期" prop="date"></el-table-column>
            <el-table-column label="12小时无单数量" prop="noOrder12Count"></el-table-column>
            <el-table-column label="清淤数量" prop="desiltingCount"></el-table-column>
            <el-table-column label="破损数量" prop="brokenCount"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import * as funReport from '@/api/report'
import moment from "moment"
import { OrderVehicleStatistics } from "@/mixins/commonPageExport"
export default {
    name: "pageVehicleStatistics",
    data() {
        let that = this
        return {
            listQuery: {
                area: "",
                beginTime: "",
                endTime: ""
            },
            tableData: [],
            selectedTime: [moment().startOf("month").format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getDataList()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.selectedTime = [timeArr[0] || "", timeArr[1] || ""]
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD 00:00:00")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD 23:59:59")
            }
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [this.listQuery.beginTime, this.listQuery.endTime]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funReport.GetOrderVehicleStatistics(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                if (response && response.length > 0) {
                    this.tableData = response
                } else {
                    this.tableData = []
                }
            })
        },
        _updateState() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD 00:00:00")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD 23:59:59")
            }
            let params = {
                area: this.listQuery.area,
                beginTime: psTime,
                endTime: peTime
            }
            if (!params.beginTime || !params.endTime) {
                this.warningMsg("请选择更新时间段!")
                return false
            }
            window.$common.fullLoading()
            funReport.SetOrderHandle(params).then(() => {
                window.$common.closeFullLoading()
                this.getDataList()
                this.successMsg("订单处理成功!")
            })
        },
        openExportExcel() {
            if (this.total > this.maxExportCount) {
                this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
                return false
            }
            let searchs = JSON.parse(JSON.stringify(this.listQuery))
            window.$common.fullLoading()
            funReport.GetOrderVehicleStatistics(searchs).then(res => {
                if (res && res.length > 0) {
                    let tableDatas = res
                    let exportData = ""
                    if (searchs.beginTime && searchs.endTime) {
                        let sbtd = moment(searchs.beginTime).format("YYYY-MM-DD")
                        let ebtd = moment(searchs.endTime).format("YYYY-MM-DD")
                        exportData = sbtd == ebtd ? sbtd : `${sbtd}至${ebtd}`
                    } else {
                        exportData = "全部"
                    }
                    let exportName = `${this.$store.getters.serviceArea}的订单车辆统计数据(${exportData}).xlsx`
                    OrderVehicleStatistics(exportName, tableDatas)
                } else {
                    window.$common.closeFullLoading()
                    setTimeout(() => {
                        this.alert("没有需要导出的数据!")
                    }, 10)
                }
            }).catch(() => {
                console.log("export error")
            })
        }
    }
}
</script>
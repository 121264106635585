<style scoped lang="scss">

</style>

<template>
  <div class="page-maintenance-day-statistics">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fr">
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="员工的姓名/手机号" clearable v-model="listQuery.keywords"
                    size="medium"></el-input>
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                    :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    align="right">
                </el-date-picker>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium">
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="联系电话" prop="tel"></el-table-column>
        <el-table-column label="正常" prop="normal"></el-table-column>
        <el-table-column label="未处理" prop="unHandled"></el-table-column>
        <el-table-column label="迟到" prop="late"></el-table-column>
        <el-table-column label="早退" prop="leaveEarly"></el-table-column>
        <el-table-column label="缺卡" prop="lack"></el-table-column>
        <el-table-column label="操作" align="center" width="120" fixed="right">
            <template slot-scope="scope">
                <el-button type="primary" plain size="mini" @click="navTo(scope.row)">查看记录</el-button>
            </template>
        </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import permission from "@/mixins/permission"
  import enumConfigs from "@/mixins/enumConfigs"
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  export default {
    name: "pageMaintenanceDayStatistics",
    mixins: [permission, enumConfigs],
    data() {
      let that = this
      return {
        tableData: [],
        listQuery: {
            area: "",
            keywords: "",
            startDate: "",
            endDate: ""
        },
        selectedTime: [],
        pickerOptions: {
            shortcuts: [
                {
                    text: "昨天",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "days")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一周",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "weeks")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近三月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 3, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近半年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 6, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "years")
                        that.setQueryTime(timeArr)
                    }
                }
            ]
        }
      }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.startDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.startDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.startDate, this.listQuery.endDate]
        },
        getDataList () {
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funMaintenance.DayWorkStatistics(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                if (response && response.length > 0) {
                    this.tableData = response.map(x => {
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        navTo(row) {
            this.$router.push({ name: "pageMaintenanceDayWorks", params: { keyword: row.tel, selectedTime: this.selectedTime } })
        },
        _search() {
            this.getDataList()
        }
    }
  }
</script>
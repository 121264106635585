import request from '@/utils/request'

export function userLogin(data) {
  return request({
    url: '/api/auth/token',
    method: 'post',
    data
  })
}

export function connectToken(data) {
  return request({
    url: '/connect/token',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/api/app/session',
    method: 'get',
    params: token
  })
}

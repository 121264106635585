<style lang="scss" scoped>
.page-statistics-orders {}
</style>
<template>
    <div class="page-statistics-orders">
        <div class="mrgb20">
            <el-button-group class="mrgr5 mrgb5">
                <el-button type="primary" size="small" v-for="item in selectTimes" :key="item"
                    :plain="curSelectTime != item" @click="selectRange(item)">{{ item }}月</el-button>
                <el-button type="primary" size="small" :plain="curSelectTime > 0" @click="selectRange(-1)">自定义时间段</el-button>
            </el-button-group>
            <span v-show="isShowSelectTime">
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="small"
                :default-time="['00:00:00', '23:59:59']" @change="getSearchData" :clearable="false"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                align="right">
                </el-date-picker>
                <el-button class="mrgr5 mrgb5" type="primary" size="small" @click="getSearchData()"><i class="fa fa-search"></i> 查询</el-button>
            </span>
        </div>
        <el-row :gutter="15">
            <el-col :xs="24" class="mrgb20" v-for="(item, index) in usersOptions" :key="'userEchartKey' + index">
                <el-card>
                    <span slot="header">
                        {{ usersLegends[index] }}
                        <el-tooltip effect="dark" :content="usersTips[index]" placement="top">
                            <el-tag class="fr" type="info" size="small"><i class="el-icon-view f12"></i> 查看注释</el-tag>
                        </el-tooltip>
                        <span class="db"></span>
                    </span>
                    <echart :id="'userEchart' + index" title="" width="100%" height="520px" :option="item"></echart>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import echart from "@/components/Echarts/Index"
import moment from "moment"
export default {
    name: "pageStatisticsOrders",
    components: { echart },
    data() {
        return {
            listQuery: {
                area: "",
                beginDate: "",
                endDate: ""
            },
            selectTimes: [],
            curSelectTime: null,
            isShowSelectTime: false,
            selectedTime: [],
            reportData: {},
            vehicleCommonOptions: {}, // 投放总数，可用车数量，占比
            usersLegends: ["订单量"],
            usersTips: ["查询服务区的每日订单数量"],
            usersOptions: []
        }
    },
    watch: {
      "$store.getters.serviceArea"(v) {
          this.mxIsSameActiveTabWithRoute(() => {
              this.getSearchData()
          })
      }
    },
    mounted() {
        let curMonth = moment().months() + 1
        this.curSelectTime = curMonth
        for (let i = 0; i < curMonth; i++) {
            this.selectTimes.push(i + 1)
        }
        this.selectTimes.reverse()
        for (let i in this.usersLegends) {
            this.usersOptions.push({})
        }
        this.getSearchData()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] ? moment(timeArr[0]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
            this.listQuery.endDate = timeArr[1] ? moment(timeArr[1]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.curSelectTime > 0) {
                let curYear = moment().years()
                if (this.curSelectTime == moment().months() + 1) {
                    let curDay = moment().dates()
                    // 是当前月份
                    if (curDay == 1) {
                        let curDate = moment().format("YYYY年MM月DD日")
                        this.warningMsg(`今天是${curDate}，只能看上个月的数据!`)
                        // 当月1号，直接看上个月的数据
                        this.curSelectTime = this.curSelectTime - 1
                        this.listQuery.beginDate = moment([curYear, this.curSelectTime - 1]).startOf("month").format("YYYY-MM-DD")
                        this.listQuery.endDate = moment([curYear, this.curSelectTime - 1]).endOf("month").format("YYYY-MM-DD")
                    } else {
                        // 否则看当月的数据
                        this.listQuery.beginDate = moment().startOf("month").format("YYYY-MM-DD")
                        this.listQuery.endDate = moment().subtract(1, "days").format("YYYY-MM-DD")
                    }
                } else {
                    // 不是当前月份
                    this.listQuery.beginDate = moment([curYear, this.curSelectTime - 1]).startOf("month").format("YYYY-MM-DD")
                    this.listQuery.endDate = moment([curYear, this.curSelectTime - 1]).endOf("month").format("YYYY-MM-DD")
                }
            } else {
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
                }
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
                }
                this.listQuery.beginDate = psTime
                this.listQuery.endDate = peTime
                this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
            }
        },
        getSearchData() {
            if (this.curSelectTime < 0 && (!this.selectedTime[0] || !this.selectedTime[1])) {
                this.warningMsg("请选择日期范围!")
                return false
            }
            window.$common.fullLoading()
            this.getDataList()
        },
        async getDataList() {
            this.setSelectedTime()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            await funReport.GetOrderNumStatistics(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.reportData = response.data_list
                // 新增用户，有效骑行用户，活跃用户，高活用户
                let options = []
                for (let i in this.usersLegends) {
                    options[i] = this.setUsersOptions(this.usersLegends[i])
                }
                this.usersOptions = options
            })
        },
        setUsersOptions() {
            let legendData = ["应收订单", "实收订单"]
            let xAxisData = []
            let series = [
                {
                    name: legendData[0],
                    type: "line",
                    data: [],
                    label: {
                        show: true,
                        distance: 10,
                        position: 'top',
                        color: "#0e66d3"
                    }
                },
                {
                    name: legendData[1],
                    type: "line",
                    data: [],
                    label: {
                        show: true,
                        distance: 10,
                        position: 'bottom',
                        color: "#d60aa3"
                    }
                }
            ]
            let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
            // 横坐标
            for (let i = 0; i <= days; i++) {
                let time = moment(this.listQuery.beginDate).add(i, "days")
                xAxisData.push(moment(time).format("YYYY-MM-DD"))
            }
            for (let i = 0; i < legendData.length; i++) {
                series[0].data = this.reportData.map(x => Number(x.num))
                series[1].data = this.reportData.map(x => Number(x.paid_num))
            }
            let curOptions = {
                title: {
                    text: this.listQuery.area
                },
                tooltip: {
                    trigger: "axis"
                },
                legend: {
                    data: legendData,
                    selected: {
                        "实收订单": false
                    }
                },
                grid: {
                    left: '1%',
                    right: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                dataZoom: {
                    show: true,
                    // left: "3%",
                    // width: "90%",
                    start: 0,
                    end: 100,
                    labelFormatter: function (value) {
                        let prev = moment(xAxisData[value]).format("YYYY")
                        let next = moment(xAxisData[value]).format("MM/DD")
                        return prev + "\n" + next;
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    axisLabel: {
                        rotate: 30,
                        interval: 0,
                        // showMinLabel: true,
                        // showMaxLabel: true
                    },
                    data: xAxisData,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "#e8e8e8"
                        }
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        axisTick: {},
                        axisLabel: {
                            formatter: "{value}"
                        }
                    }
                ],
                series: series
            }
            return curOptions
        },
        selectRange(time) {
            this.curSelectTime = time
            this.isShowSelectTime = time > 0 ? false : true
            this.selectedTime = time > 0 ? [] : this.selectedTime
            if (time > 0) {
                this.getSearchData()
            }
        }
    }
}
</script>
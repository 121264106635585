<style lang="scss" scoped>
/deep/ .comp-spider-history-dialog {
    margin-top: 5vh !important;
    width: 88%;
    
}
</style>

<template>
    <div>
        <el-dialog :title="title" custom-class="c-el-dialog comp-spider-history-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <div class="mrgb10">
                <div class="fr">
                    <el-button size="medium" type="primary" @click="openSpider()">执行{{ spiderName }}</el-button>
                    <el-button size="medium" type="warning" @click="getListData()">刷新数据</el-button>
                </div>
                <div class="clearb"></div>
            </div>
            <el-alert class="mrgb10" title="最多只显示最近20条的日志历史记录。" type="warning" :closable="false"></el-alert>
            <el-table :data="tableData" border size="small">
                <el-table-column label="服务区">
                    <template>
                        {{ areaName }}
                    </template>
                </el-table-column>
                <el-table-column label="开始时间" prop="startTime" :formatter="tableDateFormat"></el-table-column>
                <el-table-column label="结束时间" prop="endTime" :formatter="tableDateFormat"></el-table-column>
                <el-table-column label="执行参数" min-width="300">
                    <template slot-scope="scope">
                        <span v-if="scope.row.params">{{ scope.row.params }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="执行状态" prop="isSuccess" width="100">
                    <template slot-scope="scope">
                        <el-tag type="success" size="small" v-if="scope.row.isSuccess">执行成功</el-tag>
                        <el-tag type="danger" size="small" v-else>执行失败</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="执行结果" prop="remark" width="400">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSuccess">{{ scope.row.remark }}</span>
                        <el-button type="danger" plain size="mini" v-else @click="showError(scope.row.remark)">查看错误信息</el-button>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80" v-if="spiderName.indexOf('服务区') === -1">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain @click="openSpider(scope.row.params)">重爬</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
            </span>
        </el-dialog>

        <!--爬虫-->
        <spider :ref="refSpider"></spider>
    </div>
</template>

<script>
import * as funSpider from "@/api/spider"
import spider from "./spider"
export default {
    name: "compSpiderHistory",
    components: { spider },
    data() {
        return {
            refSpider: "refSpiderHistoryToSpider",
            title: "",
            showDialog: false,
            tableData: [],
            spiderName: "",
            areaName: "",
            spiderId: ""
        }
    },
    mounted() {},
    methods: {
        async open(row) {
            // row.serviceName, row.spiderName, row.logs, row.id
            let areaName = row.serviceName || "--"
            let spiderName = row.spiderName || ""
            this.areaName = areaName
            this.spiderName = spiderName
            spiderName = spiderName ? `【${spiderName}】` : ""
            this.title = `${areaName}${spiderName}记录`
            this.spiderId = row.id || ""
            await this.getListData(row.logs)
            this.showDialog = true
        },
        async getListData() {
            let datas = []
            if (this.spiderId) {
                window.$common.fullLoading()
                await funSpider.getSpiderDetail({ id: this.spiderId }).then(res => {
                    datas = res.logs || []
                })
            }
            window.$common.closeFullLoading()
            this.tableData = datas.map(x => {
                let args = x.args
                x.params = ""
                if (args && $util.isJsonFn(args)) {
                    args = JSON.parse(args)
                    x.params = JSON.parse(JSON.stringify(args))
                    if (x.params.Date) {
                        x.params.Date = this.getFormatDate(x.params.Date, null, "YYYY-MM-DD")
                    }
                    if (x.params.BeginDate) {
                        x.params.BeginDate = this.getFormatDate(x.params.BeginDate)
                    }
                    if (x.params.EndDate) {
                        x.params.EndDate = this.getFormatDate(x.params.EndDate)
                    }
                }
                return x
            })
        },
        openSpider(params) {
            this.$refs[this.refSpider].open({ areaName: this.areaName, spiderName: this.spiderName, params })
        },
        showError(content) {
            this.alert(content, "错误信息", {
                type: "none",
                customClass: "spider-alert"
            })
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>
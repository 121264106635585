import request from '@/utils/request'

// 用户管理
export function create(data) {
  return request({
    url: '/api/app/user',
    method: 'post',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/api/app/user/' + data.id,
    method: 'get',
    params: data
  })
}

export function getAll(data) {
  return request({
    url: '/api/app/user',
    method: 'get',
    params: data
  })
}

export function update(data) {
  return request({
    url: '/api/app/user/' + data.id,
    method: 'put',
    data
  })
}

export function remove(data) {
  return request({
    url: '/api/app/user/' + data.id,
    method: 'delete',
    params: data
  })
}

// 登陆日志
export function getLoginLogs(data) {
  return request({
    url: '/api/services/app/User/GetLoginLogs',
    method: 'get',
    params: data
  })
}

// 操作日志
export function getAuditLogs(data) {
  return request({
    url: '/api/app/audit-log',
    method: 'get',
    params: data
  })
}

export function changePassword(data) {
  return request({
    url: '/api/app/user/password',
    method: 'put',
    data
  })
}

export function updateUserPassword(data) {
  return request({
    url: '/api/app/user/' + data.id + '/password',
    method: 'put',
    data
  })
}

export function getUserRoles(data) {
  return request({
    url: '/api/app/user/' + data.id + '/roles',
    method: 'get'
  })
}

export function updateUserRoles(data) {
  return request({
    url: '/api/app/user/' + data.id + '/roles',
    method: 'put',
    data
  })
}

export function getAssignableRoles(data) {
  return request({
    url: '/api/app/user/assignable-roles',
    method: 'get',
    params: data
  })
}

export function findByUserName(data) {
  return request({
    url: '/api/app/user/find-by-username',
    method: 'post',
    data
  })
}

export function findByEmail(data) {
  return request({
    url: '/api/app/user/find-by-username',
    method: 'post',
    data
  })
}

// 获取当前用户能看的服务区
export function getCurrentUserAreas(data) {
  return request({
    url: '/api/app/user/service-areas',
    method: 'get',
    params: data
  })
}

export function getUserAreasById(data) {
  return request({
    url: '/api/app/user/service-area-ids/' + data.id,
    method: 'get'
  })
}

export function setUserAreasById(data) {
  return request({
    url: '/api/app/user/service-area-ids/' + data.id,
    method: 'put',
    data: data.data
  })
}

<style lang="scss">
.comp-move-park-batch {
    width: 60% !important;
    min-width: 300px !important;
    .el-drawer__header {
        margin-bottom: 0;
        padding: 10px 15px 0;
    }
    .total {
        margin: 15px 0 0 15px;
    }
    .el-table {
        width: auto;
        margin: 15px;
    }
}
</style>

<template>
    <div>
        <el-drawer custom-class="comp-move-park-batch" :title="title" :visible.sync="showDrawer" :destroy-on-close="true">
            <el-tag class="total">挪入总量：{{ total }}辆</el-tag>
            <el-table :data="tableData" size="mini" border show-summary>
                <el-table-column label="批次号" prop="record_id"></el-table-column>
                <el-table-column label="挪车人" prop="operator"></el-table-column>
                <el-table-column label="挪车人手机" prop="phone" min-width="120"></el-table-column>
                <el-table-column label="挪车完成时间" prop="endTime" min-width="150" :formatter="tableDateFormat"></el-table-column>
                <el-table-column label="挪入数量" prop="count"></el-table-column>
                <el-table-column v-for="(t, index) in types" :prop="'s' + t" :key="'b-' + index">
                    <template #header>{{ t }}小时内</template>
                    <template slot-scope="scope">{{ scope.row["s" + t] }}</template>
                </el-table-column>
            </el-table>
        </el-drawer>
    </div>
</template>

<script>
import * as funMaintenance from "@/api/maintenance"
export default {
  name: 'compMoveParkBatch',
  data () {
    return {
        showDrawer: false,
        title: "",
        total: 0,
        tableData: [],
        types: []
    }
  },
  methods: {
    open(params, types) {
        this.types = types
        this.tableData = []
        this.title = `站点名称：${params.name}` || "站点详情"
        funMaintenance.GetMoveTask(params).then(res => {
            this.total = res.totalCount
            let temp = {}
            for (let i in res.items) {
                let item = res.items[i]
                if (item.record_id in temp) {
                    temp[item.record_id].count++
                    for (let t in types) {
                        temp[item.record_id][`s${types[t]}`] += item[`order_count_of_${types[t]}`]
                    }
                } else {
                    temp[item.record_id] = {
                        record_id: item.record_id,
                        operator: item.operator,
                        phone: item.phone,
                        endTime: item.end_time,
                        count: 1
                    }
                    for (let t in types) {
                        temp[item.record_id][`s${types[t]}`] = item[`order_count_of_${types[t]}`] || 0
                    }
                }
            }
            for (let i in temp) {
                this.tableData.push(temp[i])
            }
            this.showDrawer = true
        })
    }
  }
}
</script>
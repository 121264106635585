export default {
    data () {
        return {}
    },
    mounted() {
        /*
            ["System", "Products", "Purchase", "Sales", "Stocks", "Materials", 
            "System.Config", "System.RoleManage", "System.AdminManage", "System.LoginLogs", "System.HandleLogs", 
            "Products.CategoryManage", "Products.BrandManage", "Products.ProductView", "Products.ProductCostView", 
            "Products.ProductCreate", "Products.ProductUpdate", "Products.ProductPriceUpdate", "Products.ProductDelete", 
            "Purchase.View", "Purchase.OrderCreate", "Purchase.OrderUpdate", "Purchase.OrderDelete", "Purchase.OrderStockPutIn", 
            "Sales.View", "Sales.OrderCreate", "Sales.OrderUpdate", 
            "Sales.OrderAudit", "Sales.OrderReceiveMoney", "Sales.OrderDelete", "Sales.OrderStockTakeOut", 
            "Stocks.Inventory", "Stocks.BillManage", "Stocks.SerialNumberManage", "Stocks.PutIn", 
            "Stocks.TakeOut", "Stocks.PurchaseReturn", "Stocks.SaleReturn", "Stocks.SaleExchange", "Stocks.ChangeLogs", 
            "Stocks.SerialNumberLogs", "System.WarehouseManage"]
        */
        // console.error(this.$store.getters.roles)
    },
    computed: {
        /* ----------管理---------- */
        isSystem() {
            return this.hasSpecialRole("System.System")
        },
        isSystemConfig() {
            return this.hasSpecialRole("System.System.SystemConfig")
        },
        isSystemAuditLog() {
            return this.hasSpecialRole("System.System.AuditLog")
        },
        
        /* ----------身份角色，用户管理---------- */
        isAbpIdentity() {
            // 是否有身份大类权限
            return this.hasSpecialRole("AbpIdentity")
        },
        isRolesManage() {
            // 是否可以角色管理
            return this.hasSpecialRole("AbpIdentity.Roles")
        },
        isRolesCreate() {
            return this.hasSpecialRole("AbpIdentity.Roles.Create")
        },
        isRolesUpdate() {
            return this.hasSpecialRole("AbpIdentity.Roles.Update")
        },
        isRolesDelete() {
            return this.hasSpecialRole("AbpIdentity.Roles.Delete")
        },
        isRolesPermissions() {
            return this.hasSpecialRole("AbpIdentity.Roles.ManagePermissions")
        },
        isUsersManage() {
            // 是否可以用户管理
            return this.hasSpecialRole("AbpIdentity.Users")
        },
        isUsersCreate() {
            return this.hasSpecialRole("AbpIdentity.Users.Create")
        },
        isUsersUpdate() {
            return this.hasSpecialRole("AbpIdentity.Users.Update")
        },
        isUsersDelete() {
            return this.hasSpecialRole("AbpIdentity.Users.Delete")
        },
        isUsersPermissions() {
            return this.hasSpecialRole("AbpIdentity.Users.ManagePermissions")
        },

        /* ----------基础数据---------- */
        isBaseData() {
            // 是否有基础数据大类权限
            return this.hasSpecialRole("BaseData.BaseData")
        },
        isBaseDataView() {
            return this.hasSpecialRole("BaseData.BaseData.View")
        },
        isBaseDataImport() {
            return this.hasSpecialRole("BaseData.BaseData.Import")
        },
        isBaseDataDelete() {
            return this.hasSpecialRole("BaseData.BaseData.Delete")
        },

        /* ----------运营数据---------- */
        isOperation() {
            // 是否有运营数据大类权限
            return this.hasSpecialRole("Operation.Operation")
        },
        isOperationMoveTask() {
            return this.hasSpecialRole("Operation.Operation.MoveTask")
        },
        isOperationMoveTaskAudit() {
            return this.hasSpecialRole("Operation.Operation.MoveTaskAudit")
        },
        isOperationCustomerArea() {
            return this.hasSpecialRole("Operation.Operation.CustomerArea")
        },
        isOperationCustomerAreaConfig() {
            return this.hasSpecialRole("Operation.Operation.CustomerAreaConfig")
        },
        isOperationEmployeeWork() {
            return this.hasSpecialRole("Operation.Operation.EmployeeWork")
        },
        isOperationEmployeeWorkConfig() {
            return this.hasSpecialRole("Operation.Operation.EmployeeWorkConfig")
        },
        isOperationTravel() {
            return this.hasSpecialRole("Operation.Operation.Travel")
        },

        /* ----------分析数据---------- */
        isAnalysis() {
            // 是否有分析数据大类权限
            return this.hasSpecialRole("DataAnalysis.Analysis")
        },
        isAnalysisDashboard() {
            return this.hasSpecialRole("DataAnalysis.Analysis.Dashboard")
        },
        isAnalysisUser() {
            return this.hasSpecialRole("DataAnalysis.Analysis.User")
        },
        isAnalysisVehicle() {
            return this.hasSpecialRole("DataAnalysis.Analysis.Vehicle")
        },
        isAnalysisVehicleHeatMap() {
            return this.hasSpecialRole("DataAnalysis.Analysis.VehicleHeatMap")
        },
        isAnalysisVehicleRealTimeAnalysis() {
            return this.hasSpecialRole("DataAnalysis.Analysis.VehicleRealTimeAnalysis")
        },

        isAnalysisReport() {
            return this.hasSpecialRole("DataAnalysis.Report")
        },
        isAnalysisReportView() {
            return this.hasSpecialRole("DataAnalysis.Report.ReportView")
        },
        isAnalysisReportCreate() {
            return this.hasSpecialRole("DataAnalysis.Report.ReportCreate")
        },
        isAnalysisReportUpdate() {
            return this.hasSpecialRole("DataAnalysis.Report.ReportUpdate")
        },
        isAnalysisReportDelete() {
            return this.hasSpecialRole("DataAnalysis.Report.ReportDelete")
        },
    },
    methods: {
        // 传递权限名称，返回是否存在
        isHasPermission(roleName) {
            let index = this.$store.getters.roles.findIndex(role => {
                role = role.replace(".", "")
                roleName = roleName.replace(".", "")
                return role == roleName
            })
            return index != -1
        },
        // 判断是否有指定权限
        hasSpecialRole(role) {
            return this.$store.getters.roles.indexOf(role) != -1
        }
    }
  }
  
  
  
<style lang="scss" scoped>
.page-order-map {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99998;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover {
        background-color: #f0f0f0;
    }
    .cluster-list {
        padding: 10px 12px;
        border-bottom: 1px dashed #eee;
        .cluster-item {
            margin-top: 3px;
        }
    }
    .cluster-list:hover {
        background-color: #f5f5f5;
    }
}
</style>

<template>
    <div class="page-order-map">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <div class="fl mrgt5">
                    <span class="col_danger">
                        <i class="el-icon-warning"></i>
                        每日7:30以后更新数据
                    </span>
                </div>
                <div class="fr">
                    <el-button-group class="fl mrgr5">
                        <el-button type="primary" :plain="listQuery.timeType !== 0" size="medium" @click="changeTimeType(0)">用车</el-button>
                        <el-button type="warning" :plain="listQuery.timeType === 0" size="medium" @click="changeTimeType(1)">还车</el-button>
                    </el-button-group>
                    <el-tag type="info mrgr5 mrgb5" v-if="listQuery.timeType === 0">用车时间：</el-tag>
                    <el-tag type="info mrgr5 mrgb5" v-if="listQuery.timeType !== 0">还车时间：</el-tag>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedDate" type="daterange" size="medium"
                        :picker-options="pickerOptions" :clearable="false" @change="_search()"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                    <el-time-picker class="c-datetime-range mrgr5 mrgb5" is-range v-model="selectedTime" size="medium" @change="changeTime"
                        range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            注：每日7:30以后更新数据。<br />
                            以下可查询指定日期段对应的每小时分时数据。<br />
                            用车：用户骑行订单开锁用车时的定位点，地图放大缩小时，聚合显示数量。<br />
                            还车：用户骑行订单关锁还车时的定位点，地图放大缩小时，聚合显示数量。<br />
                        </div>
                        <el-tag class="mrgl5" type="warning" size="medium"><i class="el-icon-view f12"></i> 查看说明</el-tag>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <el-button type="primary" :plain="!(cueSelectTimers.length === 2 && time >= cueSelectTimers[0] && time <= cueSelectTimers[1])" size="medium"
                            v-for="(item, time) in 24" :key="item" @click="selectTime(time)"
                            @mousedown.native="mouseSelectTime(time, 0)" @mouseup.native="mouseSelectTime(time, 1)">{{ time }}时</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='container'>
            <span class="total-count col_primary" title="当前查询出的总数">总数量：{{ orderJson.length }}</span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
        </div>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from 'moment'

let dataVars = {
    map: null, // 地图
    cluster: {}, // 点聚合
    polygonMap: null,
}
export default {
    name: "pageOrderMap",
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "用车/还车查询，分别以开锁用车/关锁还车的时间进行的数据筛选。"
            },
            listQuery: {
                area: "",
                timeType: 0,
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: ""
            },
            orderJson: [],
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: [moment().subtract(1, "d").format("YYYY-MM-DD"), moment().subtract(1, "d").format("YYYY-MM-DD")],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            setMarkerTime: null,
            centerMarker: null,
            coverMarker: null,
            coverPaths: [] // 服务区多边形点位
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                this.setPolygonContains()
                this._search()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        this.getCurrentAreaData()
        await this.initMap()
        await this.getDataList()
    },
    unmounted() {
        dataVars.map?.destroy()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psDate = moment(this.selectedDate[0]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate[0]).format("00:00:00")
                }
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peDate = moment(this.selectedDate[1]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate[1]).format("23:59:59")
                }
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedDate = [this.listQuery.beginDate, this.listQuery.endDate]
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [`${this.listQuery.beginDate} ${this.listQuery.beginTime}`, `${this.listQuery.endDate} ${this.listQuery.endTime}`]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            await funReport.GetOrderLocation(this.listQuery).then(response => {
                this.orderJson = response
            })
            await this.setMarkerClusterer()
            window.$common.closeFullLoading()
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverMarker = areaItem || {}
                this.coverPaths = areaItem.pointList || []
            }
        },
        changeTimeType(val) {
            this.listQuery.timeType = val
            this._search()
        },
        // 时间段选择
        changeTime(arr) {
            this.cueSelectTimers = []
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
                let sTime = moment(arr[0]).format("HH:00:00")
                let eTime = moment(arr[1]).format("HH:59:59")
                // 时间相同，配置选中效果
                if (this.listQuery.beginTime === sTime && this.listQuery.endTime === eTime) {
                    this.cueSelectTimers = [moment(arr[0]).format("H"), moment(arr[1]).format("H")]
                }
                this._search()
            }
        },
        // 快捷时间段选择
        selectTime(time) {
            this.cueSelectTimers = [time, time]
            let psTime = ""
            let peTime = ""
            let hour = time < 10 ? ("0" + time ) : time
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + hour + ":00:00")
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + hour + ":59:59")
            }
            this.selectedTime = [psTime, peTime]
            this._search()
        },
        mouseSelectTime(time, type) {
            if (type === 0) {
                // 鼠标按下
                this.curMouseDownTime = time
            } else if (type === 1) {
                // 鼠标放开
                if (this.curMouseDownTime !== time) {
                    let psTime = ""
                    let peTime = ""
                    let sHour = null
                    let eHour = null
                    if (time > this.curMouseDownTime) {
                        this.cueSelectTimers = [this.curMouseDownTime, time]
                        sHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                        eHour = time < 10 ? ("0" + time ) : time
                    } else if (time < this.curMouseDownTime) {
                        this.cueSelectTimers = [time, this.curMouseDownTime]
                        sHour = time < 10 ? ("0" + time ) : time
                        eHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                    }
                    if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                        psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + sHour + ":00:00")
                    }
                    if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                        peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + eHour + ":59:59")
                        // peTime = moment(peTime).subtract(1, "hours")
                    }
                    this.selectedTime = [psTime, peTime]
                    this._search()
                }
            }
        },
        _search() {
            this.getDataList()
        },
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.MarkerCluster"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
                // 生成点聚合
                this.initMarkerClusterer()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
            // 自定义地图缩放步长问题
            /* let isZoom = false
            dataVars.map.on("mousewheel", (res) => {
                isZoom = true
            })
            dataVars.map.on("zoomend", (res) => {
                if (isZoom) {
                    console.log("开始：" + dataVars.map.getZoom() + ", " + zoom)
                    if (dataVars.map.getZoom() > zoom) {
                        zoom = Math.ceil(dataVars.map.getZoom())
                    } else {
                        zoom = Math.floor(dataVars.map.getZoom())
                    }
                    console.log("结束：" + zoom)
                    dataVars.map.setZoom(zoom)
                }
                isZoom = false
            }) */
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 服务区有中心点则异动到服务区中心
            if (this.coverMarker && this.coverMarker.centerLng && this.coverMarker.centerLat) {
                let curLnglat = [this.coverMarker.centerLng, this.coverMarker.centerLat]
                this.moveToCenter({ lnglat: curLnglat })
            }
        },
        // 生成地图点聚合
        initMarkerClusterer() {
            // 生成地图点位
            let points = this.getMarkerClustererPoints()
            dataVars.cluster = new AMap.MarkerClusterer(
                dataVars.map,     // 地图实例
                points, // 海量点数据，数据中需包含经纬度信息字段 lnglat
                {
                    gridSize: 40,
                    renderClusterMarker: this._renderClusterMarker,
                    renderMarker: this._renderMarker
                    // averageCenter: false
                }
            );
            // 点击聚合点
            dataVars.cluster.on("click", (ev) => {
                this.setMarkerTime = setTimeout(() => {
                    let pos = ev.marker.getPosition()
                    let clusterCenter = [pos.lng, pos.lat]
                    this.moveToCenter({ lnglat: clusterCenter })
                }, 300)
            })
        },
        _renderClusterMarker(context) {
            // console.log(context)
            let count = this.orderJson.length
            let factor = Math.pow(context.count / count, 1 / 18)
            let div = document.createElement('div')
            let Hue = 180 - factor * 180
            let bgColor = 'hsla(' + Hue + ',100%,45%,0.7)'
            let fontColor = 'hsla(' + Hue + ',100%,90%,1)'
            let borderColor = 'hsla(' + Hue + ',100%,40%,1)'
            let shadowColor = 'hsla(' + Hue + ',100%,90%,1)'
            div.style.backgroundColor = bgColor
            let size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
            div.style.width = div.style.height = size + 'px'
            div.style.border = 'solid 1px ' + borderColor
            div.style.borderRadius = size / 2 + 'px'
            div.style.boxShadow = '0 0 5px ' + shadowColor
            div.innerHTML = context.count
            div.style.lineHeight = size + 'px'
            div.style.color = fontColor
            div.style.fontSize = '14px'
            div.style.textAlign = 'center'
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2))
            context.marker.setContent(div)
            // 绑定双击事件--放大，并移动到中心点
            context.marker.on("dblclick", (ev) => {
                if (this.setMarkerTime) {
                    clearTimeout(this.setMarkerTime)
                }
                let clusterCenter = [ev.lnglat.lng, ev.lnglat.lat]
                dataVars.map.setZoomAndCenter(dataVars.map.getZoom() + 1, clusterCenter)
            })
        },
        _renderMarker(context) {
            context.marker.setAnchor("bottom-center")
            context.marker.setContent(`<img width="14" height="22" src="/static/images/map/poi-marker-default.png" />`)
            context.marker.setOffset(new AMap.Pixel(0, 4))
        },
        // 设置点聚合数据
        async setMarkerClusterer() {
            let points = this.getMarkerClustererPoints()
            if (points.length > 0) {
                this.moveToCenter({ lnglat: points[0].lnglat })
            }
            await dataVars.cluster.setData(points)
        },
        getMarkerClustererPoints() {
            let points = this.orderJson.map((x, i) => {
                let lnglat = [x.endLng, x.endLat]
                if (!this.listQuery.timeType) {
                    lnglat = [x.startLng, x.startLat]
                }
                let temp = { lnglat, weight: i }
                return temp
            }) || []
            return points
        },
        moveToCenter(item) {
            // console.log(item)
            dataVars.map.setCenter(item.lnglat)
            /* if (this.centerMarker) {
                this.centerMarker.setPosition(item.lnglat)
            } else {
                this.centerMarker = new AMap.Marker({
                    position: item.lnglat,
                    anchor: "bottom-center",
                    content: `<img width="19" height="32" src="/static/images/map/poi-marker-red.png" />`,
                    offset: new AMap.Pixel(0, 4)
                });
                this.centerMarker.setMap(dataVars.map)
            } */
        }
    }
}
</script>
import axios from 'axios'
import qs from 'qs'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import { getAdminToken } from '@/utils/storages'

// axios.defaults.withCredentials = true

let baseApi = ""
// 判断是生产环境或者开发环境
if (process.env.NODE_ENV === 'development') {
  baseApi = window.configs.baseTestApi
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.VUE_APP_FLAG === 'pro') {
    baseApi = window.configs.baseApi
  } else if (process.env.VUE_APP_FLAG === 'uat') {
    baseApi = window.configs.baseUatApi
  } else if (process.env.VUE_APP_FLAG === 'test') {
    baseApi = window.configs.baseTestApi
  } else {
    baseApi = window.configs.baseApi
  }
}

console.log(window.configs)
// 设置mock数据的api
// window.$mockApi = baseApi

// 创建axios实例
const service = axios.create({
  baseURL: baseApi, // api的base_url
  timeout: 600000 // 请求超时时间
})


// request拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  if (store.getters.token) {
    config.headers['Authorization'] = "Bearer " + getAdminToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (config.method == 'get' || config.method == 'delete') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, error => {
  // 请求错误时做什么
  console.log(error) // 打印错误信息
  Promise.reject(error)
})
// respone拦截器
service.interceptors.response.use(
  response => {
    /**
    * 对响应数据做点什么
    * success非true表示请求失败
    */
    const res = response
    if (res.status === 200) {
      if (res.data && res.data.totalCount) {
        res.data.totalCount = Number(res.data.totalCount)
      }
      return res.data
    } else if (res.status === 401) {
      // unAuthorizedRequest 表示授权失败
      if (res.unAuthorizedRequest) {
        loginOutDialog()
        return false
      }
    } else {
      try {
        // 其他错误弹出提示
        Message({
          message: res.error.message || res.error.details,
          type: 'error',
          duration: 5 * 1000,
          onClose: () => {
            window.$common.closeFullLoading()
          }
        })
        window.$common.closeFullLoading()
        return Promise.reject('error: ' + res.error.message)
      } catch(e) {
        return res
      }
    }
  },
  error => {
    // 对响应错误做点什么
    console.log(error.response) // 打印错误信息
    /* if (error.response && (error.response.status === 401 || error.response.status === 403) && error.response.data.unAuthorizedRequest) {
      loginOutDialog()
      return false
    } */
    if (error.response && error.response.status === 401) {
      loginOutDialog()
      return Promise.reject(error)
    }
    if (error.response && error.response.status === 403) {
      Message({
        message: error.response.data && error.response.data.error && error.response.data.error.message || "没有访问权限!",
        type: 'error',
        duration: 2500,
        onClose: () => {
          window.$common.closeFullLoading()
        }
      })
      window.$common.closeFullLoading()
      return Promise.reject(error)
    }
    Message({
      message: error && error.response && error.response.data && error.response.data.error && error.response.data.error.message || error,
      type: 'error',
      duration: 3 * 1000,
      onClose: () => {
        window.$common.closeFullLoading()
        /* store.dispatch('FedLogOut').then(() => {
          location.reload()// 为了重新实例化vue-router对象 避免bug
        }) */
      }
    })
    window.$common.closeFullLoading()
    return Promise.reject(error)
  }
)

// 登出提示
function loginOutDialog () {
  MessageBox.confirm('当前登录信息已过期，请重新登录!', '确定登出', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    store.dispatch('loginOut').then(() => {
      location.reload()// 为了重新实例化vue-router对象 避免bug
    })
  })
}

export default service


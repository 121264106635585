<style scoped lang="scss">

</style>

<template>
  <div class="page-maintenance-movedata">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fl">
                <el-button class="mrgr5 mrgb5" type="danger" size="medium" v-if="isOperationMoveTaskAudit"
                    @click="batchAudit()">批量审核</el-button>
                <el-button-group class="mrgr5 mrgb5">
                  <el-button :class="$root.isPc ? '' : 'mobile-group-button'" type="primary" v-for="(tab, index) in tabs" :plain="tabIndex !== index"
                      :key="'employee_tab_' + index" size="medium" @click="tabClick(index)">{{ tab }}</el-button>
                </el-button-group>
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium" v-show="isShowSelectTime"
                    :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    align="right">
                </el-date-picker>
            </div>
            <div class="fr">
                <el-select class="c-el-input mrgr5 mrgb5" placeholder="挪车类型" size="medium" filterable
                    clearable v-model="listQuery.flag" @change="_search()">
                    <el-option v-for="t in flags" :key="t.value" :label="t.name" :value="t.value"></el-option>
                </el-select>
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="员工的姓名/手机号" clearable v-model="listQuery.keywords"
                    size="medium"></el-input>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 导出</el-button>
                <el-tooltip class="item" effect="dark" content="在系统配置中更改了挪车配置后，需手动更新下相关数据" placement="top-start">
                    <el-button type="danger" size="medium" @click="_handle()"><i class="fa fa-refresh"></i> 更新挪车数据</el-button>
                </el-tooltip>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium" @sort-change="sortChange" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="40" fixed="left"></el-table-column>
        <el-table-column label="批次ID" prop="record_id" width="100"></el-table-column>
        <el-table-column label="批次车辆数" prop="move_num" width="100"></el-table-column>
        <el-table-column label="车辆号" prop="car_id" width="120"></el-table-column>
        <el-table-column label="挪车人" prop="operator" width="100"></el-table-column>
        <el-table-column label="开始挪车时间" prop="start_time" :formatter="tableDateFormat" sortable="custom" width="160"></el-table-column>
        <el-table-column label="挪车完成时间" prop="end_time" :formatter="tableDateFormat" sortable="custom" width="160"></el-table-column>
        <el-table-column label="挪车持续时间" prop="last_time" width="160"></el-table-column>
        <el-table-column label="挪车人绩效" prop="achievement" width="100"></el-table-column>
        <el-table-column label="挪车前无单时间" prop="no_order_time_str" width="160"></el-table-column>
        <el-table-column label="挪车后首单时间" prop="first_order_time_diff" width="160"></el-table-column>
        <el-table-column label="开始地点" prop="short_start_address" width="320"></el-table-column>
        <el-table-column label="结束地点" prop="short_end_address" width="320"></el-table-column>
        <el-table-column label="挪车距离" prop="distance" sortable="custom" width="110"></el-table-column>
        <el-table-column label="挪车前状态" prop="move_status_desc" width="250"></el-table-column>
        <el-table-column label="挪车前告警状态" prop="alarm_before_names" width="160"></el-table-column>
        <el-table-column label="挪车后告警状态" prop="alarm_after_names" width="160"></el-table-column>
        <el-table-column label="挪车前车辆标签" prop="car_labels_before_names" width="160"></el-table-column>
        <el-table-column label="挪车后车辆标签" prop="car_labels_after_names" width="160"></el-table-column>
        <el-table-column label="是否有效" prop="flag" width="100" fixed="right">
            <template slot-scope="scope">
                <el-tag type="danger" size="small" v-if="scope.row.flag == 0">{{ scope.row.flag }}</el-tag>
                <el-tag type="success" size="small" v-else-if="scope.row.flag == 1">{{ scope.row.flag }}</el-tag>
                <el-tag type="warning" size="small" v-else-if="scope.row.flag == 2">{{ scope.row.flag }}</el-tag>
                <el-tag type="primary" size="small" v-else>{{ scope.row.flag }}</el-tag>
                <el-button style="padding: 5px 8px;" class="mrgl5" size="mini" v-if="isOperationMoveTaskAudit"
                    @click="batchAudit(scope.row)">修改</el-button>
            </template>
        </el-table-column>

        <!--<el-table-column label="任务生成时间" prop="created_at" :formatter="tableDateFormat" sortable="custom" width="160"></el-table-column>
        <el-table-column label="开始挪车时间" prop="start_time" :formatter="tableDateFormat" sortable="custom" width="160"></el-table-column>
        <el-table-column label="任务完成时间" prop="finish_time" :formatter="tableDateFormat" sortable="custom" width="160"></el-table-column>
        <el-table-column label="车辆无单时间" prop="no_order_time" sortable="custom" width="160"></el-table-column>
        <el-table-column label="完成任务后首单间隔时间" prop="first_order_time_diff" sortable="custom" width="200"></el-table-column>
        <el-table-column label="挪车人员" prop="op_name" width="100"></el-table-column>
        <el-table-column label="挪车人员手机号" prop="op_phone" width="150"></el-table-column>

        <el-table-column label="任务编号" prop="id" width="200"></el-table-column>
        <el-table-column label="批次编号" prop="record_id" width="100"></el-table-column>
        <el-table-column label="车辆编号" prop="car_id" width="120"></el-table-column>
        <el-table-column label="任务来源" prop="name" width="120"></el-table-column>
        <el-table-column label="所属片区" prop="area_name" width="150"></el-table-column>
        
        <el-table-column label="挪车持续时间" prop="move_last_time" width="160"></el-table-column>
        <el-table-column label="挪车距离" prop="distance" sortable="custom" width="110"></el-table-column>
        <el-table-column label="任务生成时是否在站点" prop="task_create_is_in_park" width="180">
            <template slot-scope="scope">{{ scope.row.task_create_is_in_park ? "是" : "否" }}</template>
        </el-table-column>
        <el-table-column label="任务生成时所属站点名称" prop="task_create_park_name" width="180"></el-table-column>
        <el-table-column label="任务完成时是否在站点" prop="is_in_park" width="180">
            <template slot-scope="scope">{{ scope.row.is_in_park ? "是" : "否" }}</template>
        </el-table-column>
        <el-table-column label="任务完成时所在站点名称" prop="parking_name" width="180"></el-table-column>
        <el-table-column label="任务完成时车辆定位" prop="end_address" width="400"></el-table-column>
        <el-table-column label="是否指定任务站点" prop="is_have_target_park" width="160">
            <template slot-scope="scope">{{ scope.row.is_have_target_park ? "是" : "否" }}</template>
        </el-table-column>
        <el-table-column label="指定站点名称" prop="end_address" width="400"></el-table-column>
        <el-table-column label="是否挪入任务站点" prop="is_moving_into_target_park" width="180">
            <template slot-scope="scope">{{ scope.row.is_moving_into_target_park ? "是" : "否" }}</template>
        </el-table-column>
        
        <el-table-column label="6小时订单" prop="order_count_of_6" width="120"></el-table-column>
        <el-table-column label="12小时订单" prop="order_count_of_12" width="120"></el-table-column>
        <el-table-column label="24小时订单" prop="order_count_of_24" width="120"></el-table-column>
        <el-table-column label="48小时订单" prop="order_count_of_48" width="120"></el-table-column>
        <el-table-column label="距离上一次调度时间" prop="last_move_time_diff" width="160"></el-table-column>
        <el-table-column label="挪车前告警状态" prop="alarm_names_before" width="160"></el-table-column>
        <el-table-column label="挪车后告警状态" prop="alarm_names_after" width="160"></el-table-column>
        <el-table-column label="挪车前运维状态" prop="operate_state_before" width="160"></el-table-column>
        <el-table-column label="挪车后运维状态" prop="operate_state_after" width="160"></el-table-column>
        <el-table-column label="挪车前车辆标签" prop="car_tag_before" width="160"></el-table-column>
        <el-table-column label="挪车后车辆标签" prop="car_tag_after" width="160"></el-table-column>
        <el-table-column label="绩效" prop="performance" width="100"></el-table-column>
        <el-table-column label="挪车状态" prop="state" fixed="right" width="120">
            <template slot-scope="scope">
                <el-tag :type="scope.row.flag > 0 ? 'primary' : 'danger'" plain size="small">{{ tranceFlag(scope.row.flag) }}</el-tag>
            </template>
        </el-table-column>-->
    </el-table>

    <div class="pagination-container" v-if="isPagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>

    <!--导出excel-->
    <export-to-excel :ref="refExportToExcel"></export-to-excel>
    <!--审核-->
    <audit :ref="refAudit" @refreshData="getDataList()"></audit>
  </div>
</template>

<script>
  import permission from "@/mixins/permission"
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  import ExportToExcel from "@/components/Excels/JsonToExcel"
  import audit from "./components/audit"
  import { AnalysisMoveTask } from "@/mixins/commonPageExport"
  export default {
    name: "pageMaintenanceMoveData",
    mixins: [permission],
    components: { ExportToExcel, audit },
    data() {
      let that = this
      return {
        refExportToExcel: "refMoveTaskToExportExcel",
        refAudit: "refMoveTaskToAudit",
        tableData: [],
        total: null,
        listQuery: {
          area: "",
          flag: null, // 
          keywords: "",
          beginDate: "",
          endDate: "",
          page: 1,
          size: window.$common.appPageSize,
          sorting: ""
        },
        isPagination: false,
        selectedTime: [moment().startOf("month").format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")],
        flags: [
            { name: "有效挪车", value: 1 },
            { name: "无效挪车", value: 0 },
            { name: "存疑挪车", value: 2 }
        ],
        pickerOptions: {
            shortcuts: [
                {
                    text: "昨天",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "days")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一周",
                    onClick: (picker) => {
                        let timeArr = that.setPickerTime(picker, 1, "weeks")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近三月",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 3, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近半年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 6, "months")
                        that.setQueryTime(timeArr)
                    }
                },
                {
                    text: "近一年",
                    onClick(picker) {
                        let timeArr = that.setPickerTime(picker, 1, "years")
                        that.setQueryTime(timeArr)
                    }
                }
            ]
        },
        tabIndex: 1,
        tabs: ["昨日", "本月", "上月", "自定义"],
        isShowSelectTime: false,
        batchSelections: []
      }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    activated() {
        console.log(this.$route.params)
        let params = this.$route.params
        if (params) {
            if (params.keyword) {
                this.listQuery.keywords = params.keyword
            }
            if (params.selectedTime && params.selectedTime.length == 2) {
                this.selectedTime = params.selectedTime
            }
            if (params.type !== undefined) {
                // 0：有效挪车，1：无效挪车，2：存疑挪车
                if (!params.type && params.type !== 0) {
                    this.listQuery.flag = null
                } else {
                    this.listQuery.flag = Number(params.type)
                }
            }
            if (params.tabIndex !== undefined) {
                this.tabClick(params.tabIndex, true)
            } else {
                this._search()
            }
        } else {
            this._search()
        }
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
      setQueryTime(timeArr) {
          this.listQuery.beginDate = timeArr[0] || ""
          this.listQuery.endDate = timeArr[1] || ""
      },
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getDataList()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getDataList()
      },
      setSelectedTime() {
          let psTime = ""
          let peTime = ""
          if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
              psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
          }
          if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
              peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
          }
          this.listQuery.beginDate = psTime
          this.listQuery.endDate = peTime
          this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
      },
      getDataList () {
        this.setSelectedTime()
        this.listQuery.area = this.$store.getters.serviceArea || ""
        window.$common.fullLoading()
        funMaintenance.GetMoveTask(this.listQuery).then(response => {
          window.$common.closeFullLoading()
          this.total = response.totalCount
          this.isPagination = response.totalCount > 0 || false
          if (response && response.items && response.items.length > 0) {
            this.tableData = response.items
          } else {
            this.tableData = []
          }
        })
      },
      tabClick(index, isKeepSelectTime) {
        this.tabIndex = index
        switch(index) {
          case 0: {
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            this.selectedTime = [moment(endTime).format("YYYY-MM-DD 00:00:00"), endTime]
            break
          }
          case 1: {
            this.selectedTime = [moment().startOf("month").format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")]
            break
          }
          case 2: {
            this.selectedTime = [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD 23:59:59")]
            break
          }
        }
        if (index !== 3) {
          this.isShowSelectTime = false
        } else {
          if (!isKeepSelectTime) {
            this.selectedTime = []
          }
          this.isShowSelectTime = true
        }
        this._search()
      },
      changeInvalid(val) {
        if (!val) {
            this.listQuery.flag = null
        }
        this._search()
      },
      tranceFlag(val) {
        let back = ""
        let item = this.flags.find(x => x.value === val)
        return item && item.name || "无效挪车"
      },
      _search() {
        this.listQuery.page = 1
        this.getDataList()
      },
      _handle() {
        let param = {
            area: this.listQuery.area,
            beginDate: this.listQuery.beginDate,
            endDate: this.listQuery.endDate,
            keywords: this.listQuery.keywords
        }
        window.$common.fullLoading()
        funMaintenance.HandleMoveTask(param).then(res => {
            this._search()
        })
      },
      async sortChange(e) {
        let params = ["first_order_time_diff", "no_order_time"]
        if (e.order) {
            if (params.indexOf(e.prop) !== -1) {
                this.listQuery.sorting = `${e.prop}_int ${e.order}`
            } else {
                this.listQuery.sorting = `${e.prop} ${e.order}`
            }
        } else {
            this.listQuery.sorting = ""
        }
        this._search()
      },
      handleSelectionChange(val) {
        this.batchSelections = val
      },
      batchAudit(row) {
        let ids = row && row.id ? [row.id] : this.batchSelections.map(x => x.id)
        if (ids.length > 0) {
            this.$refs[this.refAudit].open(ids, row)
        } else {
            this.warningMsg("请先勾选审核数据!")
            return false
        }
      },
      // 导出excel
      openExportExcel() {
            if (this.total > this.maxExportCount) {
                this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
                return false
            }
            let searchs = JSON.parse(JSON.stringify(this.listQuery))
            searchs.page = 1
            searchs.size = this.maxExportCount
            window.$common.fullLoading()
            funMaintenance.GetMoveTask(searchs).then(res => {
                if (res && res.items && res.items.length > 0) {
                    let tableDatas = res.items.map(x => {
                        x.start_time = this.getFormatDate(x.start_time)
                        x.end_time = this.getFormatDate(x.end_time)
                        x.first_order_time = this.getFormatDate(x.first_order_time)
                        return x
                    })
                    let exportData = ""
                    if (searchs.beginDate && searchs.endDate) {
                        let sbtd = moment(searchs.beginDate).format("YYYY-MM-DD")
                        let ebtd = moment(searchs.endDate).format("YYYY-MM-DD")
                        exportData = sbtd == ebtd ? sbtd : `${sbtd}至${ebtd}`
                    } else {
                        exportData = "全部"
                    }
                    let exportName = `${this.$store.getters.serviceArea}员工挪车数据(${exportData}).xlsx`
                    AnalysisMoveTask(exportName, tableDatas)
                } else {
                    window.$common.closeFullLoading()
                    setTimeout(() => {
                        this.alert("没有需要导出的数据!")
                    }, 10)
                }
            }).catch(() => {
                console.log("export error")
            })
        }
    }
  }
</script>
<style lang="scss">
.page-spiders-table {
    .el-badge {
        width: 100%;
        z-index: 999;
    }
    .el-badge__content.is-fixed {
        top: 18px;
        right: 25px;
    }
    .spider-lefts {
        .spider-scrollbar {
            width: 160px;
            height: 700px;
            border: 1px solid #eee;
            .el-scrollbar__wrap {
                .el-scrollbar__view {
                    min-width: 100%;
                }
            }
            .spider-area-btn {
                display: block;
                height: 36px;
                line-height: 36px;
                overflow: hidden;
                text-align: center;
                border-bottom: 1px dotted #eee;
            }
            .spider-area-btn.active {
                color: #fff;
            }
            .el-button {
                width: 100%;
                margin: 0 !important;
                border: 0;
                border-bottom: 1px dotted #eee;
                border-radius: 0;
            }
            .spider-btn {
                background: #ffffff;
                color: #606266;
            }
            .el-button--primary.is-plain {
                background: #ffffff;
                color: #606266;
            }
        }
    }
    .spider-rights {
        width: calc(100% - 175px);
        .primary-row {
            background-color: #f2f9ff;
        }
        .success-row {
            background-color: #fbfffa;
        }
        .danger-row {
            background-color: #fbf5f5;
        }
    }
}
.spider-alert {
    word-break: break-word !important;
    width: 60% !important;
    min-width: 300px !important;
    .el-message-box__message p {
        max-height: 65vh;
        overflow: auto;
    }
}
</style>

<template>
    <div class="page-spiders-table">
        <div class="spider-lefts fl">
            <el-scrollbar class="spider-scrollbar">
                <!--<a href="javascript:void(0)" class="spider-area-btn" v-for="area in areas" :key="area.serviceArea">
                    <el-badge :value="area.abnormalCount" :hidden="area.abnormalCount < 0">{{ area.serviceArea || "无服务区" }}</el-badge>
                </a>-->
                <el-badge :value="area.abnormalCount" :hidden="area.abnormalCount <= 0" v-for="area in areas" :key="area.serviceArea">
                    <el-button :type="area.abnormalCount <= 0 ? 'primary' : 'danger'" 
                        :plain="area.serviceArea != listQuery.area"
                        @click="selectArea(area.serviceArea)">
                        {{ area.serviceArea || "无服务区" }}
                    </el-button>
                </el-badge>
            </el-scrollbar>
        </div>
        <div class="spider-rights fr">
            <div class="toolbars mrgb5">
                <div class="buttons mrgb5">
                    <div class="fl dn">
                        <el-button-group>
                            <el-button :type="area.abnormalCount <= 0 ? 'primary' : 'danger'" v-for="area in areas" :key="area.serviceArea"
                                :plain="area.serviceArea != $store.getters.serviceArea">
                                <el-badge :value="area.abnormalCount" :hidden="area.abnormalCount <= 0">{{ area.serviceArea || "无服务区" }}</el-badge>
                            </el-button>
                        </el-button-group>
                    </div>
                    <div class="fr">
                        <el-select class="mrgr5" v-model="listQuery.name" filterable clearable placeholder="爬虫名称"
                            @change="_search()">
                            <el-option v-for="sp in spiderNames" :key="sp" :label="sp" :value="sp"></el-option>
                        </el-select>
                        <el-select class="mrgr5" v-model="listQuery.status" clearable placeholder="执行状态"
                            @change="_search()">
                            <el-option value="Normal" label="正常"></el-option>
                            <el-option value="Running" label="执行中"></el-option>
                            <el-option value="Abnormal " label="异常"></el-option>
                        </el-select>
                        <el-button type="primary" size="medium" @click="_search()"><i class="el-icon-refresh"></i> 查询</el-button>
                    </div>
                    <div class="clearb"></div>
                </div>
            </div>
            <el-table :data="tableData" border :row-class-name="tableRowClassName">
                <el-table-column label="服务区" prop="serviceName">
                    <template slot-scope="scope">{{ scope.row.serviceName || "--" }}</template>
                </el-table-column>
                <el-table-column label="爬虫名称" prop="spiderName"></el-table-column>
                <!--<el-table-column label="最后运行时间" prop="lastRunTime" :formatter="tableDateFormat"></el-table-column>-->
                <el-table-column label="爬虫状态" prop="status">
                    <template slot-scope="scope">
                        <span class="col_success" v-if="scope.row.status === 1">正常</span>
                        <span class="col_primary" v-else-if="scope.row.status === 2">执行中</span>
                        <span class="col_danger" v-else>异常</span>
                    </template>
                </el-table-column>
                <el-table-column label="最近一条执行结果" min-width="280">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status === 1">
                            <div><span class="col_success">执行时间：</span>{{ scope.row.lastLog.startTime }} 至 {{ scope.row.lastLog.endTime }}</div>
                            <div class="col_info">{{ scope.row.lastLog.remark }}</div>
                        </div>
                        <div v-else-if="scope.row.status === 2"></div>
                        <div v-else>
                            <div><span class="col_danger">执行时间：</span>{{ scope.row.lastLog.startTime }} 至 {{ scope.row.lastLog.endTime }}</div>
                            <el-button type="danger" plain size="mini" @click="showError(scope.row.lastLog.remark)">查看错误信息</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="showSpiderLogs(scope.row)">日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="clearb"></div>

        <!--爬虫历史日志-->
        <spider-history :ref="refSpiderHistory" @refreshData="getAllSpiderAreas()"></spider-history>
    </div>
</template>

<script>
import * as funSpider from "@/api/spider"
import SpiderHistory from "./components/spiderHistory"
export default {
    name: "PageSpidersTable",
    components: { SpiderHistory },
    data() {
        return {
            refSpiderHistory: "refSpidersTableToHistory",
            areas: [],
            spiderNames: [],
            listQuery: {
                area: "",
                name: "",
                status: ""
            },
            tableData: []
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.initData()
            })
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            this.listQuery.area = this.$store.getters.serviceArea
            this.getAllSpiderAreas()
        },
        async getAllSpiderAreas() {
            await funSpider.getSpiderAreas().then(res => {
                this.areas = res
                // this.areas[2].abnormalCount = 3
                // this.areas[5].abnormalCount = 1
                this.areas.unshift({
                    serviceArea: "全部",
                    abnormalCount: res.reduce((total, item) => {
                        return total + item.abnormalCount
                    }, 0)
                })
                // 如果服务区数组内不存在默认的服务区，则选择全部
                let index = this.areas.findIndex(x => {
                    return this.listQuery.area == x.serviceArea
                })
                if (index === -1) {
                    this.listQuery.area = this.areas[0].serviceArea
                }
            })
            await this.getSpiderNames()
            await this.getSpiderDatas()
        },
        async getSpiderNames() {
            await funSpider.getSpiderNames().then(res => {
                this.spiderNames = res
            })
        },
        async getSpiderDatas() {
            let params = JSON.parse(JSON.stringify(this.listQuery))
            if (params.area == "") {
                params.area = "无服务区"
            } else if (params.area == "全部") {
                params.area = ""
            }
            window.$common.fullLoading()
            await funSpider.getAllSpider(params).then(res => {
                window.$common.closeFullLoading()
                this.tableData = res.map(x => {
                    x.lastLog = {}
                    if (x.logs && x.logs[0]) {
                        x.lastLog = x.logs[0]
                        x.lastLog.startTime = this.getFormatDate(x.lastLog.startTime)
                        x.lastLog.endTime = this.getFormatDate(x.lastLog.endTime)
                    }
                    return x
                })
            })
        },
        selectArea(name) {
            this.listQuery.area = name
            this.getSpiderDatas()
        },
        showSpiderLogs(row) {
            this.$refs[this.refSpiderHistory].open(row)
        },
        tableRowClassName({row, rowIndex}) {
            if (row.status === 1) {
                return 'success-row'
            } else if (row.status === 2) {
                return 'primary-row'
            } else {
                return 'danger-row'
            }
        },
        showError(content) {
            this.alert(content, "错误信息", {
                type: "none",
                customClass: "spider-alert"
            })
        },
        _search() {
            this.getSpiderDatas()
        }
    }
}
</script>
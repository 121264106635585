<style lang="scss" scoped>
/deep/ .comp-travel-edit-dialog {
    max-width: 400px;
}
</style>

<template>
    <div class="comp-travel-edit">
        <el-dialog title="工作时间段配置" custom-class="c-el-dialog comp-travel-edit-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="80px" size="small">
                <el-col :xs="24">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="dialogData.name" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="dialogData.phone" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="开始时间" prop="beginDate">
                        <el-date-picker type="datetime" placeholder="请选择开始时间" v-model="dialogData.beginDate"
                            :default-time="'00:00:00'"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="结束时间" prop="endDate">
                        <el-date-picker type="datetime" placeholder="请选择结束时间" v-model="dialogData.endDate"
                            :default-time="'23:59:59'"></el-date-picker>
                    </el-form-item>
                </el-col>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import moment from "moment"
import * as funMaintenance from '@/api/maintenance'
export default {
    name: "compTravelEdit",
    mixins: [configMixins],
    data() {
        return {
            showDialog: false,
            defaultDialogData: {
                name: "",
                phone: "",
                beginDate: "",
                endDate: ""
            },
            dialogData: {},
            formRefName: "refEditWork",
            formRules: {
                name: [{ required: true, message: '请输入姓名', trigger: ['blur']}],
                phone: [{ required: true, message: '请输入联系方式', trigger: ['blur']}],
                beginDate: [{ required: true, message: '请输入开始时间', trigger: ['blur', 'change']}],
                endDate: [{ required: true, message: '请输入结束时间', trigger: ['blur', 'change']}]
            }
        }
    },
    methods: {
        open() {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            this.showDialog = true
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let beginDate = moment(this.dialogData.beginDate).unix()
                    let endDate = moment(this.dialogData.endDate).unix()
                    if (Number(beginDate) >= Number(endDate)) {
                        this.warningMsg("结束时间必须大于开始时间!")
                        return false
                    }
                    let commitData = JSON.parse(JSON.stringify(this.dialogData))
                    funMaintenance.createTravel(commitData).then(res => {
                        this.commonSuccess()
                    })
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "行程单创建成功!"
            this.alert(tips).then(() => {
                this.refreshData()
                this._close()
                window.$common.closeFullLoading()
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>
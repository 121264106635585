<style lang="scss" scoped>
/deep/ .comp-point-demand-dialog {
    max-width: 320px;
}
</style>

<template>
    <div class="comp-point-demand">
        <el-dialog :title="title" custom-class="c-el-dialog comp-point-demand-dialog" :visible.sync="showDialog" :close-on-press-escape="true">
            <el-table :data="tableData" border size="small" highlight-current-row max-height="286">
                <el-table-column property="beginTime" label="开始时间"></el-table-column>
                <el-table-column property="endTime" label="结束时间"></el-table-column>
                <el-table-column property="demandVehicleCount" label="需求数量"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "compPoindDemand",
    data() {
        return {
            showDialog: false,
            title: "",
            tableData: []
        }
    },
    methods: {
        open(row) {
            let item = JSON.parse(JSON.stringify(row))
            this.title = item.point && item.point.name || ""
            this.tableData = item.items || []
            this.showDialog = true
        }
    }
}
</script>
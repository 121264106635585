<style lang="scss" scoped>
.page-statistics-users {}
</style>
<template>
    <div class="page-statistics-users">
        <div class="mrgb20">
            <el-button-group class="mrgr5 mrgb5">
                <el-button type="primary" size="small" v-for="item in selectTimes" :key="item"
                    :plain="curSelectTime != item" @click="selectRange(item)">{{ item === 1 ? "昨日" : "近" + item + "日" }}</el-button>
                <el-button type="primary" size="small" :plain="curSelectTime > 0" @click="selectRange(-1)">自定义时间段</el-button>
            </el-button-group>
            <span v-show="isShowSelectTime">
                <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="small"
                :default-time="['00:00:00', '23:59:59']" @change="getSearchData" :clearable="false"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                align="right">
                </el-date-picker>
                <el-button class="mrgr5 mrgb5" type="primary" size="small" @click="getSearchData()"><i class="fa fa-search"></i> 查询</el-button>
            </span>
        </div>
        <el-row :gutter="15">
            <el-col :xs="24" :lg="12" class="mrgb20" v-for="(item, index) in usersOptions" :key="'userEchartKey' + index">
                <el-card>
                    <span slot="header">
                        {{ usersLegends[index] }}
                        <el-tooltip effect="dark" :content="usersTips[index]" placement="top">
                            <el-tag class="fr" type="info" size="small"><i class="el-icon-view f12"></i> 查看注释</el-tag>
                        </el-tooltip>
                        <span class="db"></span>
                    </span>
                    <echart :id="'userEchart' + index" title="" width="100%" height="520px" :option="item"></echart>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import echart from "@/components/Echarts/Index"
import moment from "moment"
export default {
    name: "pageStatisticsUsers",
    components: { echart },
    data() {
        return {
            listQuery: {
                area: "",
                type: 0,
                beginDate: "",
                endDate: "",
                keywords: null,
                page: 1,
                size: window.$common.appPageSize
            },
            selectTimes: [1, 3, 7, 10, 15, 30],
            curSelectTime: null,
            isShowSelectTime: false,
            selectedTime: [],
            reportData: {},
            vehicleCommonOptions: {}, // 投放总数，可用车数量，占比
            usersLegends: ["新增用户数", "有效骑行用户数", "活跃用户数", "高活用户数"],
            usersTips: ["当日新增注册用户数", "单日活跃且产生有效订单的用户去重（通过导出当日订单列表，筛选重复数据后得出）", "7天内有1天骑行用户数", "7天内有4天骑行用户数"],
            usersOptions: []
        }
    },
    watch: {
      "$store.getters.serviceArea"(v) {
          this.mxIsSameActiveTabWithRoute(() => {
              this.getSearchData()
          })
      }
    },
    mounted() {
        this.curSelectTime = this.selectTimes[2]
        for (let i in this.usersLegends) {
            this.usersOptions.push({})
        }
        this.getSearchData()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] ? moment(timeArr[0]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
            this.listQuery.endDate = timeArr[1] ? moment(timeArr[1]).subtract(1, "days").format("YYYY-MM-DD HH:mm:ss") : ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.curSelectTime > 0) {
                let timeArr = this.setPickerTime(null, this.curSelectTime, "days")
                this.setQueryTime(timeArr)
                } else {
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
                }
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
                }
                this.listQuery.beginDate = psTime
                this.listQuery.endDate = peTime
                this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
            }
        },
        getSearchData() {
            if (this.curSelectTime < 0 && (!this.selectedTime[0] || !this.selectedTime[1])) {
                this.warningMsg("请选择日期范围!")
                return false
            }
            window.$common.fullLoading()
            this.getDataList()
        },
        async getDataList() {
            this.setSelectedTime()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
            this.listQuery.size = days + 1
            await funReport.GetList(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.reportData = response.items
                // 新增用户，有效骑行用户，活跃用户，高活用户
                let options = []
                for (let i in this.usersLegends) {
                    options[i] = this.setUsersOptions(this.usersLegends[i])
                }
                this.usersOptions = options
            })
        },
        setUsersOptions(title) {
            let legendData = [title]
            let xAxisData = []
            let series = []
            let days = moment(this.listQuery.endDate).diff(this.listQuery.beginDate, "day")
            // 横坐标
            for (let i = 0; i <= days; i++) {
                let time = moment(this.listQuery.beginDate).add(i, "days")
                xAxisData.push(moment(time).format("YYYY-MM-DD"))
            }
            for (let i = 0; i < legendData.length; i++) {
                let temp = {
                    name: legendData[i],
                    type: "bar",
                    // smooth: false,
                    // stack: "basic",
                    // areaStyle: {},
                    barWidth: "30%",
                    barMaxWidth: "20px",
                    // barMinHeight: "10",
                    markPoint: {
                        symbolSize: 40,
                        data: [
                            { type: "max", name: "最大值" },
                            { type: "min", name: "最小值" }
                        ]
                    },
                    markLine: {
                        label: {
                            position: "center"
                        },
                        data: [
                            { type: "average", name: "平均值" }
                        ]
                    },
                    data: []
                }
                for (let k = 0; k < xAxisData.length; k++) {
                    let userItem = this.reportData.find(x => {
                        return moment(xAxisData[k]).isSame(x.date) 
                    })
                    let item = 0
                    // 有车辆数据
                    if (userItem && userItem.user) {
                        let user = userItem.user
                        switch(i) {
                            case 0: 
                            if (title === this.usersLegends[0]) {
                                // 新增用户
                                item = user.newRegistrationCount || 0; 
                            } else if (title === this.usersLegends[1]) {
                                // 有效骑行用户
                                item = user.validRiderCount || 0; 
                            } else if (title === this.usersLegends[2]) {
                                // 活跃用户
                                item = user.activeUserCount || 0; 
                            } else if (title === this.usersLegends[3]) {
                                // 高活用户
                                item = user.highActiveUserCount || 0; 
                            }
                            break;
                        }
                    }
                    temp.data.push(item)
                }
                series.push(temp)
            }
            let curOptions = {
                tooltip: {
                    trigger: "axis",
                    formatter: function(params) {
                        let backVal = ""
                        for (let i in params) {
                            let par = params[i]
                            backVal = `${par.name}<br/>${par.seriesName}：${par.data}`
                        }
                        return backVal
                    }
                },
                legend: {
                    data: legendData,

                },
                grid: {
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                dataZoom: {
                    show: true,
                    left: "10%",
                    width: "83%",
                    start: 0,
                    end: 100,
                    labelFormatter: function (value) {
                        let prev = moment(xAxisData[value]).format("YYYY")
                        let next = moment(xAxisData[value]).format("MM/DD")
                        return prev + "\n" + next;
                    }
                },
                xAxis: {
                    type: "category",
                    boundaryGap: true,
                    axisLabel: {
                        // rotate: 30,
                        // interval: 0,
                        showMinLabel: true,
                        showMaxLabel: true
                    },
                    data: xAxisData,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: "dashed",
                            color: "#e8e8e8"
                        }
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        axisTick: {},
                        axisLabel: {
                            formatter: "{value}"
                        }
                    }
                ],
                series: series
            }
            return curOptions
        },
        selectRange(time) {
            this.curSelectTime = time
            this.isShowSelectTime = time > 0 ? false : true
            this.selectedTime = time > 0 ? [] : this.selectedTime
            if (time > 0) {
                this.getSearchData()
            }
        }
    }
}
</script>
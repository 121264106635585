import '@/assets/css/CssTools.css'
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import vueAxios from 'vue-axios'
import ElementUI from 'element-ui'
import '@/assets/css/element-variables.scss'
import 'font-awesome/css/font-awesome.css'
import common from '@/utils/common'
import mixins from './mixins/mixins'
import mxConfig from './mixins/mxConfig'
import store from './store'
import router from './router'
import echarts from 'echarts'
// import '@/mock'
import Print from 'vue-print-nb'

// 解决返回时模态框不消失的问题
ElementUI.Dialog.props.modalAppendToBody.default = false
ElementUI.Drawer.props.modalAppendToBody.default = false

// 挂载echarts
Vue.prototype.$echarts = echarts

// 注册common
window.$common = common

Vue.use(ElementUI)
Vue.use(Print)
Vue.use(vueAxios, axios)

//混入
Vue.mixin(mixins)
Vue.mixin(mxConfig)

Vue.config.productionTip = false

var vue = new Vue({
  // el: '#app',
  router,
  store,
  data: {
    eventHub: new Vue()
  },
  render: h => h(App)
}).$mount("#app")

export default vue


<!--
 * @Author: your name
 * @Date: 2020-05-07 09:43:40
 * @LastEditTime: 2020-05-28 09:15:49
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \client\src\components\Excels\JsonToExcel.vue
--> 
<style lang="scss">
  .json_to_excel {
    width: 300px !important;
  }
</style>

<template>
  <div></div>
</template>

<script>
export default {
  name: "jsonToExcel",
  data() {
    return {}
  },
  mounted() {},
  methods: {
    handleOpenExportPrompt(total, callback) {
      this.prompt("输入导出条数：", "导出Excel", {
        customClass: "json_to_excel",
        closeOnClickModal: false,
        //closeOnPressEscape: false,
        //closeOnHashChange: false,
        inputType: "number",
        inputValue: (total && total > 10000) ? total : 10000,
        inputErrorMessage: "请输入导出条数!",
        inputPlaceholder: "请输入导出的条数，默认10000条",
        inputValidator: (val) => {
          return val ? true: false
        },
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            let size = instance.inputValue
            size = Number(size)
            if (size > 0 && callback) {
              callback(size, () => {
                done()
              })
            } else {
              done()
            }
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>
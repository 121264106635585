<style lang="scss" scoped>
/deep/ .comp-demand-config-dialog {
    max-width: 500px;
    .point-btn {
        font-size: 14px;
        padding: 4px;
    }
}
</style>

<template>
    <div class="comp-demand-config">
        <el-dialog title="调度时间段配置" custom-class="c-el-dialog comp-demand-config-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-table :data="timeNodes" border size="small" highlight-current-row max-height="286">
                <el-table-column label="序号" width="60">
                    <template slot-scope="scope">
                        {{ ++scope.$index }}
                    </template>
                </el-table-column>
                <el-table-column label="时间段">
                    <template slot-scope="scope">
                        <el-time-picker class="c-datetime-range" is-range v-model="scope.row.selectedTime" :clearable="true" size="small" @change="(v) => changeTime(v, scope.row)"
                            range-separator="至"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            placeholder="选择时间范围"></el-time-picker>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="52">
                    <template slot-scope="scope">
                        <el-button class="point-btn" type="danger" size="mini" plain @click.stop="_delete(scope.row, scope.$index)"><i class="el-icon-delete-solid"></i></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <span slot="footer">
                <div class="fl">
                    <el-button type="warning" size="small" icon="el-icon-plus" @click="_add()">新增</el-button>
                </div>
                <el-button size="small" @click="_close()">取消</el-button>
                <el-tooltip class="item" effect="dark" content="确认配置后，请在列表上点击保存!" placement="bottom">
                    <el-button size="small" type="primary" @click="_save()">确认</el-button>
                </el-tooltip>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from "moment"
export default {
    name: "compDemandConfig",
    data() {
        return {
            showDialog: false,
            defaultData: {
                beginTime: "",
                endTime: "",
                selectedTime: []
            },
            timeNodes: [],
            prevStr: "1970-01-01"
        }
    },
    methods: {
        open(configs) {
            if (configs) {
                let arr = JSON.parse(JSON.stringify(configs))
                arr = arr.map(x => {
                    x.selectedTime = this.tranceTimeArr(x.beginTime, x.endTime)
                    return x
                }) || []
                this.timeNodes = JSON.parse(JSON.stringify(arr))
                this.showDialog = true
            }
        },
        // 转换时间格式
        tranceTimeArr(bTime, eTime) {
            let arr = []
            if (bTime && eTime) {
                if (!moment(bTime).isValid()) {
                    bTime = `${this.prevStr} ${bTime}`
                } else {
                    bTime = moment(bTime).format(`${this.prevStr} HH:mm:ss`)
                }
                if (!moment(eTime).isValid()) {
                    eTime = `${this.prevStr} ${eTime}`
                } else {
                    eTime = moment(eTime).format(`${this.prevStr} HH:mm:ss`)
                }
                arr = [bTime, eTime]
            } else {
                arr = []
            }
            return arr
        },
        _add() {
            let temp = JSON.parse(JSON.stringify(this.defaultData))
            let len = this.timeNodes.length
            let beforeHour = "07:00:00"
            if (len > 0) {
                beforeHour = this.timeNodes[len - 1].endTime
            }
            let beforeTime = `${this.prevStr} ${beforeHour}`
            let afterTime = moment(beforeTime).add(2, "hours")
            temp.beginTime = beforeHour
            temp.endTime = moment(afterTime).format("HH:mm:ss")
            temp.selectedTime = this.tranceTimeArr(temp.beginTime, temp.endTime)
            this.timeNodes.push(temp)
        },
        changeTime(arr, row) {
            if (arr) {
                row.beginTime = moment(arr[0]).format("HH:mm:ss")
                row.endTime = moment(arr[1]).format("HH:mm:ss")
            } else {
                row.beginTime = ""
                row.endTime = ""
            }
        },
        _delete(row, index) {
            this.timeNodes.splice(index, 1)
        },
        _save() {
            if (this.timeNodes.length <= 0) {
                this.warningMsg(`请配置时间段!`)
                return false
            }
            let warnTemp = undefined
            for (let i in this.timeNodes) {
                let item = this.timeNodes[i]
                if (!item.beginTime || !item.endTime) {
                    this.warningMsg(`第${Number(i) + 1}行，请配置时间段!`)
                    warnTemp = item
                    break
                } else {
                    if (i > 0) {
                        let bItem = this.timeNodes[i - 1]
                        let pEndTime = `${this.prevStr} ${bItem.endTime}` // 上一条结束时间点
                        let nStartTime = `${this.prevStr} ${item.beginTime}` // 当前的开始时间
                        if (moment(nStartTime).isBefore(pEndTime)) {
                            this.warningMsg(`第${Number(i) + 1}行，开始时间不能小于第${i}行的结束时间!`)
                            warnTemp = item
                            break
                        }
                    }
                }
            }
            if (warnTemp) { return false }
            let commitDatas = this.timeNodes.map(x => {
                let temp = {
                    beginTime: x.beginTime,
                    endTime: x.endTime
                }
                return temp
            })
            this.refreshData(commitDatas)
            this._close()
        },
        _close() {
            this.showDialog = false
        },
        refreshData(datas) {
            this.$emit("refreshData", datas)
        }
    }
}
</script>
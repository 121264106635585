<style lang="scss" scoped>
.page-moving-map {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99998;
    }
    .switch-btns {
        position: absolute;
        width: 300px;
        top: 5px;
        left: 5px;
        z-index: 8;
        display: flex;
        justify-content: space-between;
        border: 1px solid #d0e7ff;
        border-radius: 4px;
        a {
            display: block;
            flex: 1;
            text-align: center;
            padding: 6px 0;
            font-size: 13px;
            background-color: #ecf5ff;
        }
        a.actived, a:hover {
            background-color: #409EFF;
            color: #ffffff;
        }
    }
    /deep/ .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        width: 300px;
        z-index: 10;
        background-color: #ffffff;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        .tips {
            padding: 6px 10px;
            border-bottom: 1px solid #eee;
            background-color: #f5f5f5;
        }
        .el-table .cell {
            padding-left: 3px;
            padding-right: 3px;
        }
        .el-table th {
            padding: 4px 0;
            font-size: 13px;
        }
        .el-table td {
            padding: 2px 0;
            font-size: 12px;
        }
    }
    .full-screen, .point-list-btn {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover, .point-list-btn:hover {
        background-color: #f0f0f0;
    }
    .point-list-btn {
        display: block;
        right: 5px;
        top: 42px;
        font-size: 24px;
        padding: 0 3px;
    }
    .cluster-list {
        padding: 10px 12px;
        border-bottom: 1px dashed #eee;
        .cluster-item {
            margin-top: 3px;
        }
    }
    .cluster-list:hover {
        background-color: #f5f5f5;
    }
    /deep/ .selected-box {
        position: absolute;
        top: 40px;
        left: 5px;
        width: 300px;
        height: calc(100% - 90px);
        z-index: 10;
        background: #fff;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #cae4ff;
        .total-close {
            position: absolute;
            top: -8px;
            right: -8px;
            cursor: pointer;
        }
        .total-item {
            background: #a9d2ff;
            line-height: 1.6;
        }
        .el-row {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        .el-scrollbar {
            height: calc(100% - 160px);
        }
        .el-scrollbar__view {
            min-width: 100% !important;
        }
        .el-card {
            margin: 5px;
        }
        .el-card__body {
            padding: 6px 10px;
        }
        .el-table th>.cell {
            padding-left: 3px;
            padding-right: 3px;
        }
        .el-table--mini th {
            padding: 2px 0;
            font-size: 13px;
        }
        .el-table--mini td {
            padding: 1px 0;
            font-size: 12px;
        }
    }
}
</style>

<template>
    <div class="page-moving-map">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <!--<div class="fl">
                    <el-button-group>
                        <el-button type="primary" :plain="curType !== t" :key="'t' + t" v-for="t in types" 
                            @click="switchType(t)" size="medium">{{ t }}小时内</el-button>
                    </el-button-group>
                </div>-->
                <div class="fl mrgt5">
                    <span class="col_danger">
                        <i class="el-icon-warning"></i>
                        每日12:30以后更新数据
                    </span>
                </div>
                <div class="fr">
                    <el-tag type="info mrgr5 mrgb5">挪车结束时间：</el-tag>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedDate" type="daterange" size="medium"
                        :picker-options="pickerOptions" :clearable="false" @change="_search()"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                    <el-time-picker class="c-datetime-range mrgr5 mrgb5" is-range v-model="selectedTime" size="medium" @change="changeTime"
                        range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            注：每日12:30以后更新数据。<br />
                            以下可查询指定日期段对应的每小时分时数据。<br />
                            通过指定挪车结束时间段，查询挪车后<template v-for="t in types">{{ t }}小时内，</template>产单的平均数量。
                        </div>
                        <el-tag class="mrgl5" type="warning"><i class="el-icon-view f12"></i> 查看说明</el-tag>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <el-button type="primary" :plain="!(cueSelectTimers.length === 2 && time >= cueSelectTimers[0] && time <= cueSelectTimers[1])" size="medium"
                            v-for="(item, time) in 24" :key="item" @click="selectTime(time)"
                            @mousedown.native="mouseSelectTime(time, 0)" @mouseup.native="mouseSelectTime(time, 1)">{{ time }}时</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='container'>
            <!--<span class="total-count col_primary" title="当前查询出的总数">
                总数:
                <span v-for="(item, index) in counts" :key="'c' + index">
                    【{{ types[index] }}小时:{{ item }}单】
                </span>
            </span>-->
            <div class="switch-btns">
                <a :class="curType === t ? 'actived' : ''" href="javascript:void(0);" v-for="t in types" :key="'t' + t"
                    @click="switchType(t)">{{ t }}小时内</a>
            </div>
            <div class="total-count">
                <div class="tips tc">
                    <span class="col_danger"><span class="fwb">挪入总量：</span>{{ moveTotals }}辆</span>
                    <span class="col_info mrgl15"><span class="fwb">无站点量：</span>{{ noParkTotals }}辆</span>
                </div>
                <el-table :data="counts" size="mini" v-if="isShowFold">
                    <el-table-column label="时间段内" align="center">
                        <template slot-scope="scope">{{ types[scope.$index] }}小时内</template>
                    </el-table-column>
                    <!--<el-table-column label="挪车数量" prop="count" align="center"></el-table-column>-->
                    <el-table-column label="订单总数" prop="total" align="center"></el-table-column>
                    <el-table-column label="平均单量" prop="average" align="center"></el-table-column>
                </el-table>
            </div>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <span class="point-list-btn" @click="isShowFold = !isShowFold" :title="isShowFold ? '收起' : '展开'">
                <i :class="!isShowFold ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
            </span>
            <div class="selected-box" v-if="isShowParkDetail">
                <div class="total-close" @click="isShowParkDetail = false"><i class="el-icon-error f20 col6"></i></div>
                <el-card class="total-item">
                    <!--<el-row v-for="(t, index) in parkDetailCounts" :key="'total' + index">
                        <el-col :span="12">
                            <span :class="types[index] === curType ? 'col_primary' : ''">{{ types[index] }}小时内: {{ t.total }}</span>
                        </el-col>
                    </el-row>-->
                    <el-table :data="parkDetailCounts" size="mini">
                        <el-table-column align="center">
                            <template #header><span class="col_danger">挪入量: {{ parkDetailCounts[0] && parkDetailCounts[0].count }}</span></template>
                            <template slot-scope="scope">
                                <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ types[scope.$index] }}小时内</span>
                            </template>
                        </el-table-column>
                        <!--<el-table-column label="挪车数量" prop="count" align="center">
                            <template slot-scope="scope">
                                <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ scope.row.count }}</span>
                            </template>
                        </el-table-column>-->
                        <el-table-column label="订单总数" prop="total" align="center">
                            <template slot-scope="scope">
                                <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ scope.row.total }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="平均单量" prop="average" align="center">
                            <template slot-scope="scope">
                                <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ scope.row.average }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>
                <el-scrollbar :style="{ 'height': scrollbarHeight }">
                    <el-card shadow="hover" v-for="(item, index) in parkDetails" :key="'table' + index">
                        <div class="mrgb5" @click="getParkDetail(item)"><span class="fwb">
                            站点名称：</span><span class="fwb col6 mrgr5">{{ item.name }}</span>
                            <i class="el-icon-view col_primary f14 curp"></i>
                        </div>
                        <el-table :data="item.clusters" size="mini" border>
                            <el-table-column align="center">
                                <template #header><span class="col_warning">挪入量: {{ item.clusters[0] && item.clusters[0].count }}</span></template>
                                <template slot-scope="scope">
                                    <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ types[scope.$index] }}小时内</span>
                                </template>
                            </el-table-column>
                            <!--<el-table-column label="挪车数量" prop="count" align="center">
                                <template slot-scope="scope">
                                    <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ scope.row.count }}</span>
                                </template>
                            </el-table-column>-->
                            <el-table-column label="订单总数" prop="total" align="center">
                                <template slot-scope="scope">
                                    <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ scope.row.total }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="平均单量" prop="average" align="center">
                                <template slot-scope="scope">
                                    <span :class="types[scope.$index] === curType ? 'col_primary' : ''">{{ scope.row.average }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-card>
                </el-scrollbar>
            </div>
        </div>

        <!--查看站点的批次统计详情-->
        <park-batch :ref="refMoveParkBatch"></park-batch>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funReport from "@/api/report"
import * as funMaintenance from "@/api/maintenance"
import moment from 'moment'
import ParkBatch from "./components/moveParkBatch"

let dataVars = {
    map: null, // 地图
    cluster: {}, // 点聚合
    polygonMap: null
}
export default {
    name: "pageMovingMap",
    mixins: [configMixins],
    components: { ParkBatch },
    data() {
        let that = this
        let showTypes = [6, 12] // , 24, 48
        return {
            refMoveParkBatch: "refMovingMapToMoveParkBatch",
            pageHeader: {
                desc: "查看挪车后指定时间段内的产单数据的热力图。"
            },
            listQuery: {
                area: "",
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: ""
            },
            types: showTypes, // , 24, 48
            scrollbarHeight: (() => {
                return `calc(100% - ${68 + showTypes.length * 23}px)`
            })(),
            moveTotals: 0,
            noParkTotals: 0,
            counts: [],
            curType: null,
            tempParkings: [],
            moveParkings: [],
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: [moment().subtract(2, "d").format("YYYY-MM-DD"), moment().subtract(2, "d").format("YYYY-MM-DD")],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            isShowFold: false,
            setMarkerTime: null,
            parkings: [], // 站点
            centerMarker: null,
            coverMarker: null,
            coverPaths: [], // 服务区多边形点位
            isShowParkDetail: false,
            parkDetailCounts: [],
            parkDetails: []
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                this.setPolygonContains()
                this.initLoadData()
            })
        }
    },
    async mounted() {
        this.curType = this.types[0]
        this.setSysPageHeader(this.pageHeader)
        this.getCurrentAreaData()
        await this.initMap()
        await this.initLoadData()
    },
    unmounted() {
        dataVars.map?.destroy()
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psDate = moment(this.selectedDate[0]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate[0]).format("00:00:00")
                }
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peDate = moment(this.selectedDate[1]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate[1]).format("23:59:59")
                }
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedDate = [this.listQuery.beginDate, this.listQuery.endDate]
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [`${this.listQuery.beginDate} ${this.listQuery.beginTime}`, `${this.listQuery.endDate} ${this.listQuery.endTime}`]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            this.tempParkings = []
            this.moveParkings = []
            this.noParkTotals = 0
            this.counts = []
            await funMaintenance.GetMoveTaskParks(this.listQuery).then(response => {
                this.tempParkings = response
                // 分别计算总数量
                for (let i in this.types) {
                    let temp = this.getMoveTaskCount(response, this.types[i])
                    this.counts.push(temp)
                }
                // 筛选出可显示的站点数据，相同站点只显示一次
                for (let i in response) {
                    let item = response[i]
                    let parkItem = this.parkings.find(x => { return x.id == item.parkId })
                    if (!parkItem) {
                        // 匹配不到站点信息
                        this.noParkTotals += item.inCount
                        // console.error(item.parkId)
                        continue
                    } else {
                        // console.log(parkItem.id + "，" + item.parkId)
                    }
                    item.lng = parkItem.centerLng
                    item.lat = parkItem.centerLat
                    item.name = parkItem.name
                    this.moveParkings.push(item)
                }
                // console.log(this.moveParkings)
            })
            await this.setMarkerClusterer()
            window.$common.closeFullLoading()
        },
        getMoveTaskCount(items, type) {
            this.moveTotals = 0
            this.noParkTotals = 0
            let temp = {
                total: 0,
                average: 0
            }
            for (let i in items) {
                let item = items[i]
                temp.total += item[`s${type}`]
                this.moveTotals += item.inCount
            }
            temp.average = (temp.total / this.moveTotals) || 0
            // 最多保留两位小数
            let strAver = String(temp.average)
            let strAverIndex = strAver.indexOf(".")
            if (strAverIndex !== -1) {
                let len = strAver.length - strAverIndex - 1
                if (len > 2) {
                    temp.average = temp.average.toFixed(2)
                } else {
                    temp.average = temp.average.toFixed(1)
                }
            }
            return temp
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverMarker = areaItem || {}
                this.coverPaths = areaItem.pointList || []
            }
        },
        // 加载数据
        async initLoadData() {
            this.isShowFold = true
            this.isShowParkDetail = false
            await this.getPackings()
            await this._search()
        },
        // 站点数据
        async getPackings() {
            this.parkings = []
            let area = this.$store.getters.serviceArea || ""
            // 有相同
            let hasSameParks = this.hasSameCoverageAreas || []
            let parkAreas = [area]
            for (let i in hasSameParks) {
                let item = hasSameParks[i]
                let index = item.indexOf(area)
                if (index !== -1) {
                    parkAreas = item
                    break
                }
            }
            parkAreas.forEach(async (item) => {
                await funReport.GetParkingLocations({ area: item }).then(response => {
                    this.parkings = this.parkings.concat(response)
                })
            })
            // 龙潭寺特殊处理
            /* let longIndex = area.indexOf("龙潭寺")
            if (longIndex !== -1) {
                await funReport.GetParkingLocations({ area: "龙潭寺" }).then(response => {
                    this.parkings = response
                })
                await funReport.GetParkingLocations({ area: "龙潭寺（成都）" }).then(response => {
                    this.parkings = this.parkings.concat(response)
                })
            } else {
                await funReport.GetParkingLocations({ area }).then(response => {
                    this.parkings = response
                })
            } */
        },
        // 时间段选择
        changeTime(arr) {
            this.cueSelectTimers = []
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
                let sTime = moment(arr[0]).format("HH:00:00")
                let eTime = moment(arr[1]).format("HH:59:59")
                // 时间相同，配置选中效果
                if (this.listQuery.beginTime === sTime && this.listQuery.endTime === eTime) {
                    this.cueSelectTimers = [moment(arr[0]).format("H"), moment(arr[1]).format("H")]
                }
                this._search()
            }
        },
        // 快捷时间段选择
        selectTime(time) {
            this.cueSelectTimers = [time, time]
            let psTime = ""
            let peTime = ""
            let hour = time < 10 ? ("0" + time ) : time
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + hour + ":00:00")
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + hour + ":59:59")
            }
            this.selectedTime = [psTime, peTime]
            this._search()
        },
        mouseSelectTime(time, type) {
            if (type === 0) {
                // 鼠标按下
                this.curMouseDownTime = time
            } else if (type === 1) {
                // 鼠标放开
                if (this.curMouseDownTime !== time) {
                    let psTime = ""
                    let peTime = ""
                    let sHour = null
                    let eHour = null
                    if (time > this.curMouseDownTime) {
                        this.cueSelectTimers = [this.curMouseDownTime, time]
                        sHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                        eHour = time < 10 ? ("0" + time ) : time
                    } else if (time < this.curMouseDownTime) {
                        this.cueSelectTimers = [time, this.curMouseDownTime]
                        sHour = time < 10 ? ("0" + time ) : time
                        eHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                    }
                    if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                        psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + sHour + ":00:00")
                    }
                    if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                        peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + eHour + ":59:59")
                        // peTime = moment(peTime).subtract(1, "hours")
                    }
                    this.selectedTime = [psTime, peTime]
                    this._search()
                }
            }
        },
        _search() {
            this.getDataList()
        },
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.MarkerCluster"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
                // 生成点聚合
                this.initMarkerClusterer()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 服务区有中心点则异动到服务区中心
            if (this.coverMarker && this.coverMarker.centerLng && this.coverMarker.centerLat) {
                let curLnglat = [this.coverMarker.centerLng, this.coverMarker.centerLat]
                this.moveToCenter({ lnglat: curLnglat })
            }
        },
        // 生成地图点聚合
        initMarkerClusterer() {
            // 生成地图点位
            let points = this.getMarkerClustererPoints()
            dataVars.cluster = new AMap.MarkerClusterer(
                dataVars.map,     // 地图实例
                points, // 海量点数据，数据中需包含经纬度信息字段 lnglat
                {
                    gridSize: 40,
                    renderClusterMarker: this._renderClusterMarker,
                    renderMarker: this._renderMarker
                    // averageCenter: false
                }
            );
            // 点击聚合点
            dataVars.cluster.on("click", (ev) => {
                this.setMarkerTime = setTimeout(() => {
                    let pos = ev.marker.getPosition()
                    let clusterCenter = [pos.lng, pos.lat]
                    this.moveToCenter({ lnglat: clusterCenter })
                    // console.log(ev)
                }, 300)
            })
        },
        _renderClusterMarker(context) {
            // console.log(context)
            let count = this.getClusterCount(true, context.clusterData).average
            let total = 0
            if (this.counts && this.counts.length > 0) {
                total = this.counts[this.types.findIndex(x => x === this.curType)].total
            }
            total = total || 1
            let factor = Math.pow(count / total, 1 / 18)
            let div = document.createElement('div')
            let Hue = 180 - factor * 180
            let bgColor = 'hsla(' + Hue + ',100%,45%,0.7)'
            let fontColor = 'hsla(' + Hue + ',100%,90%,1)'
            let borderColor = 'hsla(' + Hue + ',100%,40%,1)'
            let shadowColor = 'hsla(' + Hue + ',100%,90%,1)'
            div.style.backgroundColor = bgColor
            let size = Math.round(30 + Math.pow(count / total, 1 / 5) * 20);
            div.style.width = div.style.height = size + 'px'
            div.style.border = 'solid 1px ' + borderColor
            div.style.borderRadius = size / 2 + 'px'
            div.style.boxShadow = '0 0 5px ' + shadowColor
            div.innerHTML = count // context.count
            div.style.lineHeight = size + 'px'
            div.style.color = fontColor
            div.style.fontSize = '14px'
            div.style.textAlign = 'center'
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2))
            context.marker.setContent(div)
            // 单击事件
            context.marker.on("click", () => {
                this.clickClusterPoint(context.clusterData)
            })
            // 绑定双击事件--放大，并移动到中心点
            context.marker.on("dblclick", (ev) => {
                if (this.setMarkerTime) {
                    clearTimeout(this.setMarkerTime)
                }
                let clusterCenter = [ev.lnglat.lng, ev.lnglat.lat]
                dataVars.map.setZoomAndCenter(dataVars.map.getZoom() + 1, clusterCenter)
            })
        },
        getClusterCount(isCluster, clusterDatas, type) {
            let temp = {
                total: 0,
                count: 0,
                average: 0
            }
            if (isCluster) {
                // 聚合点
                for (let i in clusterDatas) {
                    let item = clusterDatas[i]
                    let origins = item._amapMarker.originData
                    for (let k in origins) {
                        temp.total += origins[k][0].extData[`s${type || this.curType}`]
                        temp.count += origins[k][0].extData.inCount
                    }
                    /* totals = origins.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue[0].extData[`s${type || this.curType}`]
                    }, totals) */
                }
            } else {
                // 非聚合点
                temp.total = clusterDatas[0].extData[`s${type || this.curType}`]
                temp.count = clusterDatas[0].extData.inCount || 0
            }
            temp.average = (temp.total / temp.count) || 0
            // 最多保留两位小数
            let strAver = String(temp.average)
            let strAverIndex = strAver.indexOf(".")
            if (strAverIndex !== -1) {
                let len = strAver.length - strAverIndex - 1
                if (len > 2) {
                    temp.average = temp.average.toFixed(2)
                } else {
                    temp.average = temp.average.toFixed(1)
                }
            }
            return temp
        },
        _renderMarker(context) {
            /* context.marker.setAnchor("bottom-center")
            context.marker.setContent(`<img width="14" height="22" src="/static/images/map/poi-marker-default.png" />`)
            context.marker.setOffset(new AMap.Pixel(0, 4)) */ 
            let count = this.getClusterCount(false, context.data).average
            let total = 0
            if (this.counts && this.counts.length > 0) {
                total = this.counts[this.types.findIndex(x => x === this.curType)].total
            }
            total = total || 1
            let factor = Math.pow(count / total, 1 / 18)
            let div = document.createElement('div')
            let Hue = 180 - factor * 180
            let bgColor = 'hsla(' + Hue + ',100%,45%,0.7)'
            let fontColor = 'hsla(' + Hue + ',100%,100%,1)'
            let borderColor = 'hsla(' + Hue + ',100%,40%,1)'
            let shadowColor = 'hsla(' + Hue + ',100%,90%,1)'
            div.style.backgroundColor = bgColor
            let size = Math.round(30 + Math.pow(count / total, 1 / 5) * 20);
            div.style.width = div.style.height = size + 'px'
            div.style.border = 'solid 1px ' + borderColor
            div.style.borderRadius = size / 2 + 'px'
            div.style.boxShadow = '0 0 5px ' + shadowColor
            div.innerHTML = count // context.count
            div.style.lineHeight = size + 'px'
            div.style.color = fontColor
            div.style.fontSize = '14px'
            div.style.textAlign = 'center'
            context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2))
            context.marker.setContent(div)
            // 单击事件
            context.marker.on("click", () => {
                console.log(context)
                this.clickClusterPoint(context.data, true)
            })
        },
        clickClusterPoint(clusterData, isMarker) {
            // console.log(clusterData)
            this.isShowParkDetail = false
            this.parkDetailCounts = []
            this.parkDetails = []
            for (let i in this.types) {
                if (isMarker) {
                    // 代表是点，非聚合
                    this.parkDetailCounts[i] = this.getClusterCount(false, clusterData, this.types[i]) // clusterData[0].extData[`s${this.types[i]}`]
                } else {
                    // 代表是聚合，非点
                    this.parkDetailCounts[i] = this.getClusterCount(true, clusterData, this.types[i])
                }
            }
            for (let i in clusterData) {
                let item = clusterData[i]
                if (isMarker) {
                    // 代表是点，非聚合
                    item.extData.clusters = this.types.map(x => {
                        return this.getClusterCount(false, [item], x)
                    })
                    this.parkDetails.push(item.extData)
                } else {
                    // 代表是聚合，非点
                    let originItems = item._amapMarker.originData
                    for (let k in originItems) {
                        originItems[k][0].extData.clusters = this.types.map(x => {
                            return this.getClusterCount(false, originItems[k], x)
                        })
                        this.parkDetails.push(originItems[k][0].extData)
                    }
                }
            }
            console.log(this.parkDetails)
            setTimeout(() => {
                this.isShowParkDetail = true
            }, 0)
        },
        getParkDetail(row) {
            let params = {
                name: row.name,
                area: this.listQuery.area || "",
                endParkId: row.parkId,
                beginDate: this.listQuery.beginDate || "",
                endDate: this.listQuery.endDate || "",
                beginTime: this.listQuery.beginTime || "",
                endTime: this.listQuery.endTime || "",
                page: 1,
                size: 10000
            }
            this.$refs[this.refMoveParkBatch].open(params, this.types)
        },
        // 设置点聚合数据
        async setMarkerClusterer() {
            let points = this.getMarkerClustererPoints()
            if (points.length > 0) {
                this.moveToCenter({ lnglat: points[0].lnglat })
            }
            await dataVars.cluster.setData(points)
        },
        getMarkerClustererPoints() {
            let points = this.moveParkings.map((x, i) => {
                let lnglat = [x.lng, x.lat]
                let temp = { lnglat, weight: i, extData: { ...x } }
                return temp
            }) || []
            // points = points.slice(0, 5)
            return points
        },
        switchType(type) {
            this.curType = type
            this.setMarkerClusterer()
        },
        moveToCenter(item) {
            // console.log(item)
            dataVars.map.setCenter(item.lnglat)
            /* if (this.centerMarker) {
                this.centerMarker.setPosition(item.lnglat)
            } else {
                this.centerMarker = new AMap.Marker({
                    position: item.lnglat,
                    anchor: "bottom-center",
                    content: `<img width="19" height="32" src="/static/images/map/poi-marker-red.png" />`,
                    offset: new AMap.Pixel(0, 4)
                });
                this.centerMarker.setMap(dataVars.map)
            } */
        }
    }
}
</script>
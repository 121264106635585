<style scoped lang="scss">
  /deep/ .el-menu--collapse .el-menu-item span, 
  .el-menu--collapse .el-submenu .el-submenu__title span {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }
  
</style>
<style>
  .el-menu--collapse .el-menu-item .el-submenu__icon-arrow,
  .el-menu--collapse .el-submenu .el-submenu__title .el-submenu__icon-arrow {
    display: none !important;
  }
</style>

<template>
  <div>
    <!--子集中只有一条且不需要一直显示父级时-->
    <el-menu-item :key="Math.random() + '_bar_onlyone_' + item.path + '_' + index" :index="resolvePath(item.children[0].path)"
      v-if="hasOneShowingChild(item) && !item.alwaysShow"
      @click="toCurrentTab(item)">
        <template>
          <i class="el-self-icon" :class="item.children[0].meta.icon"></i>
          <span slot="title">{{ item.children[0] && item.children[0].meta.title }}</span>
        </template>
    </el-menu-item>
    <!--如果是外部链接则新打开页面-->
    <app-link v-if="isUrl(item.path)" :to="item.path">
      <el-menu-item :key="Math.random() + '_bar_' + item.path + '_' + index" index="">
          <template slot="title">
            <i class="el-self-icon" :class="item.meta.icon" v-if="isShowIcon"></i>
            <span slot="title">{{ item && item.meta.title }}</span>
          </template>
      </el-menu-item>
    </app-link>
    <!--不是外部链接-->
    <template v-if="!isUrl(item.path)">
      <el-menu-item :key="Math.random() + '_bar_' + item.path + '_' + index" :index="resolvePath(item.path)"
        v-if="!hasOneShowingChild(item) && !item.hidden && (!item.children || item.children.length <= 0)" 
        @click="toCurrentTab(item)">
          <template slot="title">
            <i class="el-self-icon" :class="item.meta.icon" v-if="isShowIcon"></i>
            <span slot="title">{{ item && item.meta.title }}</span>
          </template>
      </el-menu-item>
    </template>
    <!--正常菜单显示-->
    <el-submenu v-if="(!hasOneShowingChild(item) && !item.hidden && item.children && item.children.length > 0) || (hasOneShowingChild(item) && item.alwaysShow)" 
      @click.native="toCurrentTab(item)" :index="resolvePath(item.path)" :key="Math.random() + '_bar_menu_' + item.path + '_' + index">
      <template slot="title">
        <i class="el-self-icon" :class="item.meta && item.meta.icon"></i>
        <span slot="title">{{ item.meta && item.meta.title }}</span>
      </template>
      <!--无限极菜单-->
      <side-bar-item v-for="(child, childIndex) in item.children" :key="Math.random() + '_' + index + '_' + childIndex" 
      :index="childIndex" :item="child" :parentPath="item.path"></side-bar-item>
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import AppLink from '../components/Link'
import { getActiveTabStorage } from '@/utils/storages'
export default {
  name: "sideBarItem",
  components: {
    AppLink
  },
  props: {
    index: {
      default: null,
      type: Number
    },
    item: {
      type: Object
    },
    parentPath: {
      default: "",
      type: String
    }
  },
  inject: ["sideBarProvide"],
  data() {
    return {
      isShowIcon: null
    }
  },
  mounted() {
    this.isShowIcon = window && window.$common.isShowMenuIcon
  },
  methods: {
    hasOneShowingChild(items) {
        let children = items.children
        if (items.hidden) {
          return false
        }
        if (children && children.length > 0) {
          let showingChildren = []
          for (let i in children) {
            let item = children[i]
            if (!item.hidden) {
              showingChildren.push(item)
            }
          }
          if (showingChildren.length === 1) {
            return true
          }
        }
        return false
      },
      /* setCurrentTab(parentPath, childItem) {
        let temp = {
          title: childItem.meta && childItem.meta.title,
          path: this.resolvePath(parentPath, childItem.path)
        }
        // 设置当前选中显示的tab标签
        this.$store.dispatch("setActiveTab", temp)
        // 添加到tab标签
        this.$store.dispatch("addTabPanes", temp)
        //如果是移动设备，点击菜单后消失
        if (this.$store.getters.device == "mobile") {
          this.$store.dispatch("setCollapse")
        }
      }, */
      toCurrentTab(childItem) {
        //console.log(childItem.path)
        // 如果菜单可以有下级且自己本身也需要打开页面，则配置isSkip
        if (childItem.meta && childItem.meta.isSkip && this.sideBarProvide.selectedMenuPath) {
          this.sideBarProvide.selectedPath = this.sideBarProvide.selectedMenuPath
        }
        // 获取当前需要跳转的地址
        let curSelectPath = this.sideBarProvide.selectedPath
        // 当前对象用于显示tab
        let temp = {
          title: childItem.meta && childItem.meta.title,
          name: childItem.name,
          path: curSelectPath // this.resolvePath(parentPath, childItem.path)
        }
        // 获取激活的tab数据
        let tabStorage = getActiveTabStorage()
        let query = {}
        if (tabStorage && tabStorage.path === curSelectPath) {
          query = tabStorage.query
        }
        // 路由跳转
        this.$router.push({ path: curSelectPath, query })
        this.$store.dispatch("addTagsInclude", temp) // tab点击记录缓存，下次不用刷新
        // console.log(this.$store.getters.tagsIncludes)
        // 阻止冒泡
        event.stopPropagation()
      },
      resolvePath(routePath) {
        return path.resolve(this.parentPath, routePath)
      },
      isUrl(url) {
        if (isExternal(url)) {
          return true
        }
        return false
      }
  }
}
</script>
<style lang="scss" scoped>
.page-device-screen {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .device-time {
        display: block;
        position: absolute;
        top: 5px;
        left: 45px;
        z-index: 10;
        background: rgba(255, 255, 255, .3);
        padding: 8px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        font-size: 12px;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen, .point-list-btn {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover, .point-list-btn:hover {
        background-color: #f0f0f0;
    }
    .point-list-btn {
        left: 5px;
        right: auto;
        top: 5px;
        font-size: 24px;
        padding: 0 3px;
    }
    .cluster-list {
        padding: 10px 12px;
        border-bottom: 1px dashed #eee;
        .cluster-item {
            margin-top: 3px;
        }
    }
    .cluster-list:hover {
        background-color: #f5f5f5;
    }
    /deep/ .point-box {
        position: absolute;
        top: 45px;
        left: 5px;
        width: 320px;
        height: auto;
        z-index: 10;
        background: #fff;
        .el-table--border {
            border-top: 0;
        }
        .el-tabs__content {
            padding: 0 !important;
        }
        .el-table__body-wrapper {
            max-height: 470px;
            overflow-y: auto;
        }
    }
    /deep/ .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        .point-box {
            .el-table__body-wrapper {
                max-height: calc(82vh - 40px);
                overflow-y: auto;
            }
        }
    }
    /deep/ .row-highlight {
        td {
            background: #badaff;
        }
    }
}
</style>

<template>
    <div class="page-device-screen">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-tooltip effect="dark" content="更新当前实时分析数据，可获取到最新配置点位的车辆数据。" placement="top">
                        <el-button class="mrgb5" type="primary" size="medium" @click="_updateData()"><i class="fa fa-refresh"></i> 更新数据</el-button>
                    </el-tooltip>
                    <el-button class="mrgb5" type="warning" size="medium" @click="openConfig()"><i class="fa fa-edit"></i> 配置点位</el-button>
                </div>
                <div class="fr">
                    <el-date-picker class="mrgr5 mrgb5" v-model="selectedDate" @change="initLoadData()"
                        type="date" size="medium" placeholder="请选择日期">
                    </el-date-picker>
                    <el-time-select class="c-el-input mrgr5 mrgb5" v-model="selectedStartTime" size="medium" 
                        @change="selectTime" placeholder="选择查询时间点" :editable="false"
                        :picker-options="{
                            start: '00:00',
                            step: '00:30',
                            end: '23:30'
                        }"
                    ></el-time-select>
                    <el-button type="primary" size="medium" @click="initLoadData()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            注：每{{ spaceTime }}分钟更新一次实时车辆数据。<br />
                            实时车辆：仅代表【{{ deviceItem.time }}】之前的车辆实时定位。<br />
                            通过配置点位，可查看指定点位及周边指定范围的车辆的定位、数量。<br />
                        </div>
                        <el-tag class="mrgl5" type="warning" size="medium"><i class="el-icon-view f12"></i> 查看说明</el-tag>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <template v-for="(item, time) in pointTimes">
                            <el-button type="primary" size="medium" v-if="time % 2 === 0"
                                :plain="selectedStartTime !== item"
                                :key="item" @click="selectTime(item)">{{ time / 2 }}时</el-button>
                        </template>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='mapDeviceScreen'>
            <span class="device-time">数据更新时间：{{ deviceItem.time }}</span>
            <span class="total-count col_primary" title="当前查询出的总车辆数">总数量：{{ deviceItem.gps && deviceItem.gps.length }}</span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <template v-if="pointGroups.length > 0">
                <span class="point-list-btn" @click="isShowFold = !isShowFold" :title="isShowFold ? '点位列表收起' : '点位列表展开'">
                    <i :class="isShowFold ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
                </span>
                <div class="point-box" v-if="isShowFold">
                    <el-tabs v-model="activeIndex" type="border-card" @tab-click="handleGroupClick">
                        <el-tab-pane :label="tag.category || '未分类'" :name="tindex.toString()" v-for="(tag, tindex) in pointGroups" :key="'tab-' + tindex">
                            <el-table :data="tag.items" border size="small"
                                :row-class-name="tableRowClassName"
                                @row-click="rowClick">
                                <el-table-column label="点位名称" >
                                    <template slot-scope="scope">
                                        {{ scope.row.name }}
                                        <span class="curp" title="查看经纬度" @click.stop="viewLocation(scope.row)"><i class="el-icon-view col_primary"></i></span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="车辆数" width="65">
                                    <template slot-scope="scope">
                                        <span class="col_danger">{{ scope.row.vehicleCount }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </template>
        </div>
        <!--指定点位配置-->
        <point-config :ref="refPointConfig" @refreshData="_updateData"></point-config>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from 'moment'
import PointConfig from "./pointConfig"

let dataVars = {
    map: null, // 地图
    polygonMap: null,
    markers: null,
    pointMarkers: [],
    circleMarkers: [],
    infoWindow: null
}
export default {
    name: "pageDeviceScreen",
    components: { PointConfig },
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "用于查询实时车辆数据，便于了解设置的点位车辆是否充足。"
            },
            refPointConfig: "refDeviceScreenPointConfig",
            listQuery: {
                area: "",
                beginDate: "",
                endDate: ""
            },
            activeIndex: "0",
            pointTags: [],
            pointGroups: [],
            deviceItem: [],
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: moment(),
            selectedTime: [],
            spaceTime: 30, // 选择间隔时间30分钟
            pointTimes: [],
            selectedStartTime: "",
            isShowFold: true,
            centerPos: null,
            coverMarker: null,
            coverPaths: [] // 服务区多边形点位
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.centerPos = null
                this.initLoadData()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        this.setPointTimes()
        this.centerPos = null
        await this.initMap()
        await this.initLoadData()
        // this.openConfig()
    },
    methods: {
        setPointTimes() {
            for (let i = 0; i < 24; i++) {
                let hour = i < 10 ? ("0" + i ) : i
                let time = moment().format(`YYYY-MM-DD ${hour}:00:00`)
                let sTime = moment(time).format("HH:mm")
                let eTime = moment(time).add(this.spaceTime, "m").format("HH:mm")
                this.pointTimes.push(sTime, eTime)
            }
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && moment(this.selectedDate).isValid()) {
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate).format("00:00:00")
                }
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate).format("23:59:59")
                }
                psDate = moment(this.selectedDate).format(`YYYY-MM-DD ${psTime}`)
                peDate = moment(this.selectedDate).format(`YYYY-MM-DD ${peTime}`)
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedTime = [`${this.listQuery.beginDate}`, `${this.listQuery.endDate}`]
        },
        async initLoadData() {
            this.clearAllMarkers()
            this.activeIndex = "0"
            this.listQuery.area = this.$store.getters.serviceArea || ""
            window.$common.fullLoading()
            this.getCurrentAreaData()
            this.setPolygonContains()
            await this.getPointTags()
            await this.getDataList()
            // 设置分组
            this.setPointGroup()
            // 设置车辆点位marker
            this.setAllMarkers()
            // 设置指定点的点位
            this.setPointMarkers()
            // 设置指定点位覆盖的范围
            this.setPointCircle()
            // 创建窗体
            this.createinfoWindow()
            window.$common.closeFullLoading()
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverMarker = areaItem || {}
                this.coverPaths = areaItem.pointList || []
            }
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                bubble: true,
                path: path,
                strokeColor: "#f56c6c", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 服务区有中心点则异动到服务区中心
            if (this.coverMarker && this.coverMarker.centerLng && this.coverMarker.centerLat) {
                let curLnglat = [this.coverMarker.centerLng, this.coverMarker.centerLat]
                this.moveToCenter({ lnglat: curLnglat })
            }
        },
        async getPointTags() {
            await funReport.GetPointConfigCategories(this.listQuery).then(response => {
                this.pointTags = response
            })
        },
        async getDataList() {
            this.setSelectedTime()
            await funReport.GetDeviceScreen(this.listQuery).then(response => {
                this.deviceItem = response || {}
                this.deviceItem.time = moment(this.deviceItem.time).format("YYYY-MM-DD HH:mm:ss")
            })
        },
        // 分组
        setPointGroup() {
            let group = this.pointTags.map(x => {
                let pointItems = []
                if (this.deviceItem && this.deviceItem.pointData) {
                    pointItems = this.deviceItem.pointData.filter(m => m.category === x)
                }
                let temp = {
                    category: x,
                    items: pointItems
                }
                return temp
            })
            this.pointGroups = group
        },
        // 切换分类
        handleGroupClick(tab, event) {
            this.centerPos = null
            this.activeIndex = tab.index.toString()
            this.setPointMarkers()
            this.setPointCircle()
        },
        tableRowClassName({row, index}) {
            return row.isSelected ? "row-highlight" : ""
        },
        // 时间段选择
        selectTime(time) {
            this.selectedTime = []
            this.selectedStartTime = time
            let psTime = ""
            let peTime = ""
            if (this.selectedDate && moment(this.selectedDate).isValid()) {
                psTime = moment(this.selectedDate).format("YYYY-MM-DD " + time + ":00")
                peTime = moment(psTime).add(this.spaceTime, "m").format("YYYY-MM-DD HH:mm:00")
                this.selectedTime = [psTime, peTime]
                this.initLoadData()
            }
        },
        _updateData() {
            if (this.deviceItem && this.deviceItem.id) {
                funReport.UpdateDeviceScreen({ id: this.deviceItem.id }).then(res => {
                    this.centerPos = null
                    this.initLoadData()
                })
            } else {
                this.warningMsg("当前没有车辆数据!")
                return false
            }
        },
        async initMap() {
            await window.$common.loadGaoDeMap(null, (Amap) => {
                // 生成地图
                this.initCreateMap()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("mapDeviceScreen", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
        },
        // 设置车辆点marker
        setAllMarkers(datas) {
            let items = datas || this.deviceItem.gps || []
            let markers = []
            for (let i in items) {
                let item = items[i]
                let marker = new AMap.Marker({
                    position: [item.lng, item.lat],
                    anchor: "bottom-center",
                    content: `<img width="14" height="22" src="/static/images/map/poi-marker-default.png" />`,
                    offset: new AMap.Pixel(0, 4),
                    extData: item
                })
                marker.on("click", (ev) => {
                    let target = ev.target.getExtData()
                    let pos = ev.target.getPosition()
                    dataVars.infoWindow.setContent(`<span style="padding: 0 10px 0 0;">车辆号：${target.carId}</span>`)
                    dataVars.infoWindow.open(dataVars.map, [pos.lng, pos.lat])
                })
                markers.push(marker)
            }
            if (items.length > 0) {
                dataVars.map.add(markers)
                dataVars.markers = markers
            }
        },
        setPointMarkers() {
            this.clearPointMarkers()
            let acIndex = Number(this.activeIndex)
            let points = this.pointGroups[acIndex] && this.pointGroups[acIndex].items || []
            if (points.length <= 0) {
                return false
            }
            let markers = []
            for (let i in points) {
                let item = points[i]
                let marker = new AMap.Marker({
                    position: [item.lng, item.lat],
                    offset: new AMap.Pixel(-20, -20),
                    zIndex: 20,
                    clickable: true,
                    content: this.getPointMarkerContent(item),
                    extData: item
                })
                marker.on("click", (ev) => {
                    let target = ev.target.getExtData()
                    let pos = ev.target.getPosition()
                    dataVars.infoWindow.setContent(`<span style="padding: 0 10px 0 0;">${target.name}</span>`)
                    dataVars.infoWindow.open(dataVars.map, [pos.lng, pos.lat])
                    this.moveToCenter({ lnglat: [target.lng, target.lat] })
                    this.selectGroupPoint(target)
                })
                markers.push(marker)
            }
            dataVars.map.add(markers)
            dataVars.pointMarkers = markers
            this.$forceUpdate()
        },
        // 设置指定点位的样式
        getPointMarkerContent(item) {
            let div = document.createElement('div')
            let Hue = 90
            let bgColor = 'hsla(' + Hue + ',100%,45%,0.7)'
            let fontColor = 'hsla(' + Hue + ',100%,90%,1)'
            let borderColor = 'hsla(' + Hue + ',100%,40%,1)'
            let shadowColor = 'hsla(' + Hue + ',100%,90%,1)'
            div.style.backgroundColor = bgColor
            let size = 40;
            div.style.width = div.style.height = size + 'px'
            div.style.border = 'solid 1px ' + borderColor
            div.style.borderRadius = size / 2 + 'px'
            div.style.boxShadow = '0 0 5px ' + shadowColor
            div.innerHTML = item.vehicleCount
            div.style.lineHeight = size + 'px'
            div.style.color = fontColor
            div.style.fontSize = '14px'
            div.style.textAlign = 'center'
            return div
        },
        // 设置覆盖范围
        setPointCircle() {
            this.clearCircleMarkers()
            let acIndex = Number(this.activeIndex)
            let points = this.pointGroups[acIndex] && this.pointGroups[acIndex].items || []
            if (points.length <= 0) {
                return false
            }
            let circles = []
            let fillColors = ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"]
            for (let i in points) {
                let item = points[i]
                let center = new AMap.LngLat(item.lng, item.lat);
                let circle = new AMap.Circle({
                    center: center, //圆心
                    radius: item.checkDistance, //半径
                    borderWeight: 1,
                    strokeColor: "#FF33FF", 
                    strokeWeight: 4,
                    strokeOpacity: 0.2,
                    fillOpacity: 0.2,
                    strokeStyle: 'dashed',
                    strokeDasharray: [10, 10], 
                    // 线样式还支持 'dashed'
                    fillColor: fillColors[Math.floor(Math.random() * 5)]
                })
                circles.push(circle)
            }
            dataVars.map.add(circles)
            dataVars.circleMarkers = circles
        },
        // 创建信息窗体
        createinfoWindow() {
            dataVars.infoWindow = new AMap.InfoWindow({
                content: "",
                offset: [0, -25],
                autoMove: true
            })
        },
        clearAllMarkers() {
            this.centerPos = null
            this.clearMarkers()
            this.clearPointMarkers()
            this.clearCircleMarkers()
        },
        clearMarkers() {
            dataVars.markers && dataVars.map.remove(dataVars.markers)
            dataVars.markers = null
        },
        clearPointMarkers() {
            dataVars.infoWindow && dataVars.infoWindow.close()
            dataVars.pointMarkers && dataVars.map.remove(dataVars.pointMarkers)
            dataVars.pointMarkers = null
        },
        clearCircleMarkers() {
            dataVars.circleMarkers && dataVars.map.remove(dataVars.circleMarkers)
            dataVars.circleMarkers = null
        },
        viewLocation(item) {
            this.alert(`
                覆盖范围：${item.checkDistance}米<br/>
                经度：${item.lng}<br/>
                纬度：${item.lat}`, `【${item.name}】经纬度`, {
                dangerouslyUseHTMLString: true
            })
        },
        rowClick(row, column, event) {
            this.moveToCenter({ lnglat: [row.lng, row.lat] })
            this.selectGroupPoint(row)
        },
        moveToCenter(item) {
            dataVars.map.setCenter(item.lnglat, false, 50)
        },
        selectGroupPoint(item) {
            let curGroups = this.pointGroups[this.activeIndex]
            curGroups.items = curGroups.items.map(x => {
                x.isSelected = false
                if (item.name === x.name) {
                    x.isSelected = true
                }
                return x
            })
        },
        openConfig() {
            this.$refs[this.refPointConfig].open()
        }
    }
}
</script>
<style lang="scss" scoped>
.page-vehicles-logs {
    
}
</style>

<template>
    <div class="page-vehicles-logs">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fr">
                    <el-select class="c-el-input mrgr5 mrgb5" placeholder="事件类型" size="medium" filterable
                        clearable v-model="listQuery.eventType" @change="_search()">
                        <el-option v-for="t in eventTypes" :key="t.id" :label="t.eventName" :value="t.eventType"></el-option>
                    </el-select>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="车辆号查询" clearable v-model="listQuery.vehicleId"
                        size="medium"></el-input>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="_search()"
                        align="right">
                    </el-date-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="姓名/电话/站点名" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-button type="warning" size="medium" @click="showAllMap()"
                        v-if="(listQuery.eventType || listQuery.vehicleId || listQuery.keywords) && listQuery.beginTime && listQuery.endTime">
                        <i class="el-icon-location-information"></i> 查询定位
                    </el-button>
                    <el-button type="danger" size="medium" @click="_spiderLogs()"><i class="fa fa-refresh"></i> 更新车辆日志</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="small">
                <el-table-column label="车辆号" prop="carId"></el-table-column>
                <el-table-column label="时间" prop="time" :formatter="tableDateFormat"></el-table-column>
                <el-table-column label="手机号" prop="phone"></el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="事件" prop="eventName"></el-table-column>
                <el-table-column label="站点ID" prop="parkId"></el-table-column>
                <el-table-column label="站点名称" prop="parkName">
                    <template slot-scope="scope">
                        <span v-if="scope.row.parkName">
                            <el-tag type="danger" size="mini" v-if="scope.row.tag">{{ scope.row.tag }}</el-tag>
                            {{ scope.row.parkName }}
                            <i class="el-icon-location-information col_warning" @click="showMap(scope.row)"></i>
                        </span>
                    </template>
                </el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>

        <!--验证站点在地图的位置-->
        <location-valid :ref="refLocationValid"></location-valid>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import * as funReport from "@/api/report"
import * as funSpider from "@/api/spider"
import moment from "moment"
import LocationValid from "@/views/admin/system/locationValid"
export default {
    name: "pageVehiclesLogs",
    mixins: [configMixins],
    components: { LocationValid },
    data() {
        let that = this
        return {
            refLocationValid: "refVehiclesLogsToLocationValid",
            tableData: [],
            total: null,
            listQuery: {
                area: "",
                eventType: "",
                vehicleId: "",
                beginTime: "",
                endTime: "",
                keywords: "",
                page: 1,
                size: window.$common.appPageSize,
                sorting: "time desc"
            },
            isPagination: false,
            selectedTime: [],
            eventTypes: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    activated() {
        /* let params = this.$route.params
        if (params && params.logsItem) {
            this.listQuery.vehicleId = params.logsItem.vehicleId
            this.listQuery.eventType = "用户端-扫码"
            this.selectedTime = [moment(params.logsItem.date).format("YYYY-MM-DD 00:00:00"), moment(params.logsItem.date).format("YYYY-MM-DD 23:59:59")]
            // this.logsItem = params.logsItem
            this._search()
        } else {
            this._search()
        } */
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
        this.getEventTypes()
    },
    methods: {
        open(params) {
            this.listQuery.keywords = ""
            if (params && params.logsItem) {
                this.listQuery.eventType = params.logsItem.eventType || ""
                this.listQuery.vehicleId = params.logsItem.vehicleId || ""
                this.listQuery.keywords = params.logsItem.keywords || ""
                if (params.logsItem.startDate && params.logsItem.endDate) {
                    this.selectedTime = [params.logsItem.startDate, params.logsItem.endDate]
                } else if (params.logsItem.date) {
                    this.selectedTime = [moment(params.logsItem.date).format("YYYY-MM-DD 00:00:00"), moment(params.logsItem.date).format("YYYY-MM-DD 23:59:59")]
                }
                this._search()
            }
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginTime = timeArr[0] || ""
            this.listQuery.endTime = timeArr[1] || ""
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [this.listQuery.beginTime, this.listQuery.endTime]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            this.listQuery.vehicleId = this.listQuery.vehicleId.trim()
            this.listQuery.keywords = this.listQuery.keywords.trim()
            funReport.GetVehicleLogs(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map((x, i) => {
                        x.date = moment(x.time).format("YYYY-MM-DD")
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        async getEventTypes() {
            await funReport.GetVehicleLogEvents().then(res => {
                this.eventTypes = res
            })
            this.getDataList()
        },
        _spiderLogs() {
            let that = this
            let curType = ""
            if (this.listQuery.eventType) {
                for (let i in this.vehicleLogsSpiderTypes) {
                    let eType = this.vehicleLogsSpiderTypes[i][this.listQuery.eventType]
                    if (eType) {
                        curType = eType
                        break
                    }
                }
            }
            let params = {
                type: curType || "",
                beginDate: this.listQuery.beginTime,
                endDate: this.listQuery.endTime,
                carId: this.listQuery.vehicleId,
                pageSize: 50
            }
            if (!params.carId) {
                if (!this.listQuery.eventType) {
                    this.alert("因数据量较大，请选择事件类型或者输入车辆号才能执行！")
                    return false
                } else {
                    if (!curType) {
                        this.alert("暂不支持当前事件类型的日志更新！")
                        return false
                    }
                }
            }
            if (!params.beginDate || !params.endDate) {
                this.alert("请选择时间段！")
                return false
            }
            this.confirm("执行更新后，请稍等2分钟左右，重新查询数据！").then(() => {
                funSpider.execVehicleLogSpider(params).then(() => {
                    that.successMsg("日志数据正在更新，请稍后查询！", {
                        onClose: () => {
                            that._search()
                        }
                    })
                })
            })
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        },
        showMap(row) {
            if (row.lng && row.lat) {
                let lnglats = [{
                    parkTag: row.tag || "",
                    parkName: row.parkName || "",
                    parkId: row.parkId,
                    lnglat: [row.lng, row.lat],
                    expandParkings: row.parkCheckPoints || []
                }]
                this.$refs[this.refLocationValid].open({
                    isExpand: true,
                    lnglats
                })
            }
        },
        showAllMap() {
            let params = {
                eventType: this.listQuery.eventType,
                vehicleId: this.listQuery.vehicleId,
                beginTime: this.listQuery.beginTime,
                endTime: this.listQuery.endTime,
                keywords: this.listQuery.keywords,
                page: 1,
                size: 5000,
                sorting: "time desc"
            }
            if (!params.eventType && !params.vehicleId && !params.keywords) {
                this.warningMsg("由于数据量大，【事件类型、车辆号、姓名、电话、站点】请至少选择一个，否则无法查询！")
                return false
            }
            if (!params.beginTime || !params.endTime) {
                this.warningMsg("请选择时间段查询！")
                return false
            }
            this.confirm("目前最多只显示最近的5000条数据，是否继续？").then(() => {
                window.$common.fullLoading()
                funReport.GetVehicleLogs(params).then(response => {
                    window.$common.closeFullLoading()
                    if (response && response.items && response.items.length > 0) {
                        let lnglats = []
                        for (let i = 0; i < response.items.length; i++) {
                            let item = response.items[i]
                            if (item.lng && item.lat) {
                                lnglats.push({
                                    parkTag: item.tag || "",
                                    parkName: item.parkName || "",
                                    parkId: item.parkId,
                                    lnglat: [item.lng, item.lat],
                                    expandParkings: item.parkCheckPoints || []
                                })
                            }
                        }
                        this.$refs[this.refLocationValid].open({
                            isExpand: true,
                            lnglats
                        })
                    } else {
                        this.warningMsg("暂无车辆日志数据！")
                    }
                })
            })
        }
    }
}
</script>
<style lang="scss">
  .ueditor_box {
    line-height: initial !important;
  }
</style>

<template>
  <div class="ueditor_box">
    <ueditor-wrap @ready="ready" :destroy="true" v-model="curContent" :config="ueditorConfig"></ueditor-wrap>
  </div>
</template>

<script>
import UeditorWrap from 'vue-ueditor-wrap'
export default {
  name: "ueditor",
  props: {
    content: {
      default: "",
      type: String
    },
    configs: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  components: {
    UeditorWrap
  },
  data() {
    return {
      curContent: "",
      ueditorConfig: {
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        // serverUrl: this.$config.baseUrl + 'ueditor/ueditorConfig',
        // serverUrl: 'http://localhost:8090/ueditor/ueditorConfig',
        serverUrl: "",
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/static/UEditor/',
        // 编辑器自动被内容撑高
        autoHeightEnabled: true,
        // 工具栏是否可以浮动
        autoFloatEnabled: false,
        // 初始容器高度
        initialFrameHeight: 250,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: true,
        // 层级基数
        zIndex: 999999999
      }
    }
  },
  watch: {
    content(val) {
      this.curContent = val
    },
    curContent(v) {
      this.$emit("changeContent", v)
    }
  },
  mounted() {
    // 数据初始化
    this.serverUrl = this.uploadBaseUrl + "/api/UEditor/Do"
    this.ueditorConfig = {
      ...this.ueditorConfig,
      ...this.configs
    }
  },
  methods: {
    ready() {
      this.curContent = this.content
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .comp-edit-work-dialog {
    max-width: 400px;
}
</style>

<template>
    <div class="comp-edit-work">
        <el-dialog title="工作时间段配置" custom-class="c-el-dialog comp-edit-work-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="80px" size="small">
                <el-col :xs="24">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="dialogData.name" placeholder="请输入姓名" :disabled="dialogType === createOrUpdate[1]"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="联系电话" prop="tel">
                        <el-input v-model="dialogData.tel" placeholder="请输入联系电话" :disabled="dialogType === createOrUpdate[1]"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="开始时间" prop="startTime">
                        <el-time-picker placeholder="请选择开始时间" v-model="dialogData.startTime" value-format="HH:mm:00"
                            :picker-options="{ selectableRange: '00:00:00 - 23:59:59', format: 'HH:mm:00' }">
                        </el-time-picker>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="结束时间" prop="endTime">
                        <el-time-picker placeholder="请选择结束时间" v-model="dialogData.endTime" value-format="HH:mm:00"
                            :picker-options="{ selectableRange: '00:00:00 - 23:59:59', format: 'HH:mm:00' }">
                        </el-time-picker>
                    </el-form-item>
                </el-col>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import configMixins from "@/mixins/config"
import moment from "moment"
import * as funMaintenance from '@/api/maintenance'
export default {
    name: "compEditWork",
    mixins: [configMixins],
    data() {
        return {
            showDialog: false,
            defaultDialogData: {
                name: "",
                tel: "",
                startTime: "",
                endTime: ""
            },
            dialogData: {},
            dialogType: "", 
            formRefName: "refEditWork",
            formRules: {
                name: [{ required: true, message: '请输入姓名', trigger: ['blur']}],
                tel: [{ required: true, message: '请输入联系方式', trigger: ['blur']}],
                startTime: [{ required: true, message: '请输入开始时间', trigger: ['blur', 'change']}],
                endTime: [{ required: true, message: '请输入结束时间', trigger: ['blur', 'change']}]
            }
        }
    },
    methods: {
        open(row) {
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            if (row && row.tel) {
                this.dialogType = this.createOrUpdate[1]
                this.dialogData = JSON.parse(JSON.stringify(row))
                // 编辑
            } else {
                this.dialogType = this.createOrUpdate[0]
            }
            this.showDialog = true
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let startTime = this.dialogData.startTime.replace(/:/g, "")
                    let endTime = this.dialogData.endTime.replace(/:/g, "")
                    if (Number(startTime) >= Number(endTime)) {
                        this.warningMsg("结束时间必须大于开始时间!")
                        return false
                    }
                    this.refreshData()
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
            })
        },
        refreshData() {
            this.$emit("refreshData", this.dialogData)
        }
    }
}
</script>
import { userLogin, connectToken, getInfo } from "@/api/login"
import { getAdminToken, setAdminToken } from "@/utils/storages"

const user = {
  state: {
    token: getAdminToken(),
    name: "",
    user: {},
    roles: []
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setName(state, name) {
      state.name = name
    },
    setUser(state, user) {
      state.user = user
    },
    setRoles(state, roles) {
      state.roles = roles
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token)
    },
    login({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        connectToken(loginData).then(res => {
          commit('setToken', res.access_token)
          setAdminToken(res.access_token)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loginOut({ commit }) {
      return new Promise((resolve) => {
        commit('setRoles', [])
        commit('setToken', null)
        setAdminToken(null)
        resolve()
      })
    },
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo(state.token).then(res => {
          if (res.permissions && res.permissions.length > 0) { // 验证返回的roles是否是一个非空数组
            commit('setRoles', res.permissions)
          } else {
            reject('权限不能为空数组!')
          }
          commit('setName', res.user.name)
          commit('setUser', res.user)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    clearPermissions({ commit }) {
      // 在登录界面清空权限数据
      commit('setRoles', [])
    }
  }
}
export default user

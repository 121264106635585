import { EnumInit } from "@/api/all"
import { getServiceArea, setServiceArea } from "@/utils/storages"
import { getCurrentUserAreas } from "@/api/system/user"

const webSite = {
  state: {
    enumConfigs: {},
    serviceArea: getServiceArea(),
    serviceAreas: []
  },
  mutations: {
    setEnumConfigs(state, enums) {
      state.enumConfigs = enums
    },
    setServiceArea(state, area) {
      state.serviceArea = area
    },
    setServiceAreas(state, areas) {
      state.serviceAreas = areas
    }
  },
  actions: {
    setEnumConfigs({ commit }) {
      return new Promise((resolve, reject) => {
        EnumInit().then(res => {
          commit('setEnumConfigs', res)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    setServiceArea({ commit }, val) {
      commit("setServiceArea", val)
      setServiceArea(val)
    },
    setServiceAreas({ commit }) {
      return new Promise((resolve, reject) => {
        getCurrentUserAreas().then(res => {
          commit('setServiceAreas', res)
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  }
}
export default webSite


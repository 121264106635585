<style lang="scss" scoped>
/deep/ .comp-Vehicles-dialogLogs {
    margin-top: 2vh !important;
    max-width: 98%;
    width: 98%;
    .el-dialog__body {
        padding: 2px 20px;
        height: 90vh;
        overflow-y: auto;
    }
}
</style>

<template>
    <div>
        <el-dialog title="车辆日志" custom-class="c-el-dialog comp-Vehicles-dialogLogs" :visible.sync="showDialog" 
            :before-close="_close">
            <logs :ref="refVehiclesLogs"></logs>
        </el-dialog>
    </div>
</template>

<script>
import logs from "../logs"
export default {
    name: "compVehiclesDialogLogs",
    components: { logs },
    data() {
        return {
            refVehiclesLogs: "refDialogLogsToVehiclesLogs",
            showDialog: false,
            dialogData: {
                id: "",
                isAllAreas: false,
                areas: []
            }
        }
    },
    methods: {
        open(row) {
            this.showDialog = true
            this.$nextTick(() => {
                this.$refs[this.refVehiclesLogs].open(row)
            })
        },
        _close() {
            this.showDialog = false
        }
    }
}
</script>
<style scoped lang="scss">

</style>

<template>
  <div class="page-travel-bill">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fl">
                <el-button type="primary" icon="el-icon-plus" size="medium" @click="_edit()">创建行程单</el-button>
            </div>
            <div class="fr">
                <el-input class="c-el-input mrgr5 mrgb5" placeholder="姓名/手机号" clearable v-model="listQuery.keywords"
                    size="medium"></el-input>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium">
        <el-table-column label="用户姓名" prop="name" width="100"></el-table-column>
        <el-table-column label="联系电话" prop="phone" width="150"></el-table-column>
        <el-table-column label="合计" min-width="160">
            <template slot-scope="scope">
                <span class="col_primary" v-if="scope.row.status === valTravelBillStatusCompleted()">共{{ scope.row.totalCount }}笔行程，合计{{ scope.row.totalAmountText }}元</span>
                <span class="col_danger" v-else>数据生成中...</span>
            </template>
        </el-table-column>
        <el-table-column label="行程开始时间" prop="beginDate" :formatter="tableDateFormat"></el-table-column>
        <el-table-column label="行程结束时间" prop="endDate" :formatter="tableDateFormat"></el-table-column>
        <el-table-column label="申请时间" prop="creationTime" :formatter="tableDateFormat"></el-table-column>
        <el-table-column label="任务完成时长" prop="taskTime" width="120"></el-table-column>
        <el-table-column label="状态" prop="status" width="100">
            <template slot-scope="scope">
                {{ tranceTravelBill(scope.row.status) }}
            </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark"></el-table-column>
        <el-table-column label="操作" align="center" width="100" fixed="right">
            <template slot-scope="scope">
                <el-dropdown trigger="click" v-if="scope.row.status === valTravelBillStatusCompleted() || scope.row.status !== valTravelBillStatusCreated()">
                    <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="navTo(scope.row)" v-if="scope.row.status === valTravelBillStatusCompleted()">查看</el-dropdown-item>
                        <el-dropdown-item @click.native="_reset(scope.row)" v-if="scope.row.status !== valTravelBillStatusCreated()">重置任务</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
        </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>

    <edit :ref="refEdit" @refreshData="_search()"></edit>
  </div>
</template>

<script>
  import permission from "@/mixins/permission"
  import enumConfigs from "@/mixins/enumConfigs"
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  import edit from "./components/travelEdit"
  export default {
    name: "pageTravelBill",
    mixins: [permission, enumConfigs],
    components: { edit },
    data() {
      let that = this
      return {
        refEdit: "refTravelBillToTravelEdit",
        tableData: [],
        total: null,
        listQuery: {
            status: null,
            keywords: "",
            page: 1,
            size: window.$common.appPageSize
        },
        isPagination: false
      }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        getDataList () {
            window.$common.fullLoading()
            funMaintenance.getTravel(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map(x => {
                        x.totalAmountText = `${x.totalAmount / 100}`
                        // 已完成才有任务开始结束时间
                        if (x.jobInfo && x.jobInfo.completeTime && x.jobInfo.startTime) {
                            if (moment(x.jobInfo.completeTime).isValid() && moment(x.jobInfo.startTime).isValid()) {
                                let duration = moment(x.jobInfo.completeTime).diff(moment(x.jobInfo.startTime))
                                let hour = moment.duration(duration).hours()
                                let minute = moment.duration(duration).minutes()
                                let second = moment.duration(duration).seconds()
                                hour = hour < 10 ? `0${hour}` : hour
                                minute = minute < 10 ? `0${minute}` : minute
                                second = second < 10 ? `0${second}` : second
                                x.taskTime = `${hour}:${minute}:${second}`
                            }
                        }
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        _edit() {
            this.$refs[this.refEdit].open()
        },
        _reset(row) {
            this.confirm("确定要重置当前任务吗?").then(() => {
                funMaintenance.resetTravel({ id: row.id }).then(res => {
                    this.successMsg("重置命令已发送，请稍后查询!", {
                        onClose: () => {
                            this.getDataList()
                        }
                    })
                })
            })
        },
        navTo(row) {
            this.$router.push({ name: "pageTravelBillDetail", params: { billItem: row } })
        },
        tranceTravelBill(status) {
            let txt = ""
            let item = this.arrTravelBillStatus && this.arrTravelBillStatus.find(x => x.value === status)
            if (item) {
                txt = item.desc
            }
            return txt
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        }
    }
  }
</script>
import { render, staticRenderFns } from "./editWork.vue?vue&type=template&id=fea15344&scoped=true&"
import script from "./editWork.vue?vue&type=script&lang=js&"
export * from "./editWork.vue?vue&type=script&lang=js&"
import style0 from "./editWork.vue?vue&type=style&index=0&id=fea15344&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fea15344",
  null
  
)

export default component.exports
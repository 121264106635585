const decodeData = {
    customAlphabet: "ACBFEDTPIJXLMNOHQRSGUZWKYVodcbesghqjwumyapirftlvkxnz0721354986+/",
    standardAlphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
    getCodeMap() {
        for (var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "encode", t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "", n = new Map, a = new Map, i = t || this.customAlphabet, r = 0; r < i.length; r++)
            n.set(this.standardAlphabet[r], i[r]),
            a.set(i[r], this.standardAlphabet[r]);
        return "encode" === e ? n : a
    },
    utf8ToBinary(e) {
        var t, n = (new TextEncoder).encode(e), i = "", r = Object(a["a"])(n);
        try {
            for (r.s(); !(t = r.n()).done; ) {
                var o = t.value;
                i += String.fromCharCode(o)
            }
        } catch (s) {
            r.e(s)
        } finally {
            r.f()
        }
        return i
    },
    binaryToUtf8(e) {
        for (var t = new Uint8Array(e.length), n = 0; n < e.length; n++)
            t[n] = e.charCodeAt(n);
        return (new TextDecoder).decode(t)
    },
    encodeBase64(e) {
        var t = window.btoa(e)
            , n = this.getCodeMap("encode")
            , a = Array.from(t).map((function(e) {
            return n.get(e)
        }
        )).join("");
        return a
    },
    decodeBase64(e) {
        var t = this.getCodeMap("decode")
            , n = Array.from(e).map((function(e) {
            return t.get(e)
        }
        )).join("");
        return window.atob(n)
    },
    encodeUtf8ToBase64(e) {
        var t = this.utf8ToBinary(e);
        return this.encodeBase64(t)
    },
    decodeBase64ToUtf8(e) {
        var t = this.decodeBase64(e)
            , n = this.binaryToUtf8(t);
        try {
            return JSON.parse(n)
        } catch (a) {
            return n
        }
    }
}
export default decodeData;
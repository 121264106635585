import * as util from '@/utils/tools'

/* 后台用户token */
const AdminkenKey = 'Admin-Token'

export function getAdminToken() {
  return util.getLocalStorage(AdminkenKey) || null
}

export function setAdminToken(token) {
  return util.setLocalStorage(AdminkenKey, token) || null
}

export function deleteAdminToken() {
  return util.deleteLocalStorage(AdminkenKey) || null
}

/* 保存当前激活的tab */
const ActiveTab = 'Active-Tab'

export function getActiveTabStorage() {
  return util.getLocalStorage(ActiveTab) || null
}

export function setActiveTabStorage(activeTab) {
  return util.setLocalStorage(ActiveTab, activeTab) || null
}

export function deleteActiveTabStorage() {
  return util.deleteLocalStorage(ActiveTab) || null
}

/* 系统配置 */
const SystemConfig = 'SystemConfig'

export function getSystemConfigStorage() {
  return util.getLocalStorage(SystemConfig) || null
}

export function setSystemConfigStorage(val) {
  return util.setLocalStorage(SystemConfig, val) || null
}

/* 保存当前皮肤 */
const ColorCode = 'ColorCode'

export function getSkinColorCode() {
  return util.getLocalStorage(ColorCode) || null
}

export function setSkinColorCode(val) {
  return util.setLocalStorage(ColorCode, val) || null
}

/* 保存服务区 */
const ServiceArea = 'ServiceArea'

export function getServiceArea() {
  return util.getLocalStorage(ServiceArea) || null
}

export function setServiceArea(val) {
  return util.setLocalStorage(ServiceArea, val) || null
}

<style scoped lang="scss">
  .notice_list_box {
    .addbtn {
      margin-bottom: 10px;
    }
    /deep/ .c-el-dialog {
      max-width: 820px;
    }
  }
</style>

<template>
  <div class="notice_list_box">
    <div class="addbtn">
      <el-button type="primary" plain size="small" @click="_edit()"><i class="fa fa-plus"></i> 新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="mini">
      <el-table-column type="expand" fixed>
        <template slot-scope="props">
          <el-form label-position="left" inline class="c-table-expand">
            <el-form-item label="ID">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="封面图地址">
              <span>{{ props.row.coverImage }}</span>
            </el-form-item>
            <el-form-item label="标题">
              <span>{{ props.row.title }}</span>
            </el-form-item>
            <el-form-item label="是否置顶">
              <span>{{ props.row.isTop ? "是" : "否" }}</span>
            </el-form-item>
            <el-form-item label="简介">
              <span>{{ props.row.intro }}</span>
            </el-form-item>
            <el-form-item label="操作者">
              <span>{{ props.row.bookmaker }}</span>
            </el-form-item>
            <el-form-item label="创建时间">
              <span>{{ props.row.creationTime }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="id" width="70"></el-table-column>
      <el-table-column label="封面图" v-if="$root.isPc" min-width="120">
        <template slot-scope="scope">
          <img :src="scope.row.coverImage" width="100%" />
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title"></el-table-column>
      <el-table-column label="是否置顶" width="70" v-if="$root.isPc">
        <template slot-scope="scope">
          {{ scope.row.isTop ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="简介" prop="intro" v-if="$root.isPc"></el-table-column>
      <el-table-column label="创建时间" prop="creationTime" v-if="$root.isPc" width="85"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="_edit(scope.row)">编辑</el-dropdown-item>
              <el-dropdown-item @click.native="_delete(scope.row)">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
      :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
      layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <el-dialog title="文章编辑" custom-class="c-el-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
      <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
        <el-form-item label="标题" prop="title">
          <el-input placeholder="请输入标题" v-model="dialogData.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="intro">
          <el-input type="textarea" rows="5" placeholder="请输入简介" v-model="dialogData.intro"></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <ueditor :content="dialogData.content"></ueditor>
        </el-form-item>
        <el-form-item label="是否置顶" prop="isTop">
          <el-radio-group v-model="dialogData.isTop">
            <el-radio :label="false" border size="small" style="margin-right: 5px;">否</el-radio>
            <el-radio :label="true" border size="small">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="操作者" v-if="dialogType === createOrUpdate[1]">
          <el-tag size="small">{{ dialogData.bookmaker }}</el-tag>
        </el-form-item>
        <el-form-item label="操作时间" v-if="dialogType === createOrUpdate[1]">
          <el-tag size="small">{{ dialogData.creationTime }}</el-tag>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="_close()">取消</el-button>
        <el-button size="small" type="primary" plain @click="_save()">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import configMixins from "@/mixins/config"
import ueditor from '@/components/UEditor/Index'
import { getAll, getDetail, create, update, remove } from '@/api/notice/index'
export default {
  name: "noticeList",
  mixins: [configMixins],
  components: {
    ueditor
  },
  data() {
    return {
      tableData: [],
        total: null,
        listQuery: {
          keywords: null,
          page: 1,
          size: window.$common.appPageSize
        },
        isPagination: false,
        showDialog: false,
        dialogType: "",
        dialogData: {},
        formRefName: 'formRefName',
        formRules: {
          title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          isTop: [{ required: true, message: '请选择是否置顶首页', trigger: 'blur' }],
          intro: [{ required: true, message: '请输入简介', trigger: 'blur' }],
          content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
        }
    }
  },
  mounted() {
    this.getListData()
  },
  methods: {
    handleSizeChange(val) {
      window.$common.fullLoading()
      this.listQuery.size = val
      this.getListData()
    },
    handleCurrentChange(val) {
      window.$common.fullLoading()
      this.listQuery.page = val
      this.getListData()
    },
    getListData() {
      window.$common.fullLoading()
      getAll(this.listQuery).then(response => {
        window.$common.closeFullLoading()
        this.total = response.totalCount
        this.isPagination = response.totalCount > 0 || false
        if (response && response.items && response.items.length > 0) {
          this.tableData = response.items
        } else {
          this.tableData = []
        }
      })
    },
    _edit(row) {
      this.showDialog = true
      if (row) {
        this.dialogType = this.createOrUpdate[1]
        getDetail({id: row.id}).then(res => {
          this.dialogData = { ...res }
        })
      } else {
        this.dialogType = this.createOrUpdate[0]
        this.dialogData = {
          id: 0,
          title: "",
          coverImage: "",
          intro: "",
          isTop: false,
          content: ""
        }
      }
    },
    _save() {
      this.$refs[this.formRefName].validate(valid => {
        if (valid) {
          window.$common.fullLoading()
          let commitTemp = {
            id: this.dialogData.id,
            title: this.dialogData.title,
            coverImage: this.dialogData.coverImage,
            intro: this.dialogData.intro,
            isTop: this.dialogData.isTop,
            content: this.dialogData.content
          }
          if (this.dialogType === this.createOrUpdate[0]) {
            create(commitTemp).then(() => {
              this.commonSuccess()
            })
          } else if (this.dialogType === this.createOrUpdate[1]) {
            update(commitTemp).then(() => {
              this.commonSuccess()
            })
          }
          
        } else {
          return false
        }
      })
    },
    _delete(row) {
      if (row) {
        this.confirm("确定要删除ID为“" + row.id + "”的文章吗？").then(() => {
          window.$common.fullLoading()
          remove({ id: row.id }).then(res => {
            this.commonSuccess()
          })
        })
      }
    },
    _close() {
      this.showDialog = false
      this.$nextTick(() => {
        if (this.$refs[this.formRefName]) {
          this.$refs[this.formRefName].resetFields()
        }
      })
    },
    commonSuccess(val) {
      let tips = val || "操作成功!"
      this.alert(tips).then(() => {
        this._close()
        window.$common.closeFullLoading()
        this.getListData()
      })
    }
  }
}
</script>
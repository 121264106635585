<style scoped lang="scss">
  .system_config {
    .el-form {
      width: 60%;
      min-width: 250px;
    }
  }
</style>

<template>
  <div class="system_config">
    <el-form :model="configData" :rules="formRules" :ref="formRefName">
      <el-card>
        <el-form-item label="项目名称" prop="systemName" style="margin-bottom: 0;">
          <el-input style="width: 60%;" placeholder="请输入标题" v-model="configData.systemName"></el-input>
        </el-form-item>
        <el-form-item label="页码" prop="pageSize" v-if="false">
          <el-input placeholder="请输入页码" v-model="configData.pageSize"></el-input>
        </el-form-item>
      </el-card>
      <el-card class="mrgt20">
        <el-form-item label="挪车工作时间段" style="margin-bottom: 0;"></el-form-item>
        <el-form-item label="">
          <el-time-picker is-range v-model="selectedTime" size="medium" :clearable="false"
              range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="有效挪车时间" style="margin-bottom: 0;"></el-form-item>
        <el-form-item label="">
          <el-input style="width: 350px;" type="number" :min="0" size="medium" v-model="configData.moveActiveNoOrderHours" placeholder="请输入间隔时间">
            <template slot="append">小时</template>
          </el-input>
        </el-form-item>
        <el-form-item label="仓库地址" style="margin-bottom: 0;"></el-form-item>
        <el-form-item label="">
          <div class="mrgb5" v-for="(item, index) in addresses" :key="'add-' + index">
            <el-input style="width: 60%;" size="medium" v-model="addresses[index]" placeholder="请输入仓库地址"></el-input>
            <el-button class="mrgl10" type="danger" size="small" plain @click="_remove(index)">移除</el-button>
          </div>
          <el-button type="primary" size="small" plain @click="_add()">新增仓库地址</el-button>
        </el-form-item>
      </el-card>
      <el-form-item class="mrgt20" label="">
        <el-button type="danger" @click="updateConfig()">保存全部配置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import * as commons from '@/api/common'
  import moment from "moment"
  export default {
    name: "sysConfig",
    data() {
      return {
        configData: {
          
        },
        formRefName: "formRefName",
        formRules: {
          systemName: [{ required: true, message: '请输入标题', trigger: 'blur' }],
          pageSize: [{ required: true, message: '请输入页码', trigger: 'blur' }]
        },
        selectedTime: [],
        addresses: []
      }
    },
    mounted() {
      this.getConfigData()
    },
    methods: {
      getConfigData() {
        window.$common.fullLoading()
        commons.getSystemSettings().then(res => {
          window.$common.closeFullLoading()
          this.configData = { ...res }
          let timeArr = []
          if (res.moveActiveBeginTime) {
            timeArr[0] = "1970-01-01 " + res.moveActiveBeginTime || "1970-01-01 00:00:00"
          }
          if (res.moveActiveEndTime) {
            timeArr[1] = "1970-01-01 " + res.moveActiveEndTime || "1970-01-01 23:59:59"
          }
          this.selectedTime = timeArr
          if (res.warehouseAddresses) {
            this.addresses = res.warehouseAddresses.split(",")
          }
        })
      },
      updateConfig() {
        this.$refs[this.formRefName].validate(valid => {
          if (valid) {
            let commitData = JSON.parse(JSON.stringify(this.configData))
            commitData.moveActiveNoOrderHours = commitData.moveActiveNoOrderHours || 0
            commitData.moveActiveBeginTime = moment(this.selectedTime[0]).format("HH:mm:ss")
            commitData.moveActiveEndTime = moment(this.selectedTime[1]).format("HH:mm:ss")
            let isAddNoEmpty = true
            for (let i in this.addresses) {
              if (!this.addresses[i]) {
                isAddNoEmpty = false
                break
              }
            }
            if (!isAddNoEmpty) {
              this.errorMsg("请先删除空的仓库地址!")
              return false
            }
            commitData.warehouseAddresses = this.addresses.join()
            window.$common.fullLoading()
            commons.updateSystemSettings(commitData).then(() => {
              this.alert("操作成功!").then(() => {
                this.showDialog = false
                window.$common.closeFullLoading()
                // 修改后重新保存storege
                this.setSystemConfig()
                // 刷新
                //location.reload()
              })
            })
          }
        })
      },
      _add() {
        this.addresses.push("")
      },
      _remove(index) {
        if (this.addresses[index]) {
          this.confirm("确定要移除当前地址吗？").then(() => {
            this.addresses.splice(index, 1)
          })
        } else {
          this.addresses.splice(index, 1)
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
/deep/ .comp-maintenance-audit-dialog {
    max-width: 400px;
    .is-effective {
        padding: 8px 15px 10px;
        border: 1px solid #DCDFE6;
        border-radius: 4px;
        background-color: #fdfdfd;
    }
}
</style>

<template>
    <div class="comp-maintenance-audit">
        <el-dialog :title="title" custom-class="c-el-dialog comp-maintenance-audit-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="80px" size="small">
                <el-col :xs="24">
                    <el-form-item label="是否有效">
                        <span class="is-effective">
                            <el-switch v-model="dialogData.isEffective" inactive-text="无效" active-text="有效"></el-switch>
                        </span>
                    </el-form-item>
                </el-col>
                <el-col :xs="24">
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" :rows="5" v-model="dialogData.remark" placeholder="请设置备注"></el-input>
                    </el-form-item>
                </el-col>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funMaintenance from '@/api/maintenance'
export default {
    name: "compMaintenanceAudit",
    data() {
        return {
            title: "",
            showDialog: false,
            dialogData: {
                ids: [],
                isEffective: false,
                remark: ""
            },
            formRefName: "refMaintenanceAudit",
            formRules: {
                remark: [{ required: true, message: '请设置备注', trigger: ['blur']}]
            }
        }
    },
    methods: {
        open(ids, item) {
            this.title = item ? `${item.operator}车辆号【${item.car_id}】审核`: "批量审核"
            this.dialogData.ids = ids || []
            this.showDialog = true
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    funMaintenance.AuditMoveTask(this.dialogData).then(res => {
                        this.commonSuccess("操作成功!")
                    })
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData()
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .comp-user-spider {
    max-width: 520px;
    .c-datetime-range {
        max-width: 100% !important;
    }
}
</style>

<template>
    <div>
        <el-dialog :title="title" custom-class="c-el-dialog comp-user-spider" :visible.sync="showDialog"
            :before-close="_close" :close-on-click-modal="false">
            <el-form :model="dialogData" label-width="80px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="服务区">
                            <el-select class="c-pw100" placeholder="请选择服务区(可多选)" size="medium" multiple
                                clearable v-model="dialogData.areas">
                                <el-option v-for="ws in allAreas" :key="ws.id" :label="ws.name" :value="ws.name"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="时间段" v-if="!isParking()">
                            <el-date-picker class="c-datetime-range" v-model="selectedTime" type="datetimerange" size="medium"
                                :default-time="['00:00:00', '23:59:59']"
                                range-separator="至"
                                start-placeholder="开始时间"
                                end-placeholder="结束时间"
                                align="right">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button size="small" @click="_close()">关闭</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funAll from "@/api/all"
import * as funSpider from "@/api/spider"
import * as funMaintenance from '@/api/maintenance'
import moment from "moment"
export default {
    name: "compUserAreas",
    data() {
        return {
            title: "",
            spiderName: "",
            showDialog: false,
            dialogData: {
                areas: [],
                beginDate: "",
                endDate: ""
            },
            allAreas: [],
            selectedTime: [],
            spiderParams: null
        }
    },
    methods: {
        async open(row) {
            this.showDialog = false
            // 没有爬虫名称，不显示
            if (!row || !row.spiderName) {
                this.warningMsg("请选择需要执行的爬虫!")
                return false
            }
            this.spiderParams = row && row.params || null
            this.title = `执行${row.spiderName}`
            this.spiderName = row.spiderName
            // 重新执行，带参数的直接去执行爬虫
            if (this.spiderParams) {
                this.confirm(`确认执行${this.spiderName}吗？`).then(() => {
                    this._save()
                })
                return false
            }
            // 车辆日志，提示去车辆分析--车辆日志里面执行
            if (this.isVehicleLog()) {
                this.warningMsg("请在车辆分析--车辆日志里面执行爬虫!")
                return false
            }
            // 车辆实时定位，提示只能管理员执行
            if (this.isCarPosition()) {
                this.warningMsg("请联系管理员执行车辆实时定位爬虫!")
                return false
            }
            // 服务区，直接执行，不需要选择服务区和时间
            if (this.isServiceArea()) {
                this.confirm("确认执行服务区爬虫吗？").then(() => {
                    funSpider.execServiceAreaSpider().then(() => {
                        this.commonSuccess()
                    })
                })
                return false
            }
            // 行程单，提示只能在运维管理--行程单里面执行
            if (this.isBill()) {
                this.warningMsg("请在运维管理--行程单里面执行爬虫!")
                return false
            }
            await this.getAllAreas()
            console.log(row)
            let index = this.allAreas.findIndex(x => {
                return x.name == row.areaName
            })
            if (index !== -1) {
                this.dialogData.areas = [row.areaName || ""]
            } else {
                this.dialogData.areas = [this.$store.getters.serviceArea || ""]
            }
            this.showDialog = true
        },
        async getAllAreas(){
            window.$common.fullLoading()
            await funAll.AllServiceAreas({ isActive: true }).then(res => {
                window.$common.closeFullLoading()
                this.allAreas = res
            })
        },
        async _save() {
            let submitData = null
            if (this.spiderParams) {
                submitData = JSON.parse(JSON.stringify(this.spiderParams))
            } else {
                submitData = this.tranceCommitData()
            }
            if (!submitData) {
                return false
            }
            console.log(submitData)
            await this._commonSave(submitData)
        },
        async _commonSave(submitData) {
            if (this.isVehicleLog()) {
                // 车辆日志
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execVehicleLogSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execVehicleLogSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isCarPosition()) {
                // 车辆实时定位
                this.warningMsg("请联系管理员执行车辆实时定位爬虫!")
                return false
            } else if (this.isCarStatistics()) {
                // 车辆统计
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        let diffDays = moment(submitData.endDate).diff(submitData.beginDate, "days")
                        for (let j = 0; j <= diffDays; j++) {
                            isNotStop = Number(j) === diffDays ? false : true
                            submitData.date = moment(submitData.beginDate).add(j, "days").format("YYYY-MM-DD")
                            // console.log(diffDays)
                            await funSpider.execVehicleStatisticsSpider(submitData).then(() => {
                                this.commonSuccess(isNotStop)
                            })
                        }
                        
                        /* await funSpider.execVehicleStatisticsSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        }) */
                    }
                } else {
                    await funSpider.execVehicleStatisticsSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isOrder()) {
                // 订单
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execOrderSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execOrderSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isServiceArea()) {
                // 服务区
            } else if (this.isElectricity()) {
                // 换电任务
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        console.log(submitData.areas[i])
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execBatteryTaskSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execBatteryTaskSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isMove()) {
                // 挪车任务
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        console.log(submitData.areas[i])
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execMoveTaskSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execMoveTaskSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isWallet()) {
                // 钱包
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execWalletSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execWalletSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isBill()) {
                // 行程单
                funMaintenance.resetTravel({ id: submitData.BillId }).then(res => {
                    this.commonSuccess()
                })
            } else if (this.isUser()) {
                // 用户
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === (submitData.areas.length - 1) ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execUserSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execUserSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isParking()) {
                // 站点
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execParkingSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execParkingSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isVehicle()) {
                // 车辆
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execVehicleSpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execVehicleSpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            } else if (this.isDaily()) {
                // 每日数据
                if (submitData.areas) {
                    for (let i in submitData.areas) {
                        let isNotStop = Number(i) === submitData.areas.length - 1 ? false : true
                        submitData.serviceArea = submitData.areas[i]
                        await funSpider.execDailySpider(submitData).then(() => {
                            this.commonSuccess(isNotStop)
                        })
                    }
                } else {
                    await funSpider.execDailySpider(submitData).then(() => {
                        this.commonSuccess()
                    })
                }
            }
        },
        tranceCommitData() {
            let commitData = JSON.parse(JSON.stringify(this.dialogData))
            if (!commitData.areas || commitData.areas.length <= 0) {
                this.warningMsg("请选择服务区!")
                return undefined
            }
            // 车辆日志、车辆统计、订单、挪车任务、钱包、用户
            if (this.isVehicleLog() || this.isCarStatistics() || this.isOrder() || this.isMove() || this.isWallet() || this.isUser() || this.isDaily()) {
                if (!this.selectedTime || this.selectedTime.length < 2 || !moment(this.selectedTime[0]).isValid() || !moment(this.selectedTime[1]).isValid()) {
                    this.warningMsg("请选择运行爬虫的时间段!")
                    return undefined
                }
                commitData.beginDate = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
                commitData.endDate = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            } else {
                delete commitData.beginDate
                delete commitData.endDate
            }
            return commitData
        },
        isVehicleLog() {
            return this.isNeedBySpiderName("车辆日志")
        },
        isCarPosition() {
            return this.isNeedBySpiderName("车辆实时定位")
        },
        isCarStatistics() {
            return this.isNeedBySpiderName("车辆统计")
        },
        isOrder() {
            return this.isNeedBySpiderName("订单")
        },
        isServiceArea() {
            return this.isNeedBySpiderName("服务区")
        },
        isElectricity() {
            return this.isNeedBySpiderName("换电任务")
        },
        isMove() {
            return this.isNeedBySpiderName("挪车任务")
        },
        isWallet() {
            return this.isNeedBySpiderName("钱包")
        },
        isBill() {
            return this.isNeedBySpiderName("行程单")
        },
        isUser() {
            return this.isNeedBySpiderName("用户")
        },
        isParking() {
            return this.isNeedBySpiderName("站点")
        },
        isVehicle() {
            return this.isNeedBySpiderName("车辆")
        },
        isDaily() {
            return this.isNeedBySpiderName("每日数据")
        },
        isNeedBySpiderName(name) {
            let back = false
            if (this.spiderName.indexOf(name) !== -1) {
                back = true
            }
            return back
        },
        _close() {
            this.showDialog = false
            this.dialogData.areas = []
            this.dialogData.selectedTime = []
            this.dialogData.beginDate = ""
            this.dialogData.endDate = ""
        },
        commonSuccess(isNotEnd) {
            if (isNotEnd) {
                return false
            }
            this.successMsg("爬虫已运行，请稍后手动刷新数据，并查看是否正确!")
            this._close()
            this.refreshData(true)
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .page-point-config {
    margin-top: 2vh !important;
    width: 96%;
    height: auto;
    .el-dialog__body {
        padding: 5px;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 80vh;
        min-height: 650px;
    }
    .demand-btn {
        position: absolute;
        top: 5px;
        left: 370px;
        z-index: 15;
    }
    .search-keyword {
        position: absolute;
        top: 5px;
        left: 45px;
        z-index: 15;
        width: 320px;
        .search-list {
            width: 100%;
            margin-left: 1px;
            min-width: 160px;
            height: auto;
            min-height: 200px;
            max-height: 360px;
            overflow: auto;
            background: #ffffff;
            border-radius: 2px;
            box-shadow: 0 0 1px rgba(0,0,0,.5);
            font-size: 13px;
            li {
                padding: 0 20px;
                height: 40px;
                line-height: 40px;
                overflow: hidden;
                border-bottom: 1px solid #eee;
            }
            li:hover {
                background: #e7f5fd;
            }
        }
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .point-list-btn {
        display: block;
        position: absolute;
        top: 6px;
        left: 5px;
        z-index: 10;
        background-color: #ffffff;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        font-size: 24px;
        padding: 1px 3px;
    }
    .point-config-box {
        position: absolute;
        top: 45px;
        left: 5px;
        width: 436px;
        height: auto;
        z-index: 10;
        background: #fff;
        .el-table--border {
            border-top: 0;
        }
        .el-tabs__content {
            padding: 0 !important;
        }
        .el-table__body-wrapper {
            max-height: calc(90vh - 300px);
            overflow-y: auto;
        }
        .el-tabs--border-card {
            border-bottom: 0;
            box-shadow: 0 0px 0px 0 rgba(0,0,0,1), 0 0 0px 0 rgba(0,0,0,1);
        }
        .el-input__inner {
            padding: 0 5px;
        }
    }
    .point-btn {
        font-size: 14px;
        padding: 4px;
    }
    .info-window {
        padding: 10px;
        width: 260px;
    }
}
</style>

<template>
    <div>
        <el-dialog title="定位点配置" custom-class="c-el-dialog page-point-config" :visible.sync="showDialog" :close-on-press-escape="false"
            :before-close="_beforClose" :close-on-click-modal="false" @click.native.stop="isShowSearchList = false">
            <el-alert class="mrgb10" type="warning" :closable="false" :title="alertTitle"></el-alert>
            <div class="map-container" id='mapPointConfig'>
                <div class="search-keyword" @click.stop="isShowSearchList = true">
                    <el-input v-model="searchKeyword" size="medium" clearable placeholder="请输入地名查询"
                        @focus="isShowSearchList = true" @clear="searchClear">
                        <template slot="append">
                            <el-button @click.stop="querySearch">查询</el-button>
                        </template>
                    </el-input>
                    <ul class="search-list" v-if="isShowSearchList && searchList.length > 0">
                        <li v-for="item in searchList" :key="item.id" @click.stop="handleSelect(item)">{{ item.name }}</li>
                    </ul>
                </div>
                <span class="total-count col_primary" title="当前查询出的总配置点位数量">点位数量：{{ markers && markers.length }}</span>
                <span class="point-list-btn" @click="isShowFold = !isShowFold" :title="isShowFold ? '点位列表收起' : '点位列表展开'">
                    <i :class="isShowFold ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
                </span>
                <div class="point-config-box" v-if="isShowFold">
                    <el-tabs v-model="activeIndex" type="border-card" @tab-click="handleGroupClick">
                        <el-tab-pane :label="tag.category || '未分类'" :name="tindex.toString()" v-for="(tag, tindex) in pointGroups" :key="'tab-' + tindex">
                            <el-table :data="tag.items" border size="small" highlight-current-row :row-class-name="tableRowClassName" @row-click="rowClick">
                                <el-table-column label="分类" width="110">
                                    <template slot-scope="scope">
                                        <el-select v-if="scope.row.isCanInput" v-model="scope.row.category" size="mini" filterable allow-create clearable default-first-option
                                            @change="(v) => { saveTempTags(v, scope.row) }">
                                            <el-option v-for="(opt, opIndex) in tempTags" :key="'tab-select-' + opIndex" placeholder="请选择分类"
                                                :label="opt" :value="opt"></el-option>
                                        </el-select>
                                        <span v-else>{{ scope.row.category }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="点位名称">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.name" size="mini" v-if="scope.row.isCanInput" @blur="_blur(scope.row, scope.$index)"></el-input>
                                        <span v-else>
                                            {{ scope.row.name }}
                                            <span class="curp" title="查看经纬度" @click.stop="viewLocation(scope.row)"><i class="el-icon-view col_primary"></i></span>
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="范围(米)" width="88">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.checkDistance" type="number" :min="defaultInfoItem.checkDistance" :step="10" size="mini" 
                                            v-if="scope.row.isCanInput" @blur="setCurPointCircle(scope.row, scope.$index)"></el-input>
                                        <span v-else>{{ scope.row.checkDistance }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" width="52">
                                    <template slot-scope="scope">
                                        <el-button class="point-btn mrgl5" type="danger" size="mini" plain @click.stop="_delete(scope.row, scope.$index)"><i class="el-icon-delete-solid"></i></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="fl">
                        <el-button class="mrgt5 mrgl5 mrgb5" size="small" type="primary" @click="_add()">新增点位</el-button>
                        <el-button class="mrgt5 mrgl5 mrgb5" type="warning" size="small" icon="el-icon-time" @click.stop="openDemandConfig()">调度时段配置</el-button>
                    </div>
                    <div class="fr">
                        <el-button class="mrgt5 mrgr5 mrgb5" size="small" @click="_beforClose()">关闭</el-button>
                        <el-button class="mrgt5 mrgr5 mrgb5" size="small" type="danger" @click="_save()">保存</el-button>
                    </div>
                    <div class="clearb"></div>
                </div>
            </div>
            <span slot="footer">
                <el-button size="small" @click="_beforClose()">关闭</el-button>
            </span>
        </el-dialog>

        <!--智能调度时间段配置-->
        <demand-config :ref="refDemandConfig" @refreshData="setDemandNodes"></demand-config>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from 'moment'
import DemandConfig from './components/demandConfig'
export default {
    name: "pageDeviceScreen",
    components: { DemandConfig },
    data() {
        let that = this
        return {
            refDemandConfig: "refPointConfigToDemandConfig",
            showDialog: false,
            alertTitle: "使用说明：1、分类可自行输入，并需要按回车确定，之后可点击下拉选择；2、新增点位时默认创建在当前地图中心位置，请自行拖动点位到指定的位置。",
            searchKeyword: "",
            isShowSearchList: false,
            searchList: [],
            map: null, // 地图
            placeSearch: null,
            infoWindow: null,
            defaultInfoItem: {
                category: null,
                name: "",
                lng: null,
                lat: null,
                checkDistance: 50
            },
            pointTimeNodes: [], // 智能调度时间段配置数组
            isShowFold: true,
            contextMenu: null,
            markers: [],
            activeIndex: "0",
            tempTags: [], // 记录一个临时标签缓存
            pointTags: [],
            pointIndex: 0,
            pointGroups: [],
            oldPoints: [],
            oldTimeNodes: [],
            points: [],
            circleMarkers: [],
            listQuery: {
                area: ""
            }
        }
    },
    unmounted() {
        this.map?.destroy()
    },
    methods: {
        async open() {
            this.showDialog = true
            this.activeIndex = "0"
            window.$common.fullLoading()
            await this.initMap()
            await this.getPointTags()
            await this.getDataList()
            // 分组
            this.setPointGroup()
            // 默认设置数据
            this.setPointMarkers()
            // 设置指定点位覆盖的范围
            this.setPointCircle()
            window.$common.closeFullLoading()
        },
        async getPointTags() {
            this.listQuery.area = this.$store.getters.serviceArea || ""
            await funReport.GetPointConfigCategories(this.listQuery).then(response => {
                this.pointTags = response
            })
        },
        async getDataList() {
            this.listQuery.area = this.$store.getters.serviceArea || ""
            await funReport.GetPointConfig({ area: this.listQuery.area}).then(res => {
                // 原始数据
                this.oldPoints = JSON.parse(JSON.stringify(res.points))
                this.pointTimeNodes = res.timeNodes || []
                this.oldTimeNodes = JSON.parse(JSON.stringify(this.pointTimeNodes))
                this.points = res.points.map(x => {
                    x.isCanInput = false
                    return x
                })
            })
        },
        // 分组
        setPointGroup() {
            let group = this.pointTags.map(x => {
                let temp = {
                    category: x,
                    items: this.points.filter(m => m.category === x)
                }
                return temp
            })
            this.tempTags = JSON.parse(JSON.stringify(this.pointTags))
            this.tempTags = this.tempTags.filter(x => x)
            if (!group || group.length <= 0) {
                group = []
                group.push({
                    category: null,
                    items: []
                })
            }
            this.pointGroups = group
        },
        // 切换分类
        handleGroupClick(tab, event) {
            this.activeIndex = tab.index.toString()
            this.setPointMarkers()
            this.setPointCircle()
        },
        async initMap() {
            if (this.map) {
                return false
            }
            await window.$common.loadGaoDeMap({ plugins: "AMap.PlaceSearch" }, (Amap) => {
                // 生成地图
                this.initCreateMap()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            this.map = new AMap.Map("mapPointConfig", {  //设置地图容器id
                resizeEnable: true,
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            this.map.addControl(new AMap.ToolBar())
            this.map.addControl(new AMap.Scale())
            this.placeSearch = new AMap.PlaceSearch()
        },
        // 默认设置数据
        setPointMarkers() {
            this.clearPointMarkers()
            let acIndex = Number(this.activeIndex)
            let points = this.pointGroups[acIndex] && this.pointGroups[acIndex].items || []
            if (points.length <= 0) {
                return false
            }
            let markers = []
            for (let i in points) {
                let item = points[i]
                // 新建标记点marker
                let marker = this.setCurPointMarkers(item)
                markers.push(marker)
            }
            this.markers = markers
            this.map.add(this.markers)
            // 有默认值，直接定位到第一个为中心点
            if (this.markers.length > 0) {
                let pos = this.markers[0].getPosition()
                this.moveToCenter({ lng: pos.lng, lat: pos.lat})
            }
        },
        // 新建单个点位marker
        setCurPointMarkers(item, isAdd) {
            let config = {
                draggable: true,
                position: [item.lng, item.lat],
                label: {
                    content: item.name || "新增点",
                    direction: "top",
                    offset: [0, -2]
                },
                extData: item
            }
            // 新增点
            if (isAdd) {
                config = {
                    ...config,
                    anchor: "bottom-center",
                    content: `<img width="19" height="32" src="/static/images/map/poi-marker-red.png" />`,
                    offset: new AMap.Pixel(0, 4)
                }
            }
            let marker = new AMap.Marker(config)
            // 点击弹出详情
            marker.on("click", (e) => {

            })
            // 拖动点位结束
            marker.on("dragend", (e) => {
                this.drageMarker(e)
            })
            return marker
        },
        // 设置覆盖范围
        setPointCircle() {
            this.clearCircleMarkers()
            let acIndex = Number(this.activeIndex)
            let points = this.pointGroups[acIndex] && this.pointGroups[acIndex].items || []
            if (points.length <= 0) {
                return false
            }
            let circles = []
            let fillColors = ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"]
            for (let i in points) {
                let item = points[i]
                let center = new AMap.LngLat(item.lng, item.lat);
                let circle = this.setCurPointCircle(item)
                circles.push(circle)
            }
            this.map.add(circles)
            this.circleMarkers = circles
        },
        // 设置当前的范围
        setCurPointCircle(item, index) {
            let back = false
            if (index != undefined && this.circleMarkers[index]) {
                this.circleMarkers[index].setCenter([item.lng, item.lat])
                this.circleMarkers[index].setRadius(item.checkDistance)
            } else {
                let fillColors = ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"]
                let center = new AMap.LngLat(item.lng, item.lat);
                let circle = new AMap.Circle({
                    center: center, //圆心
                    radius: item.checkDistance, //半径
                    borderWeight: 1,
                    strokeColor: "#FF33FF", 
                    strokeWeight: 4,
                    strokeOpacity: 0.2,
                    fillOpacity: 0.2,
                    strokeStyle: 'dashed',
                    strokeDasharray: [10, 10], 
                    // 线样式还支持 'dashed'
                    fillColor: fillColors[Math.floor(Math.random() * 5)]
                })
                back = circle
            }
            return back
        },
        // 拖动点位结束
        drageMarker(marker) {
            let acIndex = Number(this.activeIndex)
            let points = this.pointGroups[acIndex].items || []
            let index = marker.target.getExtData().index
            let pos = marker.target.getPosition()
            points[index].lng = pos.lng
            points[index].lat = pos.lat
            // 范围
            this.setCurPointCircle(points[index], index)
        },
        clearMarkers() {
            this.clearPointMarkers()
            this.clearCircleMarkers()
        },
        clearPointMarkers(item) {
            this.infoWindow && this.infoWindow.close()
            if (item) {
                this.map.remove(item)
            } else {
                this.markers && this.map.remove(this.markers)
                this.markers = []
            }
        },
        clearCircleMarkers(item) {
            if (item) {
                this.map.remove(item)
            } else {
                this.circleMarkers && this.map.remove(this.circleMarkers)
                this.circleMarkers = []
            }
        },
        viewLocation(item) {
            this.alert(`
                覆盖范围：${item.checkDistance}米<br/>
                经度：${item.lng}<br/>
                纬度：${item.lat}`, `【${item.name}】经纬度`, {
                dangerouslyUseHTMLString: true
            })
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex
        },
        rowClick(row, column, event) {
            row.isCanInput = true
            this.pointIndex = row.index
            this.moveToCenter(row)
        },
        moveToCenter(item) {
            let lnglat = [item.lng, item.lat]
            this.map.setCenter(lnglat)
        },
        // 打开时间段配置
        openDemandConfig() {
            this.$refs[this.refDemandConfig].open(this.pointTimeNodes)
        },
        // 设置时间段配置的值
        setDemandNodes(datas) {
            this.pointTimeNodes = JSON.parse(JSON.stringify(datas))
        },
        querySearch() {
            if (!this.searchKeyword) {
                this.warningMsg("请输入查询地址")
                return false
            }
            window.$common.fullLoading()
            this.placeSearch.search(this.searchKeyword, (status, result) => {
                this.searchList = result.poiList && result.poiList.pois || []
                this.isShowSearchList = this.searchList && this.searchList.length > 0 ? true : false
                if (!this.isShowSearchList) {
                    this.warningMsg("未查询到相关信息!")
                }
                window.$common.closeFullLoading()
            })
        },
        handleSelect(item) {
            console.log(item)
            let lnglat = [item.location.lng, item.location.lat]
            this.moveToCenter({ lng: item.location.lng, lat: item.location.lat})
            this.isShowSearchList = false
        },
        searchClear() {
            this.searchList = []
            this.isShowSearchList = false
        },
        _add() {
            let acIndex = Number(this.activeIndex)
            let points =  this.pointGroups[acIndex].items || []
            let item = JSON.parse(JSON.stringify(this.defaultInfoItem))
            let mapCenter = this.map.getCenter()
            item.index = points.length
            item.isCanInput = true
            item.lng = mapCenter.lng
            item.lat = mapCenter.lat
            // 新增point覆盖物点位
            let marker = this.setCurPointMarkers(item, true)
            points.push(item)
            this.markers.push(marker)
            this.map.add(marker)
            // 新增圆形范围
            this.setPointCircle()
        },
        _blur(row, index) {
            this.markers[index].setLabel({
                content: row.name,
                direction: "top",
                offset: [0, -2]
            })
        },
        _delete(row, index) {
            this.confirm(`确定删除点位【${row.name}】吗？`).then(() => {
                let acIndex = Number(this.activeIndex)
                let points = this.pointGroups[acIndex].items || []
                points.splice(index, 1)
                // 删除点位后要重新渲染点位覆盖物，否则数据会错位
                this.setPointMarkers()
                this.setPointCircle()
            })
        },
        _save() {
            this.confirm("确定保存当前配置吗？").then(() => {
                let isCanSave = true
                let commitDatas = []
                for (let i in this.pointGroups) {
                    commitDatas = commitDatas.concat(this.pointGroups[i].items)
                }
                for (let i in commitDatas) {
                    if (!commitDatas[i].name || commitDatas[i].checkDistance <= 0) {
                        isCanSave = false
                        break
                    }
                }
                if (!isCanSave) {
                    this.errorMsg("名称和范围必须配置，请核对!")
                    return false
                }
                if (commitDatas && commitDatas.length >= 0) {
                    let params = {
                        area: this.listQuery.area,
                        points: JSON.parse(JSON.stringify(commitDatas)),
                        timeNodes: this.pointTimeNodes || []
                    }
                    window.$common.fullLoading()
                    funReport.UpdatePointConfig(params).then(() => {
                        this.commonSuccess()
                    })
                }
            })
        },
        _beforClose() {
            let isCanClose = true
            let groups = []
            for (let i in this.pointGroups) {
                groups = groups.concat(this.pointGroups[i].items)
            }
            // 数量不同直接判断不同
            if (groups.length != this.oldPoints.length) {
                isCanClose = false
            } else {
                // 否则判断内容是否有不同
                for(let i in groups) {
                    let info = groups[i]
                    let index = this.oldPoints.findIndex(x => {
                        let result = false
                        if ((x.name == info.name) 
                            && (x.lng == info.lng) 
                            && (x.lat == info.lat) 
                            && (x.checkDistance == info.checkDistance)) {
                            result = true
                        }
                        return result
                    })
                    if (index == -1) {
                        isCanClose = false
                        break
                    }
                }
            }
            // 判断配置点位是否相同
            if (JSON.stringify(this.oldTimeNodes) != JSON.stringify(this.pointTimeNodes)) {
                console.log(JSON.stringify(this.oldTimeNodes))
                console.log(JSON.stringify(this.pointTimeNodes))
                isCanClose = false
            }
            if (!isCanClose) {
                this.confirm("配置有改动，请确认是否需要保存!", "提示", {
                    confirmButtonText: '保存',
                    cancelButtonText: '不保存'
                }).then(() => {
                    this._save()
                }).catch(() => {
                    this._close()
                })
                return false
            }
            this._close()
        },
        _close() {
            this.clearMarkers()
            setTimeout(() => {
                this.searchKeyword = ""
                this.isShowSearchList = false
                this.searchList = []
                this.markers = []
                this.points = []
                this.circleMarkers = []
                this.showDialog = false
            }, 10)
        },
        saveTempTags(v, row) {
            // 值为空，都替换成null
            if (!v) {
                row.category = null
            }
            // 没有就临时保存一次
            if (v && this.tempTags.indexOf(v) === -1) {
                this.tempTags.push(v)
            }
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData()
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .comp-user-areas {
    max-width: 360px;
}
</style>

<template>
    <div>
        <el-dialog title="用户可展示的服务区" custom-class="c-el-dialog comp-user-areas" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" :closeOnPressEscape="false" :showClose="false">
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-alert class="mrgb20" type="warning" :closable="false" title="请配置当前用户可查看的服务区 (全部展示则不需要选择)"></el-alert>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="服务区展示" prop="isAllAreas">
                            <el-radio-group v-model="dialogData.isAllAreas" @change="changeType">
                                <el-radio :label="false" border size="small" style="margin-right: 5px;">全部展示</el-radio>
                                <el-radio :label="true" border size="small">自定义展示</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="服务区选择">
                            <el-select :ref="refSelectAreas" class="c-pw100" placeholder="请选择服务区(可多选)" size="medium" multiple
                                clearable v-model="dialogData.areas" :disabled="!dialogData.isAllAreas">
                                <el-option v-for="ws in allAreas" :key="ws.id" :label="ws.name" :value="ws.id">
                                    <span class="fl">{{ ws.name }}</span>
                                    <span class="fr mrgr20">
                                        <span class="col_success" v-if="ws.isActive">已启用</span>
                                        <span class="col9" v-else>未启用</span>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <!--<el-button size="small" @click="_close()">关闭</el-button>-->
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funUser from "@/api/system/user"
import { AllServiceAreas } from "@/api/all"
export default {
    name: "compUserAreas",
    data() {
        return {
            refSelectAreas: "refUserAreasToSelectAreas",
            showDialog: false,
            dialogData: {
                id: "",
                isAllAreas: false,
                areas: []
            },
            allAreas: [],
            formRefName: "refUserToUserAreas",
            formRules: {
                isAllAreas: [{ required: true, message: '请选择服务区的展示类型', trigger: 'blur' }]
            }
        }
    },
    methods: {
        async open(row) {
            await this.getAllAreas()
            this.dialogData.id = row.id
            await funUser.getUserAreasById(this.dialogData).then(res => {
                this.dialogData.isAllAreas = res && res.length > 0 || false
                this.dialogData.areas = res
            })
            this.showDialog = true
        },
        async getAllAreas(){
            window.$common.fullLoading()
            await AllServiceAreas().then(res => {
                window.$common.closeFullLoading()
                this.allAreas = res
                this.allAreas.sort((a, b) => {
                    return Number(b.isActive) - Number(a.isActive)
                })
            })
        },
        changeType(v) {
            this.dialogData.isAllAreas = v || false
            this.dialogData.areas = v ? this.dialogData.areas : []
        },
        _save() {
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    let commitData = {
                        id: this.dialogData.id,
                        data: this.dialogData.areas
                    }
                    window.$common.fullLoading()
                    funUser.setUserAreasById(commitData).then(() => {
                        this.commonSuccess()
                    })
                } else {
                    return false
                }
            })
        },
        _close() {
            this.showDialog = false
            this.$nextTick(() => {
                if (this.$refs[this.formRefName]) {
                    this.$refs[this.formRefName].resetFields()
                }
            })
        },
        commonSuccess(val, data) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData(data)
            })
        },
        refreshData(data) {
            this.$emit("refreshData", data)
        }
    }
}
</script>
import request from '@/utils/request'

export function getAllSpider(data) {
  return request({
    url: '/api/app/spider',
    method: 'get',
    params: data
  })
}

export function getSpiderDetail(data) {
  return request({
    url: '/api/app/spider/' + data.id,
    method: 'get',
    params: data
  })
}

export function getSpiderAreas(data) {
  return request({
    url: '/api/app/spider/areas',
    method: 'get',
    params: data
  })
}

export function getSpiderNames(data) {
  return request({
    url: '/api/app/spider/names',
    method: 'get',
    params: data
  })
}

export function execServiceAreaSpider(data) {
  return request({
    url: '/api/app/spider/service-area',
    method: 'post',
    data
  })
}

export function execParkingSpider(data) {
  return request({
    url: '/api/app/spider/parking',
    method: 'post',
    data
  })
}

export function execOrderSpider(data) {
  return request({
    url: '/api/app/spider/order',
    method: 'post',
    data
  })
}

export function execMoveTaskSpider(data) {
  return request({
    url: '/api/app/spider/move-task',
    method: 'post',
    data
  })
}

export function execBatteryTaskSpider(data) {
  return request({
    url: '/api/app/spider/battery-change-task',
    method: 'post',
    data
  })
}

export function execWalletSpider(data) {
  return request({
    url: '/api/app/spider/wallet',
    method: 'post',
    data
  })
}

export function execUserSpider(data) {
  return request({
    url: '/api/app/spider/user',
    method: 'post',
    data
  })
}


export function execVehicleStatisticsSpider(data) {
  return request({
    url: '/api/app/spider/vehicle-statistics',
    method: 'post',
    data
  })
}

export function execVehicleLogSpider(data) {
  return request({
    url: '/api/app/spider/vehicle-log',
    method: 'post',
    data
  })
}

export function execVehicleSpider(data) {
  return request({
    url: '/api/app/spider/vehicle',
    method: 'post',
    data
  })
}

export function execDailySpider(data) {
  return request({
    url: '/api/app/spider/daily',
    method: 'post',
    data
  })
}

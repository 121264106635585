<style lang="scss" scoped>
/deep/ .page-system-location-valid {
    margin-top: 2vh !important;
    max-width: 98%;
    width: 98%;
    .el-dialog__body {
        padding: 2px;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 90vh;
    }
}
</style>

<template>
    <div>
        <el-dialog title="选择服务区和时间" custom-class="c-el-dialog page-system-location-valid" :visible.sync="showDialog"
            :before-close="_close" :append-to-body="true">
            <div class="map-container" :id='container'></div>
        </el-dialog>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from 'moment'

let dataVars = {
    map: null, // 地图
    polygonMap: null,
    parkingMarker: [],
    expandParkingMarkers: [], // 站点扩展后的范围
    expandLocationParkingMarkers: [], // js方法扩展站点后的范围
    markers: null,
    clusterLines: [], // 聚合点与点位之间连线
}
export default {
    name: "pageSystemLocationValid",
    data() {
        let that = this
        return {
            showDialog: false,
            container: "LocationContainer" + Math.random(),
            coverMarker: null,
            coverPaths: [], // 服务区多边形点位
            parkings: [],
            parkData: {},
            markLnglats: [],
            infoWindow: null
        }
    },
    methods: {
        async open(parkData) {
            this.parkData = parkData || {}
            this.markLnglats = parkData.lnglats || []
            this.showDialog = true
            this.getCurrentAreaData()
            await this.initMap()
            await this.initLoadData()
        },
        // 加载数据
        async initLoadData() {
            this.clearAllMarkers()
            await this.getPackings()
            this.setAllMarkers()
            // 创建窗体
            this.createinfoWindow()
        },
        // 站点数据
        async getPackings() {
            this.parkings = []
            let area = this.$store.getters.serviceArea || ""
            await funReport.GetParkingLocations({ area }).then(response => {
                this.parkings = response
            })
            this.setPackingData()
            // 设置站点扩展后的范围
            if (this.parkData && this.parkData.isExpand) {
                this.setExpandParking()
            }
        },
        // 设置站点信息
        setPackingData() {
            this.clearParking()
            this.clearExpandLocationParkingMarkers()
            let markers = []
            let datas = JSON.parse(JSON.stringify(this.parkings))
            for (let i in datas) {
                let item = datas[i]
                let marker = new AMap.Polygon({
                    height: 10,
                    zIndex: 100,
                    bubble: true,
                    path: item.pointList,
                    strokeWeight: 1,
                    fillColor: "#1791fc", // "#f56c6c",
                    fillOpacity: 0.2,
                    strokeColor: "#1791fc", // "#f56c6c"
                    extData: item
                })
                markers.push(marker)
            }
            dataVars.map.add(markers)
            dataVars.parkingMarker = markers
            // this.setExpendLocationPackingData()
            /* for (let i in datas) {
                let item = datas[i]
                let marker = new AMap.Polygon({
                    height: 10,
                    zIndex: 100,
                    bubble: true,
                    path: window.$common.expandRectangle(item.pointList, 20),
                    strokeWeight: 1,
                    fillColor: "#e0992d", // "#f56c6c",
                    fillOpacity: 0.2,
                    strokeColor: "#e0992d", // "#f56c6c"
                    extData: item
                })
                dataVars.parkingMarker.push(marker)
                dataVars.map.add(marker)
            } */
        },
        // js本地扩展后的站点信息
        setExpendLocationPackingData() {
            this.clearExpandLocationParkingMarkers()
            let markers = []
            let datas = JSON.parse(JSON.stringify(this.parkings))
            for (let i in datas) {
                let item = datas[i]
                let marker = new AMap.Polygon({
                    height: 10,
                    zIndex: 100,
                    bubble: true,
                    path: window.$common.expandRectangle(item.pointList, 20),
                    strokeWeight: 1,
                    fillColor: "#e0992d", // "#f56c6c",
                    fillOpacity: 0.2,
                    strokeColor: "#e0992d", // "#f56c6c"
                    extData: item
                })
                markers.push(marker)
            }
            dataVars.map.add(markers)
            dataVars.expandLocationParkingMarkers = markers
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverMarker = areaItem || {}
                this.coverPaths = areaItem.pointList || []
            }
        },
        async initMap() {
            await window.$common.loadGaoDeMap(null, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map(this.container, {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                bubble: true,
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 服务区有中心点则异动到服务区中心
            if (this.coverMarker && this.coverMarker.centerLng && this.coverMarker.centerLat) {
                let curLnglat = [this.coverMarker.centerLng, this.coverMarker.centerLat]
                this.moveToCenter({ lnglat: curLnglat })
            }
        },
        // 设置覆盖范围
        setExpandParking() {
            this.clearExpandParkingMarkers()
            let items = []
            for (let i in this.parkings) {
                let x = this.parkings[i]
                let index = this.markLnglats.findIndex(k => k.parkId == x.id)
                if (index != -1) {
                    x.expandParkings = this.markLnglats[index].expandParkings
                    items.push(x)
                }
            }
            let expandMaker = []
            let fillColors = ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"]
            for (let i in items) {
                let item = items[i]
                let marker = new AMap.Polygon({
                    height: 10,
                    zIndex: 100,
                    bubble: true,
                    path: item.expandParkings,
                    strokeWeight: 1,
                    fillColor: fillColors[Math.floor(Math.random() * 5)], // "#f56c6c",
                    fillOpacity: 0.2,
                    strokeOpacity: 0.6,
                    strokeStyle: 'dashed',
                    strokeColor: "#f56c6c" // "#f56c6c"
                })
                expandMaker.push(marker)
            }
            dataVars.map.add(expandMaker)
            dataVars.expandParkingMarkers = expandMaker
        },
        // 设置用户点marker
        setAllMarkers() {
            let items = this.markLnglats
            let markers = []
            for (let i in items) {
                let item = items[i]
                let marker = new AMap.Marker({
                    position: item.lnglat,
                    anchor: "bottom-center",
                    content: `<img width="14" height="22" src="/static/images/map/poi-marker-default.png" />`,
                    offset: new AMap.Pixel(0, 4),
                    extData: item
                })
                // 站点点击选中
                marker.on("click", (ev) => {
                    let target = ev.target.getExtData()
                    let pos = ev.target.getPosition()
                    let content = `<div style="padding: 0 10px 0 0;">
                                        <div class="col_danger f16 fwb">${target.parkTag}</div>
                                        <div>地址：${target.parkName}</div>
                                        <div>坐标：${target.lnglat}</div>
                                    </div>`
                    dataVars.infoWindow.setContent(content)
                    dataVars.infoWindow.open(dataVars.map, [pos.lng, pos.lat])
                })
                markers.push(marker)
                // 如果有聚合点，则显示聚合点，红色表示
                if (item.expandParkings && item.expandParkings.length === 1) {
                    let tagItem = item.expandParkings[0]
                    let tmarker = new AMap.Marker({
                        position: tagItem,
                        anchor: "bottom-center",
                        content: `<img width="10" height="14" src="/static/images/map/poi-marker-red.png" />`,
                        offset: new AMap.Pixel(0, 3)
                    })
                    markers.push(tmarker)
                    // 聚合点与点位之间连线
                    let cLines = new AMap.Polyline({
                        path: [tagItem, item.lnglat],
                        strokeColor: "#e0992d", 
                        strokeWeight: 2,
                        strokeOpacity: 0.8,
                        strokeStyle: 'dashed'
                    })
                    dataVars.clusterLines.push(cLines)
                    dataVars.map.add(cLines)
                }
                /* let titem = JSON.parse(JSON.stringify(item.lnglat))
                titem[0] = titem[0].toFixed(3)
                titem[1] = titem[1].toFixed(3)
                let tmarker = new AMap.Marker({
                    position: titem,
                    anchor: "bottom-center",
                    content: `<img width="14" height="22" src="/static/images/map/poi-marker-red.png" />`,
                    offset: new AMap.Pixel(0, 4)
                })
                markers.push(tmarker) */
            }
            if (items.length > 0) {
                dataVars.map.add(markers)
                dataVars.markers = markers

                // 默认中心移动到服务区第一个点位
                let temp = items.find(x => {
                    return dataVars.polygonMap.contains(x.lnglat)
                })
                // 没有默认则到第一个
                if (!temp) {
                    temp = items[0]
                }
                this.moveToCenter({ lnglat: temp.lnglat })
            }
            // 全部都是站点外则显示扩展范围，站点坐标集合大于1
            let hasExpandParking = items.find(x => x.expandParkings.length > 1)
            if (this.parkData && this.parkData.isExpand && !hasExpandParking) {
                this.setExpendLocationPackingData()
            }
        },
        // 创建信息窗体
        createinfoWindow() {
            dataVars.infoWindow = new AMap.InfoWindow({
                content: "",
                offset: [0, -25],
                autoMove: true
            })
        },
        clearAllMarkers() {
            this.clearParking()
            this.clearExpandParkingMarkers()
            this.clearExpandLocationParkingMarkers()
            this.clearMarkers()
            this.clearClusterLines()
        },
        clearParking() {
            for (let i in dataVars.parkingMarker) {
                dataVars.map.remove(dataVars.parkingMarker[i])
            }
            dataVars.parkingMarker = []
        },
        clearExpandParkingMarkers() {
            dataVars.expandParkingMarkers && dataVars.map.remove(dataVars.expandParkingMarkers)
            dataVars.expandParkingMarkers = null
        },
        clearExpandLocationParkingMarkers() {
            dataVars.expandLocationParkingMarkers && dataVars.map.remove(dataVars.expandLocationParkingMarkers)
            dataVars.expandLocationParkingMarkers = null
        },
        clearMarkers() {
            dataVars.infoWindow && dataVars.infoWindow.close()
            dataVars.markers && dataVars.map.remove(dataVars.markers)
            dataVars.markers = null
        },
        clearClusterLines() {
            for (let i in dataVars.clusterLines) {
                dataVars.map.remove(dataVars.clusterLines[i])
            }
            dataVars.clusterLines = []
        },
        moveToCenter(item) {
            dataVars.map.setCenter(item.lnglat)
        },
        _close() {
            dataVars.map?.destroy()
            dataVars.map = null
            this.showDialog = false
        }
    }
}
</script>
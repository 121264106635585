<style scoped lang="scss">

</style>

<template>
  <div class="page-travel-bill-detail">
    <div class="toolbars mrgb5">
        <div class="buttons mrgb5">
            <div class="fl" v-if="billItem && billItem.name">
                【<span class="col_danger">{{ billItem.name }}<span class="col6">--{{ billItem.phone }}</span></span>】
                <span class="col9">
                    时间段：
                    <el-tag type="info" size="medium">{{ getFormatDate(billItem.beginDate, null, "YYYY-MM-DD HH:mm:ss") }}</el-tag>
                    至
                    <el-tag type="info" size="medium">{{ getFormatDate(billItem.endDate, null, "YYYY-MM-DD HH:mm:ss") }}</el-tag>
                </span>
                <el-tooltip content="判断依据：当前页数据中的用户名称，手机号，骑行时间段，有一项不符均为异常数据!" placement="top-start"  v-if="isSampleValidError">
                    <el-tag class="mrgl10" type="danger" size="mini">数据有异常</el-tag>
                </el-tooltip>
            </div>
            <div class="fr">
                <el-select class="c-el-input mrgr5 mrgb5" placeholder="订单是否有效" clearable v-model="listQuery.isZeroOrder"
                    @change="_search()" size="medium">
                    <el-option label="有效订单" :value="false"></el-option>
                    <el-option label="无效订单" :value="true"></el-option>
                </el-select>
                <el-select class="c-el-input mrgr5 mrgb5" placeholder="支付状态" clearable v-model="listQuery.isPaid"
                    @change="_search()" size="medium">
                    <el-option label="已支付" :value="true"></el-option>
                    <el-option label="未支付" :value="false"></el-option>
                </el-select>
                <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                <el-tooltip content="仅导出已支付的有效行程单，如需其他单据，请联系管理员!" placement="top-end">
                    <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel(true)"><i class="fa fa-sign-out"></i> 导出行程单</el-button>
                </el-tooltip>
                <el-tooltip content="按当前搜索条件筛选导出行程单!" placement="top-end" v-if="isSystemConfig">
                    <el-button class="mrgb5" type="danger" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 按条件导出行程单</el-button>
                </el-tooltip>
            </div>
            <div class="clearb"></div>
        </div>
    </div>
    <el-table :data="tableData" style="width: 100%" border size="medium">
        <el-table-column label="序号" width="55">
            <template slot-scope="scope">{{ ++scope.$index }}</template>
        </el-table-column>
        <el-table-column label="订单编号" prop="orderNo"></el-table-column>
        <el-table-column label="姓名" prop="userName" width="88">
            <template slot-scope="scope">
                <span v-if="scope.row.isNameError" class="col_danger">{{ scope.row.userName }}</span>
                <span v-else>{{ scope.row.userName }}</span>
            </template>
        </el-table-column>
        <el-table-column label="电话" prop="phone" width="120">
            <template slot-scope="scope">
                <span v-if="scope.row.isPhoneError" class="col_danger">{{ scope.row.phone }}</span>
                <span v-else>{{ scope.row.phone }}</span>
            </template>
        </el-table-column>
        <el-table-column label="开始时间" prop="startTime"></el-table-column>
        <el-table-column label="结束时间" prop="endTime"></el-table-column>
        <el-table-column label="城市" prop="city" width="88"></el-table-column>
        <el-table-column label="起点" prop="startAddress"></el-table-column>
        <el-table-column label="终点" prop="endAddress"></el-table-column>
        <el-table-column label="金额(元)" prop="rechargeCostText" width="88"></el-table-column>
        <el-table-column label="骑行时长" prop="ridingTime" width="100"></el-table-column>
        <el-table-column label="里程(公里)" prop="mile" width="100"></el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>

    <!--导出excel-->
    <export-to-excel :ref="refExportToExcel"></export-to-excel>
  </div>
</template>

<script>
  import permission from "@/mixins/permission"
  import enumConfigs from "@/mixins/enumConfigs"
  import * as funMaintenance from '@/api/maintenance'
  import moment from "moment"
  import ExportToExcel from "@/components/Excels/JsonToExcel"
  import { UserTravelBill } from "@/mixins/commonPageExport"
  export default {
    name: "pageTravelBillDetail",
    mixins: [permission, enumConfigs],
    components: { ExportToExcel },
    data() {
      return {
        refExportToExcel: "refTravelBillDetailToExportExcel",
        tableData: [],
        total: null,
        listQuery: {
            billId: "",
            isZeroOrder: null,
            isPaid: null,
            keywords: "",
            page: 1,
            size: window.$common.appPageSize
        },
        billItem: {},
        isPagination: false,
        isSampleValidError: false // 用名字、电话、时间段简单判断数据是否有错，只能判断当前页数据
      }
    },
    activated() {
        let params = this.$route.params
        if (params && params.billItem) {
            this.listQuery.billId = params.billItem.id
            this.billItem = params.billItem
            this._search()
        } else {
            this._search()
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        getDataList () {
            if (this.listQuery.billId) {
                window.$common.fullLoading()
                funMaintenance.getTravelDetail(this.listQuery).then(response => {
                    window.$common.closeFullLoading()
                    this.total = response.totalCount
                    this.isPagination = response.totalCount > 0 || false
                    if (response && response.items && response.items.length > 0) {
                        this.travelDataValid(response.items)
                        this.tableData = this.tranceDatas(response.items)
                    } else {
                        this.tableData = []
                    }
                })
            } else {
                this.tableData = []
            }
        },
        // 简单验证爬回来的数据是否有错
        travelDataValid(items) {
            if (!this.billItem) {
                return false
            }
            try {
                let temp = items.find(x => {
                    let result = false
                    let bill = this.billItem
                    if (moment(bill.beginDate).isValid()
                        && moment(bill.endDate).isValid()
                        && moment(x.startTime).isValid()
                        && moment(x.endTime).isValid()) {
                        if (bill.name != x.userName 
                            || bill.phone != x.phone 
                            || moment(bill.beginDate).isAfter(x.startTime) 
                            || moment(bill.endDate).isBefore(x.endTime)) {
                            result = true
                        }
                    } else {
                        result = true
                    }
                    return result
                })
                console.log(moment(this.billItem.endDate).format("YYYY-MM-DD HH:mm:ss"))
                if (temp) {
                    this.isSampleValidError = true
                    this.alert("数据有异常，请联系管理员核实!")
                }
            } catch(ex) {
                console.error("数据验证结果有误!")
            }
        },
        tranceDatas(items) {
            items.map((x, i) => {
                x.sort = ++i
                x.city = ""
                x.rechargeCostText = `￥${x.rechargeCost / 100}`
                x.mile = `${x.mile / 1000}公里`
                x.isNameError = false
                x.isPhoneError = false
                if (x.ridingTime) {
                    let hour = moment.duration(x.ridingTime).hours()
                    let minute = moment.duration(x.ridingTime).minutes()
                    let second = moment.duration(x.ridingTime).seconds()
                    hour = hour < 10 ? `0${hour}` : hour
                    minute = minute < 10 ? `0${minute}` : minute
                    second = second < 10 ? `0${second}` : second
                    x.ridingTime = `${hour}:${minute}:${second}`
                    x.startTime = this.getFormatDate(x.startTime)
                    x.endTime = this.getFormatDate(x.endTime)
                }
                // 判断名称和电话是否相同
                let bill = this.billItem
                if (bill && bill.name && bill.phone) {
                    x.isNameError = bill.name != x.userName ? true : false
                    x.isPhoneError = bill.phone != x.phone ? true : false
                }
            })
            items = this.getCity(items)
            return items
        },
        getCity(items) {
            let city = ""
            try {
                for (let i in items) {
                    let item = items[i]
                    let regx1 = /.+?(省|市)/g
                    let regx2 = /.+?(市)/g
                    let arr = []
                    if (regx1.test(item.endAddress)) {
                        arr = item.endAddress.match(regx1)
                        city = arr.length > 1 ? arr[1] : arr[0]
                        break
                    }
                }
                if (city) {
                    items = items.map(x => {
                        x.city = city
                        return x
                    })
                }
            } catch(ex) {
                console.error("城市提取失败!")
            } 
            return items
        },
        _search() {
            if (!this.listQuery.billId) {
                this.warningMsg("请从行程单进入查看详情!")
                return false
            }
            this.listQuery.page = 1
            this.getDataList()
        },
        openExportExcel(type) {
            if (!this.listQuery.billId) {
                this.warningMsg("请从行程单进入查看详情!")
                return false
            }
            if (!this.billItem) {
                this.warningMsg("请选择需要查询的行程单!")
                return false
            }
            if (this.total > this.maxExportCount) {
                this.warningMsg("不允许导出超过2万条数据，如有需要，请联系管理员处理!")
                return false
            }
            let searchs = JSON.parse(JSON.stringify(this.listQuery))
            searchs.page = 1
            searchs.size = this.maxExportCount
            // 导出已支付的有效订单
            if (type) {
                searchs.isZeroOrder = false
                searchs.isPaid = true
            }
            // 生成行程单
            let billItem = this.billItem
            let exportData = {
                title: "行程单",
                name: `行程人：${billItem.name}`,
                phone: `联系电话：${billItem.phone}`,
                totalDesc: "", // 合计金额和订单数
                applyTime: `申请日期：${this.getFormatDate(billItem.creationTime, null, "YYYY-MM-DD")}`, // 申请日期
                travelTime: `行程时间：${this.getFormatDate(billItem.beginDate, null, "YYYY-MM-DD")}至${this.getFormatDate(billItem.endDate, null, "YYYY-MM-DD")}`, // 行程时间
                printTime: `行程单生成时间：${this.getFormatDate(billItem.jobInfo.startTime)}`, // 打印生成时间
                tableDatas: []
            }
            window.$common.fullLoading()
            funMaintenance.getTravelDetail(searchs).then(response => {
                window.$common.closeFullLoading()
                if (response && response.items && response.items.length > 0) {
                    exportData.tableDatas = this.tranceDatas(response.items)
                } else {
                    exportData.tableDatas = []
                }
                // 计算并导出数据
                if (exportData.tableDatas.length <= 0) {
                    this.warningMsg("没有可导出的数据!")
                    return false
                }
                let totalAmount = 0
                response.items.forEach(x => {
                    totalAmount += Number(x.rechargeCost)
                })
                totalAmount = totalAmount / 100
                exportData.totalDesc = `合计：共${response.items.length}笔行程，合计${totalAmount}元`
                UserTravelBill(`${billItem.name}的行程单.xlsx`, exportData)
            }).catch(ex => {
                console.log("export error")
                this.warningMsg("数据查询失败!")
            })
        }
    }
  }
</script>
<style lang="scss" scoped>
/deep/ .comp-analysis-delete-dialog {
    width: 88%;
    max-width: 520px;
}
</style>

<template>
    <div>
        <el-dialog title="批量删除上传数据？" custom-class="c-el-dialog comp-analysis-delete-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
            <el-alert class="mrgb10" type="warning" title="注：只会删除未生成过报表的数据，其他情况请联系管理员删除!" :closable="false"></el-alert>
            <span>日期: </span>
            <el-date-picker v-model="selectDate" type="date" placeholder="选择日期" size="medium"></el-date-picker>
            <el-table class="mrgt20" :data="tableData" border @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="服务区" prop="area" width="120"></el-table-column>
                <el-table-column label="表类型名" prop="name"></el-table-column>
            </el-table>
            <span slot="footer">
                <el-button size="small" @click="_close()">取消</el-button>
                <el-button size="small" type="primary" @click="_save()">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from "moment"
export default {
    name: "compAnalysisDelete",
    data() {
        return {
            showDialog: false,
            tableData: [],
            selectDate: "",
            multipleSelection: []
        }
    },
    methods: {
        open(keys, titles) {
            if (!keys || !titles || keys.length !== titles.length) {
                this.errorMsg("未知错误，请联系管理员!")
                return false
            }
            this.tableData = keys.map((item, i) => {
                let temp = {
                    area: this.$store.getters.serviceArea,
                    key: item,
                    name: titles[i]
                }
                return temp
            })
            this.showDialog = true
        },
        handleSelectionChange(val) {
            this.multipleSelection = val
        },
        _save() {
            if (!this.selectDate) {
                this.warningMsg("请先选择需要删除的日期!")
                return false
            }
            if (this.multipleSelection.length <= 0) {
                this.warningMsg("删除的数据类型不能为空!")
                return false
            }
            let area = this.$store.getters.serviceArea
            let date = moment(this.selectDate).format("YYYY-MM-DD")
            let names = this.multipleSelection.map(x => {
                return x.name
            })
            let count = this.multipleSelection.length
            this.confirm(`<span class="col_danger">服务区：${area}</span><br />
                日期：${date}<br />
                数量：${count}项<br />
                数据类型：【${names.join("，")}】`, "删除确认?", { dangerouslyUseHTMLString: true }).then(() => {
                let commitData = {
                    area: area,
                    date: date,
                    tables: this.multipleSelection.map(x => {
                        return x.key
                    })
                }
                window.$common.fullLoading()
                funReport.DeleteImportDate(commitData).then(() => {
                    this.commonSuccess()
                })
            })
        },
        _close() {
            this.showDialog = false
            this.selectDate = ""
            this.tableData = []
        },
        commonSuccess(val) {
            let tips = val || "删除成功!"
            this.alert(tips).then(() => {
                this._close()
                window.$common.closeFullLoading()
                this.refreshData()
            })
        },
        refreshData() {
            this.$emit("refreshData", true)
        }
    }
}
</script>
<style scoped lang="scss">
  .user_list_box {
    .addbtn {
      margin-bottom: 10px;
    }
    /deep/ .c-el-dialog {
      max-width: 520px;
    }
  }
</style>

<template>
  <div class="user_list_box">
    <div class="addbtn">
      <el-button type="primary" size="small" @click="_edit()" v-if="isUsersCreate"><i class="fa fa-plus"></i> 新增</el-button>
    </div>
    <el-table :data="tableData" border>
      <el-table-column type="expand" fixed>
        <template slot-scope="props">
          <el-form label-position="left" inline class="c-table-expand">
            <el-form-item label="帐号ID">
              <span>{{ props.row.id }}</span>
            </el-form-item>
            <el-form-item label="帐号名">
              <span>{{ props.row.userName }}</span>
            </el-form-item>
            <el-form-item label="姓名">
              <span>{{ props.row.name }}</span>
            </el-form-item>
            <el-form-item label="是否激活">
              <span>{{ props.row.isActive ? "是" : "否" }}</span>
            </el-form-item>
            <el-form-item label="创建时间">
              <span>{{ getFormatDate(props.row.creationTime) }}</span>
            </el-form-item>
            <el-form-item label="最后登录时间">
              <span>{{ getFormatDate(props.row.lastModificationTime) }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="帐号名" prop="userName"></el-table-column>
      <el-table-column label="姓名" prop="name"></el-table-column>
      <el-table-column label="是否激活">
        <template slot-scope="scope">
          {{ scope.row.isActive ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="creationTime" :formatter="tableDateFormat" v-if="$root.isPc"></el-table-column>
      <el-table-column label="最后登录时间" prop="lastModificationTime" :formatter="tableDateFormat" v-if="$root.isPc"></el-table-column>
      <el-table-column label="操作" width="100px">
        <template slot-scope="scope">
          <el-dropdown trigger="click">
            <el-button type="primary" plain size="mini">更多<i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="_edit(scope.row)" v-if="isUsersUpdate">编辑</el-dropdown-item>
              <el-dropdown-item @click.native="_delete(scope.row)" v-if="isUsersDelete">删除</el-dropdown-item>
              <el-dropdown-item @click.native="_changePassword(scope.row)" v-if="isUsersManage">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="_areas(scope.row)" v-if="isUsersUpdate">配置服务区</el-dropdown-item>
              <!--<el-dropdown-item @click.native="_updateSystem()" v-if="scope.row.userName === 'admin'">系统名称</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container" v-if="isPagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
            :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>

    <el-dialog title="帐号编辑" custom-class="c-el-dialog" :visible.sync="showDialog" :before-close="_close" :close-on-click-modal="false">
      <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="100px" size="small">
        <el-form-item label="帐号名" prop="userName">
          <el-input placeholder="请输入帐号名" v-model="dialogData.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input placeholder="请输入姓名" v-model="dialogData.name"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="dialogType === createOrUpdate[0]">
          <el-input type="password" placeholder="请输入6-32位密码" v-model="dialogData.password"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="surePassword" v-if="dialogType === createOrUpdate[0]">
          <el-input type="password" placeholder="请再次输入密码" v-model="dialogData.surePassword"></el-input>
        </el-form-item>
        <el-form-item label="是否激活" prop="isActive">
          <el-radio-group v-model="dialogData.isActive">
            <el-radio :label="false" border size="small" style="margin-right: 5px;">否</el-radio>
            <el-radio :label="true" border size="small">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="选择角色" prop="roleNames">
          <div>
            <el-tree :props="defaultProps" node-key="id" :data="treeData" :check-strictly="false" :check-on-click-node="true" :default-expanded-keys="checkedKeys"
              :default-checked-keys="checkedKeys" ref="roleTree" show-checkbox @check="checkChange"></el-tree>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="_close()">取消</el-button>
        <el-button size="small" type="primary" plain @click="_save()">保存</el-button>
      </span>
    </el-dialog>

    <!--服务区选择-->
    <areas :ref="refAreas" @refreshData="getListData()"></areas>
  </div>
</template>

<script>
  import configMixins from "@/mixins/config"
  import permission from "@/mixins/permission"
  import * as users from '@/api/system/user'
  import * as roles from '@/api/system/role'
  import { updateSystemSettings } from '@/api/common'
  import areas from './components/areas'
  export default {
    name: "user",
    mixins: [configMixins, permission],
    components: { areas },
    data() {
      var validSurePassword = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"))
        } else if (value !== this.dialogData.password) {
          callback(new Error("两次输入密码不一致"))
        } else {
          callback()
        }
      }
      return {
        refAreas: "refUserToAreas",
        tableData: [],
        total: null,
        listQuery: {
          keywords: null,
          isActive: null,
          page: 1,
          size: window.$common.appPageSize
        },
        isPagination: false,
        showDialog: false,
        dialogType: "",
        dialogData: {},
        formRefName: 'formRefName',
        formRules: {
          userName: [{ required: true, message: '请输入帐号名', trigger: 'blur' }],
          name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' },{ min: 6, max: 32, message: '长度在6到32个字符', trigger: 'blur' }],
          surePassword: [{ required: true, validator: validSurePassword, trigger: 'blur' }],
          isActive: [{ required: true, message: '请选择是否激活', trigger: 'blur' }],
          roleNames: [{ required: true, message: '选择角色', trigger: 'blur' }]
        },
        defaultProps: {
          label: 'label',
          children: 'children'
        },
        allRoles: [], // 临时存储的需要转换的权限
        treeData: [], // 绑定的权限数据
        checkedKeys: [] // 默认选中的权限数据
      }
    },
    mounted() {
      this.getBroadcast("bdChangeRole", res => {
        this.getListData()
        this.getAllRoles()
      })
      this.getListData()
      this.getAllRoles()
    },
    methods: {
      handleSizeChange(val) {
        window.$common.fullLoading()
        this.listQuery.size = val
        this.getListData()
      },
      handleCurrentChange(val) {
        window.$common.fullLoading()
        this.listQuery.page = val
        this.getListData()
      },
      getListData() {
        window.$common.fullLoading()
        let params = this.mxPageToSkipCount(this.listQuery)
        users.getAll(params).then(response => {
          window.$common.closeFullLoading()
          this.total = response.totalCount
          this.isPagination = response.totalCount > 0 || false
          if (response && response.items && response.items.length > 0) {
            this.tableData = response.items
          } else {
            this.tableData = []
          }
        })
      },
      _edit(row) {
        this.showDialog = true
        if (row) {
          this.dialogType = this.createOrUpdate[1]
          users.getDetail({id: row.id}).then(res => {
            this.dialogData = { ...res }
            this._getUserRole(res)
            // this.treeData = this.tranceRole(this.allRoles, res)
          })
        } else {
          this.dialogType = this.createOrUpdate[0]
          this.dialogData = {
            id: 0,
            userName: "",
            name: "",
            password: "",
            surePassword: "",
            isActive: true,
            roleNames: null,
            surname: "",
            email: "",
            phoneNumber: "",
            lockoutEnabled: false,
          }
          this.treeData = this.tranceRole(this.allRoles)
        }
      },
      _getUserRole(data) {
        users.getUserRoles(data).then(res => {
          let names = res.items.map(x => x.name)
          let item = names && names.length > 0 ? { roleNames: names } : null
          this.treeData = this.tranceRole(this.allRoles, item)
          this.dialogData.roleNames = this.checkedKeys
        })
      },
      _changePassword(row) {
        this.prompt("设置新密码:", "修改密码", {
            closeOnClickModal: false,
            closeOnPressEscape: false,
            inputValue: "",
            beforeClose: (action, instance, done) => {
                if (action == "confirm") {
                    if (instance.inputValue && instance.inputValue.length >= 0) {
                        done()
                    } else {
                        this.warningMsg("请输入新密码!")
                    }
                } else {
                    done()
                }
            }
        }).then(res => {
            let commitData = {
                id: row.id,
                newPassword: res.value
            }
            users.updateUserPassword(commitData).then(res => {
                this.commonSuccess()
            })
        })
      },
      _areas(row) {
        this.$refs[this.refAreas].open(row)
      },
      _save() {
        this.$refs[this.formRefName].validate(valid => {
          if (valid) {
            window.$common.fullLoading()
            let commitTemp = {
              id: this.dialogData.id,
              userName: this.dialogData.userName,
              name: this.dialogData.name,
              isActive: this.dialogData.isActive,
              roleNames: this.dialogData.roleNames,
              surname: this.dialogData.name,
              email: this.dialogData.userName + "@fljoy.net",
              phoneNumber: "",
              lockoutEnabled: false,
            }
            if (this.dialogType === this.createOrUpdate[0]) {
              commitTemp.password = this.dialogData.password
              users.create(commitTemp).then(res => {
                window.$common.closeFullLoading()
                this.commonSuccess("操作成功", () => {
                  this._areas(res)
                })
              })
            } else if (this.dialogType === this.createOrUpdate[1]) {
              users.update(commitTemp).then(() => {
                window.$common.closeFullLoading()
                this.commonSuccess()
              })
            }
            
          } else {
            return false
          }
        })
      },
      getAllRoles() {
        users.getAssignableRoles().then(res => {
          if (res.items && res.items.length > 0) {
            this.allRoles = res.items
          }
        })
      },
      tranceRole(items, curItem) {
        let roleData = []
        this.checkedKeys = []
        for (let i in items) {
          let temp = {}
          let item = items[i]
          temp.id = item.name
          temp.label = item.name
          // 有子集递归执行
          if (item.children && item.children.length > 0) {
            temp.children = this.tranceRole(item.children, curItem)
          }
          if (!curItem) {
            this.checkedKeys = []
          } else {
            // 仅仅将最后一级加入默认选中数组，父级会自动选中，自动展开
            if ((!item.children || item.children.length <= 0) && curItem.roleNames && curItem.roleNames.indexOf(item.name) !== -1) {
              this.checkedKeys.push(item.name)
            }
          }
          roleData.push(temp)
        }
        return roleData
      },
      _delete(row) {
        if (row) {
          this.confirm("确定要删除名为“" + row.name + "”的角色吗？").then(() => {
            window.$common.fullLoading()
            users.remove({ id: row.id }).then(res => {
              this.commonSuccess()
            })
          })
        }
      },
      _close() {
        this.showDialog = false
        this.$nextTick(() => {
          if (this.$refs[this.formRefName]) {
            this.$refs[this.formRefName].resetFields()
          }
        })
      },
      commonSuccess(val, callback) {
          let tips = val || "操作成功!"
          this.alert(tips, "提示", { showClose: false }).then(() => {
              this._close()
              window.$common.closeFullLoading()
              this.getListData()
              if (callback) {
                  callback()
              }
          })
      },
      checkChange(data, isChecked, childChecked) {
        var curHalfKey = this.$refs.roleTree.getHalfCheckedKeys()
        var curKey = this.$refs.roleTree.getCheckedKeys()
        var commitKeys = curHalfKey.concat(curKey)
        this.dialogData.roleNames = commitKeys || []
      },
      _updateSystem() {
         this.prompt("", "修改系统名称", {
            inputType: "text",
            inputValue: this.appSiteName,
            inputPlaceholder: "请输入系统名称",
            inputPattern: /^\S*$/,
            inputErrorMessage: '系统名称必填',
            closeOnClickModal: false
          }).then((obj) => {
            if (obj && obj.value) {
              window.$common.fullLoading()
              updateSystemSettings({
                systemName: obj.value,
                pageSize: 10
              }).then(() => {
                this.alert("系统名称修改成功!").then(() => {
                  window.$common.closeFullLoading()
                  // 修改后重新保存storege
                  this.setSystemConfig()
                  // 刷新
                  location.reload()
                })
              })
            }
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
.page-analysis-wallet {}
</style>

<template>
    <div class="page-analysis-wallet">
        <div class="toolbars mrgb5">
            <div class="buttons mrgb5">
                <div class="fl">
                    <!--<el-upload class="dib mrgr5" :action="uploadUrl" :headers="uploadHeaders" 
                        :on-success="importData" :on-error="importData" :show-file-list="false">
                        <el-button type="warning" size="medium"><i class="fa fa-sign-in"></i> 导入钱包数据</el-button>
                    </el-upload>-->
                </div>
                <div class="fr">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_search"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div>
            <el-table :data="tableData" style="width: 100%" border size="small">
                <el-table-column label="日期(星期)" prop="date" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.date }}<br />
                        <el-tag type="parmary" size="mini">{{ getFormatWeek(scope.row.date) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="服务区" prop="area"></el-table-column>
                <el-table-column label="交易单号" prop="transactionId"></el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="手机号" prop="mobile" width="150"></el-table-column>
                <el-table-column label="充值金额" prop="rechargeAmount"></el-table-column>
                <el-table-column label="赠送金额" prop="giftAmount"></el-table-column>
                <el-table-column label="交易金额" prop="amount"></el-table-column>
                <el-table-column label="交易类型" prop="transactionType"></el-table-column>
                <el-table-column label="支付渠道" prop="paymentChannel"></el-table-column>
                <el-table-column label="操作人" prop="operator"></el-table-column>
                <el-table-column label="时间" prop="time" width="110" :formatter="tableDateFormat"></el-table-column>
            </el-table>

            <div class="pagination-container" v-if="isPagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" 
                    :current-page="listQuery.page" :page-sizes="appPageSizes" :page-size="listQuery.size" 
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from "moment"
export default {
    name: "pageAnalysisWallet",
    data() {
        let that = this
        return {
            uploadUrl: window.$common.appBaseApi() + "/api/app/wallet-records/import",
            uploadHeaders: window.$common.appHeaders(),
            tableData: [],
            total: null,
            listQuery: {
                area: "",
                beginDate: "",
                endDate: "",
                page: 1,
                size: window.$common.appPageSize
            },
            isPagination: false,
            selectedTime: [moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59")],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            }
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this._search()
            })
        }
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        setQueryTime(timeArr) {
            this.listQuery.beginDate = timeArr[0] || ""
            this.listQuery.endDate = timeArr[1] || ""
        },
        handleSizeChange(val) {
            window.$common.fullLoading()
            this.listQuery.size = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            window.$common.fullLoading()
            this.listQuery.page = val
            this.getDataList()
        },
        setSelectedTime() {
            let psTime = ""
            let peTime = ""
            if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
            }
            if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
            }
            this.listQuery.beginDate = psTime
            this.listQuery.endDate = peTime
            this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            funReport.GetWalletReports(this.listQuery).then(response => {
                window.$common.closeFullLoading()
                this.total = response.totalCount
                this.isPagination = response.totalCount > 0 || false
                if (response && response.items && response.items.length > 0) {
                    this.tableData = response.items.map((x, i) => {
                        // x.sort = (this.listQuery.page - 1) * this.listQuery.size + (i + 1)
                        x.date = moment(x.time).format("YYYY-MM-DD")
                        return x
                    })
                } else {
                    this.tableData = []
                }
            })
        },
        // 导入
        importData(res) {
            if (res >= 0) {
                this.alert("导入成功!").then(() => {
                    this._search()
                })
            } else {
                this.alert("导入失败，可能数据错误或者有重复数据!")
            }
        },
        _search() {
            this.listQuery.page = 1
            this.getDataList()
        }
    }
}
</script>
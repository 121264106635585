<style scoped lang="scss">
  .page-test-decode {
    .decode-items {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f5f5f5;
      padding-bottom: 12px;
      margin-bottom: 12px;
      .params, .results {
        flex: 1;
        margin-right: 12px;
        min-height: 150px;
        overflow: auto;
        /deep/ .value {
          height: 100%;
          max-height: 320px;
          .el-textarea__inner {
            height: 100%;
          }
        }
      }
      .results {
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 12px;
        /deep/ .jv-container .jv-code {
          padding: 0px;
        }
      }
      .btns {
        .el-button--mini {
          padding: 6px 15px;
          width: 80px;
          margin-bottom: 5px;
        }
      }
    }
  }
</style>

<template>
  <div class="page-test-decode">
    <div class="decode-items" v-for="(item, index) in datas" :key="index">
      <div class="mrgr10 col9">{{ Number(index) + 1 }}.</div>
      <div class="params">
        <el-input class="value" v-model="item.value" type="textarea" placeholder="请录入需要解析的数据"></el-input>
      </div>
      <div class="results">
        <json-viewer v-if="item.result" :ref="'refTestDecode' + index" :value="item.result" :expand-depth="1" copyable></json-viewer>
        <span class="col9" v-else>此处显示解析后的数据</span>
        <!--<el-input v-model="item.result" type="textarea" rows="5" readonly placeholder="解析后的数据"></el-input>-->
      </div>
      <div class="btns">
        <div><el-button type="danger" plain size="mini" @click="_remove(item, index)">移除本行</el-button></div>
        <div><el-button type="info" plain size="mini" @click="_clear(item)">清空数据</el-button></div>
        <div><el-button type="primary" plain size="mini" @click="_copy(item)">复制List</el-button></div>
        <div><el-button type="warning" plain size="mini" @click="_copy(item, true)">复制Data</el-button></div>
      </div>
    </div>
    <div class="mrgt10">
      <el-button type="primary" plain @click="_add()"><i class="fa fa-plus"></i> 新增解析行</el-button>
      <el-button type="success" @click="_decode()"><i class="fa fa-podcast"></i> 确认解析</el-button>
      <el-button class="fr" type="warning" @click="_copyConcat()"><i class="fa fa-copy"></i> 复制List组合</el-button>
    </div>
  </div>
</template>

<script>
  import decodeData from "@/utils/decodeData"
  import JsonViewer from 'vue-json-viewer'
  export default {
    name: "pageTestDecode",
    components: { JsonViewer },
    data() {
      return {
        datas: []
      }
    },
    mounted() {
      this._add()
    },
    methods: {
      _add() {
        let temp = {
          value: "",
          result: ""
        }
        this.datas.push(temp)
      },
      _remove(item, index) {
        if (item.value || item.result) {
          this.confirm("存在解析的数据，是否确认移除？").then(() => {
            this.datas.splice(index, 1)
          })
        } else {
          this.datas.splice(index, 1)
        }
      },
      _copy(row, isAll) {
        try {
          if (!window.$util.isJsonFn(row.result)) {
            this.warningMsg(`不是正确的JSON数据格式!`)
            return false
          }
          let txt = ""
          if (isAll) {
            txt = JSON.stringify(row.result)
          } else {
            if (row.result.list) {
              txt = JSON.stringify(row.result.list)
            }
          }
          try {
            window.navigator.clipboard.writeText(txt).then(() => {
                this.successMsg("复制成功!")
            })
          } catch(ex) {
            this.alert("当前不支持直接复制，请手动复制数据!").then(() => {
              let newWindow = window.open("", "_blank")
              newWindow.document.write(txt)
            })
          }
        } catch (ex) {
          this.errorMsg(`复制失败，请确认是否解析成功!`)
        }
      },
      _clear(row) {
        row.value = row.result = ""
      },
      _decode() {
        let valid = true
        for (let i in this.datas) {
          let item = this.datas[i]
          // 没有解析数据就提示错误
          item.value = item.value.replace(/"/g, "")
          item.value = item.value.trim()
          if (!item.value) {
            valid = false
            item.result = ""
            this.warningMsg(`第${Number(i) + 1}行，请录入需要解析的数据!`)
            break
          }
          // 解析数据
          try {
            let vData = decodeData.decodeBase64ToUtf8(item.value)
            item.result = vData
          } catch (a) {
            item.result = "解析失败，传入的解析数据有误!"
          }
        }
        if (!valid) {
          return false
        }
      },
      _copyConcat() {
        try {
          let valid = false
          let arr = []
          for (let i in this.datas) {
            let item = this.datas[i]
            if (window.$util.isJsonFn(item.result)) {
              let list = item.result.list
              if (list && list.length > 0) {
                arr = arr.concat(list)
                valid = true
              }
            }
          }
          if (!valid) {
            this.warningMsg(`请先解析数据!`)
            return false
          }
          let txt = JSON.stringify(arr)
          try {
            window.navigator.clipboard.writeText(txt).then(() => {
                this.successMsg("复制成功!")
            })
          } catch(ex) {
            this.alert("当前不支持直接复制，请手动复制数据!").then(() => {
              let newWindow = window.open("", "_blank")
              newWindow.document.write(txt)
            })
          }
        } catch (ex) {
          this.errorMsg(`复制失败，请确认是否解析成功!`)
        }
      }
    }
  }
</script>
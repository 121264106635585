import { Loading } from 'element-ui'
import * as util from '@/utils/tools'
import store from '../store'
import AMapLoader from '@amap/amap-jsapi-loader'

//注册全局util
window.$util = util

let common = {
  isShowMenuIcon: false, // 是否显示菜单icon
  appPageSize: window.configs.page || 10,
  appPageSizes: [5, 10,20,30,40,50,100],
  pageRefresh: true, // 页面是否重新加载
  fullLoadings: null,
  /* 全局loading */
  fullLoading() {
    this.closeFullLoading(true)
    setTimeout(() => {
      this.fullLoadings = Loading.service({
        lock: true,
        fullscreen: true,
        spinner: "fa fa-spinner fa-pulse",
        background: "rgba(0,0,0,0.7)",
        customClass: "c-el-loading"
      })
    }, 0)
  },
  closeFullLoading(immediately) {
    if (immediately) {
      this.execCloseFullLoading()
    } else {
      setTimeout(() => {
        this.execCloseFullLoading()
      }, 10);
    }
  },
  execCloseFullLoading() {
    if (this.fullLoadings) {
      this.fullLoadings.close()
    }

  },
  // axios blob方式下载文件
  blobDownload(res, name) {
    let blob = new Blob([res], {type: res.type})
    let downloadElement = document.createElement('a')
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    downloadElement.download = name; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放blob对象
  },
  // 请求的api地址
  appBaseApi() {
    let back = ""
    if (process.env.NODE_ENV === 'development') {
      back = window.configs.baseTestApi // process.env.VUE_APP_SiteApiUrl
    } else if (process.env.NODE_ENV === 'production') {
      if (process.env.VUE_APP_FLAG === 'pro') {
        back = window.configs.baseApi
      } else if (process.env.VUE_APP_FLAG === 'uat') {
        back = window.configs.baseUatApi
      } else if (process.env.VUE_APP_FLAG === 'test') {
        back = window.configs.baseTestApi
      } else {
        back = window.configs.baseApi
      }
    }
    return back
  },
  // 上传组件的headers
  appHeaders() {
    let headers = {
      Authorization: "Bearer " + store.getters.token
    }
    return headers
  },
  async loadGaoDeMap(config, successFun, failedFun) {
    let plugins = ['AMap.ToolBar', 'AMap.Scale']
    let conf = {
        key: "a6bfe02f17c041d823e9e01c6b6b4ca6", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 2.0 指定要加载的 JS API 的版本，缺省时默认为 1.4.15
        plugins
    }
    if (config) {
        for (let i in config) {
            if (i == "version") {
                conf.version = config.version || conf.version
            } else if (i == "plugins") {
                conf.plugins = config.plugins ? plugins.concat(config.plugins) : conf.plugins
            } else {
                conf[i] = config[i]
            }
        }
    }
    window._AMapSecurityConfig = {
        securityJsCode: '3409b94aca245743d5b5c72250ba8503'
    }
    await AMapLoader.load(conf).then((AMap)=>{
        // 生成地图
        successFun && successFun(AMap)
    }).catch(e => {
        console.error("地图创建出错!", e)
        failedFun && failedFun(e)
    })
  },
  expandRectangle(coords, distance) {
    let toRadians = (degrees) => {
        return degrees * Math.PI / 180;
    }

    let toDegrees = (radians) => {
        return radians * 180 / Math.PI;
    }

    let rotatePoint = (cx, cy, angle, p) => {
        const s = Math.sin(angle);
        const c = Math.cos(angle);

        // Translate point back to origin
        p[0] -= cx;
        p[1] -= cy;

        // Rotate point
        const xnew = p[0] * c - p[1] * s;
        const ynew = p[0] * s + p[1] * c;

        // Translate point back
        p[0] = xnew + cx;
        p[1] = ynew + cy;

        return p;
    }

    let expRec = (coords, distance) => {
        const earthRadius = 6378137; // 地球半径，单位：米

        // 计算中心点
        let centerX = 0;
        let centerY = 0;
        const numPoints = coords.length;
        for (let i = 0; i < numPoints; i++) {
            centerX += coords[i][0];
            centerY += coords[i][1];
        }
        centerX /= numPoints;
        centerY /= numPoints;

        // 计算旋转角度
        const angle = Math.atan2(coords[1][1] - coords[0][1], coords[1][0] - coords[0][0]);

        // 将矩形旋转为正放矩形
        const rotatedCoords = coords.map(coord => rotatePoint(centerX, centerY, -angle, coord.slice()));

        // 计算边缘的距离转换
        const dLat = (distance / earthRadius) * (180 / Math.PI);
        const dLon = (distance / (earthRadius * Math.cos(centerY * Math.PI / 180))) * (180 / Math.PI);

        // 扩展每条边
        const minLon = Math.min(...rotatedCoords.map(coord => coord[0])) - dLon;
        const maxLon = Math.max(...rotatedCoords.map(coord => coord[0])) + dLon;
        const minLat = Math.min(...rotatedCoords.map(coord => coord[1])) - dLat;
        const maxLat = Math.max(...rotatedCoords.map(coord => coord[1])) + dLat;

        const expandedCoords = [
            [minLon, minLat],
            [minLon, maxLat],
            [maxLon, maxLat],
            [maxLon, minLat]
        ];

        // 将扩展后的矩形旋转回原角度
        const finalCoords = expandedCoords.map(coord => rotatePoint(centerX, centerY, angle, coord.slice()));

        return finalCoords;
    }
    return expRec(coords, distance);
  }
}

export default common

export default {
    data () {
        return {
            EmployeeDayWorkStatus: "EmployeeDayWorkStatus",
            MoveTaskFlag: "MoveTaskFlag",
            TravelBillStatus: "TravelBillStatus"
        }
    },
    computed: {
        arrEmployeeDayWorkStatus() {
            // 员工工作状态
            return  this.getEnumConfigs(this.EmployeeDayWorkStatus).arrs
        },
        arrMoveTaskFlag() {
            // 挪车任务状态
            return  this.getEnumConfigs(this.MoveTaskFlag).arrs
        },
        arrTravelBillStatus() {
            // 行程单状态
            return  this.getEnumConfigs(this.TravelBillStatus).arrs
        }
    },
    methods: {
        // 售后维修订单状态
        valEmployeeDayWorkStatusUnHandled() {
            // 未处理
            return this.getEnumConfigsItem(this.getEnumConfigs(this.EmployeeDayWorkStatus).objs, "UnHandled", "value")
        },
        valEmployeeDayWorkStatusNormal() {
            // 正常
            return this.getEnumConfigsItem(this.getEnumConfigs(this.EmployeeDayWorkStatus).objs, "Normal", "value")
        },
        valEmployeeDayWorkStatusLate() {
            // 迟到
            return this.getEnumConfigsItem(this.getEnumConfigs(this.EmployeeDayWorkStatus).objs, "Late", "value")
        },
        valEmployeeDayWorkStatusLeaveEarly() {
            // 早退
            return this.getEnumConfigsItem(this.getEnumConfigs(this.EmployeeDayWorkStatus).objs, "LeaveEarly", "value")
        },
        valEmployeeDayWorkStatusLateLeaveEarly() {
            // 迟到&早退
            return this.getEnumConfigsItem(this.getEnumConfigs(this.EmployeeDayWorkStatus).objs, "LateLeaveEarly", "value")
        },
        valEmployeeDayWorkStatusLack() {
            // 缺卡
            return this.getEnumConfigsItem(this.getEnumConfigs(this.EmployeeDayWorkStatus).objs, "Lack", "value")
        },

        // 挪车状态
        valMoveTaskFlagInvalid() {
            // 无效
            return this.getEnumConfigsItem(this.getEnumConfigs(this.MoveTaskFlag).objs, "Invalid", "value")
        },
        valMoveTaskFlagEffective() {
            // 有效
            return this.getEnumConfigsItem(this.getEnumConfigs(this.MoveTaskFlag).objs, "Effective", "value")
        },
        valMoveTaskFlagInDoubt() {
            // 存疑
            return this.getEnumConfigsItem(this.getEnumConfigs(this.MoveTaskFlag).objs, "InDoubt", "value")
        },

        // 行程单状态
        valTravelBillStatusCreated() {
            // 已提交
            return this.getEnumConfigsItem(this.getEnumConfigs(this.TravelBillStatus).objs, "Created", "value")
        },
        valTravelBillStatusInQueue() {
            // 已加入队列
            return this.getEnumConfigsItem(this.getEnumConfigs(this.TravelBillStatus).objs, "InQueue", "value")
        },
        valTravelBillStatusInProcess() {
            // 执行中
            return this.getEnumConfigsItem(this.getEnumConfigs(this.TravelBillStatus).objs, "InProcess", "value")
        },
        valTravelBillStatusCompleted() {
            // 已完成
            return this.getEnumConfigsItem(this.getEnumConfigs(this.TravelBillStatus).objs, "Completed", "value")
        },
        valTravelBillStatusFailed() {
            // 已失败
            return this.getEnumConfigsItem(this.getEnumConfigs(this.TravelBillStatus).objs, "Failed", "value")
        }
    }
  }
  
  
  
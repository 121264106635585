<style lang="scss" scoped>
.page-moving-route {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99998;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen, .order-mousetools {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover, .order-mousetools .btn:hover {
        background-color: #f0f0f0;
    }
    .order-mousetools {
        top: 42px;
        right: 5px;
        padding: 0;
        .btn {
            display: block;
            float: right;
            padding: 4px;
            i {
                font-size: 22px;
            }
        }
        .btn-open, .btn-open:hover {
            background: rgba(121, 184, 255, 1);
            i {
                color: #ffffff;
            }
        }
        .btn-actived {
            i {
                color: #79b8ff;
            }
        }
    }
    .cluster-list {
        padding: 10px 12px;
        border-bottom: 1px dashed #eee;
        .cluster-item {
            margin-top: 3px;
        }
    }
    .cluster-list:hover {
        background-color: #f5f5f5;
    }
}
</style>

<template>
    <div class="page-moving-route">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-tooltip class="item" effect="dark" content="在系统配置中更改了挪车配置后，需手动更新下相关数据" placement="top-start">
                        <el-button type="primary" size="medium" @click="_handle()"><i class="fa fa-refresh"></i> 更新挪车数据</el-button>
                    </el-tooltip>
                </div>
                <div class="fl mrgl10 mrgt10">
                    <span class="col_danger">
                        <i class="el-icon-warning"></i>
                        每日12:30以后会自动更新数据
                    </span>
                </div>
                <div class="fr">
                    <el-tag type="info mrgr5 mrgb5">挪车开始时间：</el-tag>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedDate" type="daterange" size="medium"
                        :picker-options="pickerOptions" :clearable="false" @change="_search()"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                    <el-time-picker class="c-datetime-range mrgr5 mrgb5" is-range v-model="selectedTime" size="medium" @change="changeTime"
                        range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            注：每日12:30以后更新数据。<br />
                            拖动时间可以快捷查看该时间段的数据。<br />
                            请不要查询太长时间段的数据，否则数据量太大，地图会卡顿明显。<br />
                            如果发现挪车数据不正确，可先更新挪车数据，再查询看是否符合。<br />
                            挪车量：通过指定开始挪车时间段，查询的挪车数量，按弧线顺时针方向旋转，起点为挪车开始点，终点为挪车结束点。<br />
                            用车量、还车量：表示用户骑行的开始，结束点位，可用于大致判断挪车是否合理。
                            未来2小时内用车量、还车量：查询未来{{ futureOrderInterval }}小时内的用户订单数据，仅供参考。<br />
                        </div>
                        <el-tag class="mrgl5" type="warning" size="medium"><i class="el-icon-view f12"></i> 查看说明</el-tag>
                    </el-tooltip>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <el-button type="primary" :plain="!(cueSelectTimers.length === 2 && time >= cueSelectTimers[0] && time <= cueSelectTimers[1])" size="medium"
                            v-for="(item, time) in 24" :key="item" @click="selectTime(time)"
                            @mousedown.native="mouseSelectTime(time, 0)" @mouseup.native="mouseSelectTime(time, 1)">{{ time }}时</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='container'>
            <span class="total-count col_primary">
                挪车量：{{ moveDatas.length }}，
                <img class="mrgr2" :src="iconImages[0]" width="8" height="8" />用车量：{{ orderJson0.length }}，
                <img class="mrgr2" :src="iconImages[1]" width="8" height="8" />还车量：{{ orderJson1.length }}
            </span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <span class="order-mousetools">
                <el-tooltip :content="isChooseOpen ? '结束框选' : '开始框选'" placement="top-end">
                    <span class="btn" :class="isChooseOpen ? 'btn-open' : ''"
                        @click="chooseSwitch()"><i :class="isChooseOpen ? 'el-icon-crop' : 'el-icon-crop'"></i></span>
                </el-tooltip>
                <template v-if="isChooseOpen">
                    <el-tooltip content="矩形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[0] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[0])"><i class="el-icon-menu"></i></span>
                    </el-tooltip>
                    <el-tooltip content="圆形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[1] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[1])"><i class="el-icon-bangzhu"></i></span>
                    </el-tooltip>
                </template>
            </span>
        </div>
    </div>
</template>

<script>
import * as funMaintenance from "@/api/maintenance"
import * as funReport from "@/api/report"
import moment from 'moment'

let dataVars = {
    map: null, // 地图
    polygonMap: null,
    labelsLayer0: null,
    labelsLayer1: null,
    parkingMarker: [],
    parkingLines: [],
    mouseTool: null, // 鼠标绘制工具
    polygonObjs: [],
    textMarkers: [],
    parkTargets: [], // 点击站点map对象
    ruleTool: null
}
export default {
    name: "pageMovingRoute",
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "挪车前后相关点位，路线等。"
            },
            listQuery: {
                area: "",
                timeType: 0,
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: ""
            },
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: [moment().subtract(1, "d").format("YYYY-MM-DD"), moment().subtract(1, "d").format("YYYY-MM-DD")],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            coverMarker: null,
            coverPaths: [], // 服务区多边形点位
            orderJson0: [],
            orderJson1: [],
            parkings: [],
            moveDatas: [],
            futureOrderInterval: 2, // 需要查看未来几小时的订单
            futureOrder0: [],
            futureOrder1: [],
            isShowTimeType0: true,
            isShowTimeType1: true,
            isShowParking: true,
            isShowParkingLine: true,
            isShowMovingRoute: true,
            isChooseOpen: false, // 框选开关
            chooseTypeArray: [1, 2],
            chooseType: null,
            iconImages: ["/static/images/map/mass2.png", "/static/images/map/mass1.png", "/static/images/map/poi-marker-default.png"],
            fillColors: ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"], // 用于随机取色
            lineColors: ["#f78989", "#67c23a", "#54a6fe"],
            switchObjStates: {}, // 选框切换显示隐藏轨迹
            isMapEditState: false,
            isShowAllLine: true, // 全部显示或隐藏轨迹
            isNeedAutoSwitch: false, // 是否需要自动切换
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                this.setPolygonContains()
                this.initLoadData()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        this.getCurrentAreaData()
        await this.initMap()
        await this.initLoadData()
        this.loadKeyDown()
        this.loadKeyUp()
    },
    unmounted() {
        dataVars.map?.destroy()
        document.removeEventListener("keydown")
        document.removeEventListener("keyup")
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psDate = moment(this.selectedDate[0]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate[0]).format("00:00:00")
                }
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peDate = moment(this.selectedDate[1]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate[1]).format("23:59:59")
                }
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedDate = [this.listQuery.beginDate, this.listQuery.endDate]
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [`${this.listQuery.beginDate} ${this.listQuery.beginTime}`, `${this.listQuery.endDate} ${this.listQuery.endTime}`]
        },
        // 加载数据
        async initLoadData() {
            this.clearAllMarkers()
            await this.getPackings()
            await this._search()
        },
        // 站点数据
        async getPackings() {
            this.parkings = []
            let area = this.$store.getters.serviceArea || ""
            await funReport.GetParkingLocations({ area }).then(response => {
                this.parkings = response
            })
            this.setPackingData()
        },
        // 设置站点信息
        setPackingData() {
            this.clearParking()
            if (this.isShowParking) {
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.parkings))
                for (let i in datas) {
                    let item = datas[i]
                    let marker = new AMap.Polygon({
                        zIndex: 100,
                        bubble: true,
                        path: item.pointList,
                        strokeWeight: 1,
                        fillColor: "#1791fc", // "#f56c6c",
                        fillOpacity: 0.2,
                        strokeColor: "#1791fc", // "#f56c6c"
                        extData: item
                    })
                    // 站点点击选中
                    marker.on("click", (ev) => {
                        if (this.isMapEditState) {
                            return false
                        }
                        dataVars.parkTargets = [ev.target]
                        // 获取详情，挪入挪出数量
                        this.getParkingDetail(ev.target)
                        // 显示隐藏轨迹线
                        this.switchMoveLines()
                    })
                    dataVars.parkingMarker.push(marker)
                    dataVars.map.add(marker)
                }
            }
        },
        async getDataList() {
            // 清空数据
            this.orderJson0 = []
            this.orderJson1 = []
            this.futureOrder0 = []
            this.futureOrder1 = []
            this.moveDatas = []
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            let params = JSON.parse(JSON.stringify(this.listQuery))
            params.timeType = 0
            await funReport.GetOrderLocationOnly(params).then(response => {
                this.orderJson0 = response
            })
            params.timeType = 1
            await funReport.GetOrderLocationOnly(params).then(response => {
                this.orderJson1 = response
            })
            // 设置订单数据
            this.setPointData0()
            this.setPointData1()
            // 获取挪车数据
            await funMaintenance.GetMoveTaskLocations(this.listQuery).then(response => {
                this.moveDatas = response
            })
            this.setParkingLine()
            // 清空显示/隐藏选框轨迹线的存储状态
            this.switchObjStates = {}
            // 判断并显示轨迹线，不同颜色
            this.switchMoveLines()
            // 更新弹出文本框数据
            this.updateTextMarkerDatas()
            // 框选状态下的拖动文本框，不能继续画框，设置为默认的false
            this.chooseNeedAutoSwitch(false)
            window.$common.closeFullLoading()
        },
        // 开锁订单点位
        setPointData0() {
            this.clearLabelsLayer0()
            if (this.isShowTimeType0) {
                dataVars.labelsLayer0 = new AMap.LabelsLayer({
                    collision: false,
                    zIndex: 5
                })
                dataVars.map.add(dataVars.labelsLayer0)
                let markers = []
                let tempdatas = []
                let datas = JSON.parse(JSON.stringify(this.orderJson0))
                for (let i in datas) {
                    let item = datas[i]
                    let temp = {
                        position: [item.lng, item.lat],
                        icon: {
                            type: "image",
                            image: this.iconImages[0],
                            size: [5, 5],
                            anchor: 'center'
                        }
                    }
                    let labelMarker = new AMap.LabelMarker(temp)
                    markers.push(labelMarker)
                    tempdatas.push([item.lng, item.lat])
                }
                //  document.write(JSON.stringify(tempdatas))
                dataVars.labelsLayer0.add(markers)
            }
        },
        // 关锁订单点位
        setPointData1() {
            this.clearLabelsLayer1()
            if (this.isShowTimeType1) {
                dataVars.labelsLayer1 = new AMap.LabelsLayer({
                    collision: false,
                    zIndex: 5
                })
                dataVars.map.add(dataVars.labelsLayer1)
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.orderJson1))
                for (let i in datas) {
                    let item = datas[i]
                    let temp = {
                        position: [item.lng, item.lat],
                        icon: {
                            type: "image",
                            image: this.iconImages[1],
                            size: [5, 5],
                            anchor: 'center'
                        }
                    }
                    let labelMarker = new AMap.LabelMarker(temp)
                    markers.push(labelMarker)
                }
                dataVars.labelsLayer1.add(markers)
            }
        },
        // 轨迹线
        setParkingLine() {
            this.clearParkingLine()
            let datas = JSON.parse(JSON.stringify(this.moveDatas))
            for (let i in datas) {
                let item = datas[i]
                item.lineStartLng = item.start_lng
                item.lineStartLat = item.start_lat
                item.lineEndLng = item.end_lng
                item.lineEndLat = item.end_lat
                // 挪车没有开始和结束位置，就排除
                if (!item.start_lng || !item.start_lat || !item.end_lng || !item.end_lat) {
                    continue
                }
                let arr = []
                let random = Math.random() * (1 - 0.5) + 0.5
                let baseLng = 0
                let baseLat = 0
                // 结束经度 > 开始经度，结束纬度 > 开始纬度
                if (item.lineEndLng >= item.lineStartLng && item.lineEndLat > item.lineStartLat) {
                    baseLng = -0.002
                    baseLat = 0.003
                } else if (item.lineEndLng >= item.lineStartLng && item.lineEndLat < item.lineStartLat) {
                    baseLng = 0.002
                    baseLat = 0.003
                } else if (item.lineEndLng <= item.lineStartLng && item.lineEndLat < item.lineStartLat) {
                    baseLng = 0.002
                    baseLat = -0.003
                } else if (item.lineEndLng <= item.lineStartLng && item.lineEndLat > item.lineStartLat) {
                    baseLng = -0.002
                    baseLat = -0.003
                }
                let ctLng = (item.lineStartLng + item.lineEndLng) / 2 + baseLng // * random
                let ctLat = (item.lineStartLat + item.lineEndLat) / 2 + baseLat // * random
                arr = [
                    [item.lineStartLng, item.lineStartLat],
                    [ctLng, ctLat, item.lineEndLng, item.lineEndLat]
                ]
                let parkLine = new AMap.BezierCurve({
                    bubble: true,
                    path: arr,            // 设置线覆盖物路径
                    strokeColor: "",   // 线颜色
                    strokeOpacity: 0.5,         // 线透明度
                    strokeWeight: 1,          // 线宽
                    strokeStyle: 'solid',     // 线样式
                    // geodesic: true,
                    showDir: true,
                    extData: item
                })
                dataVars.parkingLines.push(parkLine)
                dataVars.map.add(parkLine)
            }
            // dataVars.map.add(dataVars.parkingLines)
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverMarker = areaItem || {}
                this.coverPaths = areaItem.pointList || []
            }
        },
        // 时间段选择
        changeTime(arr) {
            this.cueSelectTimers = []
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
                let sTime = moment(arr[0]).format("HH:00:00")
                let eTime = moment(arr[1]).format("HH:59:59")
                // 时间相同，配置选中效果
                if (this.listQuery.beginTime === sTime && this.listQuery.endTime === eTime) {
                    this.cueSelectTimers = [moment(arr[0]).format("H"), moment(arr[1]).format("H")]
                }
                this._search()
            }
        },
        // 快捷时间段选择
        selectTime(time) {
            this.cueSelectTimers = [time, time]
            let psTime = ""
            let peTime = ""
            let hour = time < 10 ? ("0" + time ) : time
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + hour + ":00:00")
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + hour + ":59:59")
            }
            this.selectedTime = [psTime, peTime]
            this._search()
        },
        mouseSelectTime(time, type) {
            if (type === 0) {
                // 鼠标按下
                this.curMouseDownTime = time
            } else if (type === 1) {
                // 鼠标放开
                if (this.curMouseDownTime !== time) {
                    let psTime = ""
                    let peTime = ""
                    let sHour = null
                    let eHour = null
                    if (time > this.curMouseDownTime) {
                        this.cueSelectTimers = [this.curMouseDownTime, time]
                        sHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                        eHour = time < 10 ? ("0" + time ) : time
                    } else if (time < this.curMouseDownTime) {
                        this.cueSelectTimers = [time, this.curMouseDownTime]
                        sHour = time < 10 ? ("0" + time ) : time
                        eHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                    }
                    if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                        psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + sHour + ":00:00")
                    }
                    if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                        peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + eHour + ":59:59")
                        // peTime = moment(peTime).subtract(1, "hours")
                    }
                    this.selectedTime = [psTime, peTime]
                    this._search()
                }
            }
        },
        async _search() {
            await this.getDataList()
        },
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.MouseTool"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // features: ["bg", "road"],
                // showLabel: false,
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
            // 创建鼠标绘制
            this.setMouseTools()
            // this.moveMap()
            // 右键
            this.setContextMenu()
        },
        setContextMenu() {
            // 创建右键
            let contextMenu = new AMap.ContextMenu()
            contextMenu.addItem("<i class='el-icon-star-on'></i> 隐藏/显示轨迹", (e) => {
                this.isShowAllLine = !this.isShowAllLine
                if (this.isShowAllLine) {
                    this._search()
                } else {
                    dataVars.parkingLines.forEach(pl => {
                        pl.hide()
                    })
                }
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-delete'></i> 清空框选", (e) => {
                this.clearMouseTool()
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-menu'></i> 矩形框选", (e) => {
                this.chooseType = this.chooseTypeArray[0]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-bangzhu'></i> 圆形框选", (e) => {
                this.chooseType = this.chooseTypeArray[1]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-lock'></i> 结束框选", (e) => {
                this.chooseSwitch(false)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-c-scale-to-original'></i> 开始/清空测距", (e) => {
                if (!dataVars.ruleTool) {
                    dataVars.ruleTool = new AMap.MouseTool(dataVars.map)
                    dataVars.ruleTool.rule()
                    this.isMapEditState = true
                } else {
                    dataVars.ruleTool.close(true)
                    dataVars.ruleTool = null
                    this.isMapEditState = false
                }
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-full-screen'></i> 全屏/取消全屏", (e) => {
                this.isScreen = !this.isScreen
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-switch-button'></i> 关闭右键菜单", (e) => {
                contextMenu.close()
            })
            dataVars.map.on('rightclick', (e) => {
                contextMenu.open(dataVars.map, e.lnglat)
            })
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            // 设置服务区范围
            dataVars.polygonMap = new AMap.Polygon({
                bubble: true,
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.05,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5],
                zIndex: 3
            })
            dataVars.map.add(dataVars.polygonMap)
            // 服务区有中心点则异动到服务区中心
            if (this.coverMarker && this.coverMarker.centerLng && this.coverMarker.centerLat) {
                let curLnglat = [this.coverMarker.centerLng, this.coverMarker.centerLat]
                this.moveToCenter({ lnglat: curLnglat })
            }
        },
        // 地图移动，显示隐藏海量标记
        moveMap() {
            let _setTimeout = null
            let isMapMoving = false
            // 停止变动，显示海量点
            let stopMove = () => {
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = setTimeout(() => {
                    if (isMapMoving) {
                        this.changeMapLabel(true)
                        isMapMoving = false
                    }
                }, 2000)
            }
            // 地图移动
            dataVars.map.on("mapmove", () => {
                isMapMoving = true
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = null
                this.changeMapLabel(false)
            })
            dataVars.map.on("moveend", () => {
                stopMove()
            })
            // 地图缩放
            dataVars.map.on("zoomstart", () => {
                isMapMoving = true
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = null
                this.changeMapLabel(false)
            })
            dataVars.map.on("zoomend", () => {
                stopMove()
            })
        },
        // 显示隐藏海量点
        changeMapLabel(type) {
            if (type) {
                dataVars.map.setFeatures(["bg", "point", "road"])
            } else {
                dataVars.map.setFeatures(["bg", "road"])
            }
        },
        _handle() {
            this.setSelectedTime()
            let param = {
                area: this.listQuery.area,
                beginDate: this.listQuery.beginDate,
                endDate: this.listQuery.endDate,
                keywords: this.listQuery.keywords
            }
            window.$common.fullLoading()
            funMaintenance.HandleMoveTask(param).then(res => {
                this._search()
            })
        },
        // 框选地图显示对应的数据
        /************鼠标绘制选点************/
        // 创建鼠标绘制
        setMouseTools() {
            dataVars.mouseTool = new AMap.MouseTool(dataVars.map)
            dataVars.mouseTool.on("draw", async (event) => {
                // 面积必须大于0
                if (event.obj.getArea && event.obj.getArea() > 0) {
                    this.setPolygons(event.obj)
                    await this.setTextMarkers(event.obj)
                    dataVars.map.remove(event.obj)
                }
            })
        },
        setPolygons(obj) {
            let polygon = null
            if (this.chooseType === this.chooseTypeArray[0]) {
                // 矩形
                polygon = new AMap.Rectangle(obj.getOptions())
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                // 圆形
                polygon = new AMap.Circle(obj.getOptions())
            }
            dataVars.polygonObjs.push(polygon)
            dataVars.map.add(polygon)
            // 画框后加载轨迹
            this.switchMoveLines()
        },
        async setTextMarkers(obj) {
            let content = await this.getTextMarkerContent(obj)
            let curLnglat = obj.getCenter()
            let text = new AMap.Text({
                draggable: true,
                bubble: true,
                topWhenClick: true,
                text: content,
                anchor:"center",
                position: [curLnglat.lng, curLnglat.lat]
            })
            dataVars.textMarkers.push(text)
            dataVars.map.add(text)
            // 监听判断
            text.on("click", (event) => {
                let txt = event.target.dom.querySelector(".txtClose")
                let handleClick = (ev) => {
                    let isCloseClick = txt.contains(ev.target)
                    let index = dataVars.textMarkers.findIndex(x => x._amap_id === text._amap_id)
                    if (isCloseClick) {
                        dataVars.map.remove(dataVars.polygonObjs[index])
                        dataVars.polygonObjs.splice(index, 1)
                        dataVars.map.remove(text)
                        dataVars.textMarkers.splice(index, 1)
                        this.switchMoveLines()
                    } else {
                        this.showOrHideMoveLines(true, dataVars.polygonObjs[index])
                    }
                    document.removeEventListener("click", handleClick)
                }
                document.addEventListener("click", handleClick)
            })
            text.on("mousedown", (event) => {
                // 拖动开始
                if (this.isChooseOpen) {
                    this.chooseSwitch(false)
                    this.chooseNeedAutoSwitch(true)
                }
            })
            text.on("mouseup", (event) => {
                // 拖动结束
                if (this.isNeedAutoSwitch) {
                    this.chooseSwitch(true)
                    this.chooseNeedAutoSwitch(false)
                }
            })
        },
        getParkingDetail(event) {
            if ((!event || !event.target) && dataVars.parkTargets.length <= 0) {
                return false
            }
            let obj = event.target || dataVars.parkTargets[0]
            let packItem = obj.getExtData()
            funReport.GetParkingById({ id: packItem.id }).then(res => {
                // 挪车量
                let moveInCount = 0
                let moveOutCount = 0
                let moveSameCount = 0
                dataVars.parkingLines.forEach((pl, i) => {
                    let moveItem = pl.getExtData()
                    let isStartContain = obj.contains([moveItem.lineStartLng, moveItem.lineStartLat])
                    let isEndContain = obj.contains([moveItem.lineEndLng, moveItem.lineEndLat])
                    if (isStartContain && isEndContain) {
                        // 挪入，挪出在一个选框区域
                        moveSameCount++
                    } else if (isStartContain) {
                        // 挪出
                        moveOutCount++
                    } else if (isEndContain) {
                        // 挪入
                        moveInCount++
                    }
                })
                let content = `<div>
                                    <div class="txtClose" style="position: absolute; z-index: 100; top: -8px; right: -8px;"><i class="el-icon-error f20 col6"></i></div>
                                    <div class="f13" style="padding: 5px 8px 5px 5px;">
                                        <div class="mrgb5"><span class="fwb">站点名称：</span><span>${res.name} <i class="el-icon-document-copy col_primary"></i></span></div>
                                        <div class="mrgb5"><span class="fwb">挪入选框：</span>${moveInCount}辆 <span class="col_success">(绿线)</span></div>
                                        <div class="mrgb5"><span class="fwb">挪出选框：</span>${moveOutCount}辆 <span class="col_danger">(红线)</span></div>
                                        <div class="mrgb5"><span class="fwb">框内挪车：</span>${moveSameCount}辆 <span class="col_primary">(蓝线)</span></div>
                                    </div>
                                </div>`
                let text = this.mxCommonMapTextInfo(dataVars.map, {
                    draggable: true,
                    text: content,
                    anchor: "center",
                    position: [res.centerLng, res.centerLat]
                }, () => {
                    try {
                        window.navigator.clipboard.writeText(res.name).then(() => {
                            this.successMsg("站点名称复制成功!")
                        })
                    } catch(ex) {
                        this.alert(res.name, "手动复制站点", {
                            customClass: "wordbreakba"
                        })
                    }
                }, () => {
                    dataVars.parkTargets = []
                    this.switchMoveLines()
                })
                text.on("mousedown", (event) => {
                    // 拖动开始
                    if (this.isChooseOpen) {
                        this.chooseSwitch(false)
                        this.chooseNeedAutoSwitch(true)
                    }
                })
                text.on("mouseup", (event) => {
                    console.log(this.isNeedAutoSwitch)
                    // 拖动结束
                    if (this.isNeedAutoSwitch) {
                        this.chooseSwitch(true)
                        this.chooseNeedAutoSwitch(false)
                    }
                })
            })
        },
        async getTextMarkerContent(obj, text) {
            // 挪车量
            let moveInCount = 0
            let moveOutCount = 0
            let moveSameCount = 0
            dataVars.parkingLines.forEach((pl, i) => {
                let moveItem = pl.getExtData()
                let isStartContain = obj.contains([moveItem.lineStartLng, moveItem.lineStartLat])
                let isEndContain = obj.contains([moveItem.lineEndLng, moveItem.lineEndLat])
                if (isStartContain && isEndContain) {
                    // 挪入，挪出在一个选框区域
                    moveSameCount++
                } else if (isStartContain) {
                    // 挪出
                    moveOutCount++
                } else if (isEndContain) {
                    // 挪入
                    moveInCount++
                }
            })
            // 用车量
            let useCount = 0
            for (let i in this.orderJson0) {
                let item = this.orderJson0[i]
                if (obj.contains([item.lng, item.lat])) {
                    useCount++
                }
            }
            // 还车量
            let backCount = 0
            for (let i in this.orderJson1) {
                let item = this.orderJson1[i]
                if (obj.contains([item.lng, item.lat])) {
                    backCount++
                }
            }
            // 加载未来用车订单
            let params = JSON.parse(JSON.stringify(this.listQuery))
            let baseFutureTime = moment(moment().format(`${this.listQuery.endDate} ${this.listQuery.endTime}`)).add(this.futureOrderInterval, "h")
            params.endDate = moment(baseFutureTime).format("YYYY-MM-DD")
            params.beginTime = this.listQuery.endTime
            params.endTime = moment(baseFutureTime).format("HH:mm:ss")
            if (!this.futureOrder0 || this.futureOrder0.length <= 0) {
                params.timeType = 0
                window.$common.fullLoading()
                await funReport.GetOrderLocationOnly(params).then(response => {
                    this.futureOrder0 = response
                })
            }
            let futureUseCount = 0
            for (let i in this.futureOrder0) {
                let item = this.futureOrder0[i]
                if (obj.contains([item.lng, item.lat])) {
                    futureUseCount++
                }
            }
            // 加载未来还车订单
            if (!this.futureOrder1 || this.futureOrder1.length <= 0) {
                params.timeType = 1
                await funReport.GetOrderLocationOnly(params).then(response => {
                    this.futureOrder1 = response
                })
            }
            let futureBackCount = 0
            for (let i in this.futureOrder1) {
                let item = this.futureOrder1[i]
                if (obj.contains([item.lng, item.lat])) {
                    futureBackCount++
                }
            }
            window.$common.closeFullLoading()
            let content = `<div>
                            <div class="txtClose" style="position: absolute; z-index: 100; top: -8px; right: -8px;"><i class="el-icon-error f20 col6"></i></div>
                            <div class="f12" style="padding: 5px 8px 5px 5px;">
                                <div class="col_info mrgb5 tc">--挪车数量--</div>
                                <div class="mrgb5"><span class="fwb">挪入选框：</span>${moveInCount}辆 <span class="col_success">(绿线)</span></div>
                                <div class="mrgb5"><span class="fwb">挪出选框：</span>${moveOutCount}辆 <span class="col_danger">(红线)</span></div>
                                <div class="mrgb5"><span class="fwb">框内挪车：</span>${moveSameCount}辆 <span class="col_primary">(蓝线)</span></div>
                                <div class="col_info mrgb5 tc">--订单数量--</div>
                                <div class="mrgb5"><span class="fwb">用车订单：</span>${useCount}单</div>
                                <div class="mrgb5"><span class="fwb">还车订单：</span>${backCount}单</div>
                                <div class="col_info mrgt5 mrgb5 tc">--未来${this.futureOrderInterval}小时内单量--</div>
                                <div><span class="fwb">用车订单：</span>${futureUseCount}单</div>
                                <div><span class="fwb">还车订单：</span>${futureBackCount}单</div>
                                <div></div>
                            </div>
                          </div>`
            // 如果是刷新数据，直接更新
            if (text) {
                text.setText(content)
            }
            return content
        },
        // 共用显示隐藏轨迹线
        switchMoveLines() {
            // 组合所有的选框、站点后，需要进行坐标判断
            setTimeout(() => {
                let allShowLineObjs = dataVars.polygonObjs.concat(dataVars.parkTargets)
                // 更新订单轨迹
                dataVars.parkingLines.forEach((pl, i) => {
                    let item = pl.getExtData()
                    let options = pl.getOptions()
                    let startIndex = -1
                    let endIndex = -1
                    // 不选择范围，则初始化
                    if (allShowLineObjs.length <= 0) {
                        this.setStrokeOptions(pl, options)
                        return
                    }
                    // 判断骑出和骑入
                    for (let k in allShowLineObjs) {
                        let obj = allShowLineObjs[k]
                        let isStartContain = obj.contains([item.lineStartLng, item.lineStartLat])
                        let isEndContain = obj.contains([item.lineEndLng, item.lineEndLat])
                        // 选框不包含挪出和挪入，则隐藏
                        if (this.switchObjStates[obj._amap_id] === false) {
                            startIndex = endIndex = -1
                            continue
                        }
                        // 挪出和挪车
                        if (isStartContain && isEndContain) {
                            startIndex = endIndex = k
                            continue
                        } else if (isStartContain) {
                            startIndex = k
                            continue
                        } else if (isEndContain) {
                            endIndex = k
                            continue
                        }
                    }
                    // 骑出和骑入都不在选框内
                    if (startIndex === -1 && endIndex === -1) {
                        pl.hide()
                    } else if (startIndex !== -1 && endIndex === -1) {
                        // 骑出
                        this.setStrokeOptions(pl, options, { color: this.lineColors[0], opacity: 0.8, style: "solid" })
                    } else if (startIndex === -1 && endIndex !== -1) {
                        // 骑入
                        this.setStrokeOptions(pl, options, { color: this.lineColors[1], opacity: 0.8, style: "solid" })
                    } else {
                        // 骑出和骑入都在选框内
                        this.setStrokeOptions(pl, options, { color: this.lineColors[2], opacity: 0.8, style: "solid" })
                    }
                })
            }, 50)
        },
        showOrHideMoveLines(isSwitch, switchObj) {
            // 在画选框的时候，不执行切换
            if (isSwitch && switchObj) {
                let curState = this.switchObjStates[switchObj._amap_id] === undefined ? true : this.switchObjStates[switchObj._amap_id]
                let switchItems = dataVars.parkingLines.filter(pl => {
                    let item = pl.getExtData()
                    let result = false
                    let isStartContain = switchObj.contains([item.lineStartLng, item.lineStartLat])
                    let isEndContain = switchObj.contains([item.lineEndLng, item.lineEndLat])
                    if (isStartContain || isEndContain) {
                        result = true
                    }
                    return result
                })
                // console.log(switchItems)
                for (let i in switchItems) {
                    let pl = switchItems[i]
                    let item = pl.getExtData()
                    if (curState) {
                        pl.hide()
                    } else {
                        pl.show()
                    }
                }
                this.switchObjStates[switchObj._amap_id] = !curState
            }
        },
        // 刷新时重新变更数据
        async updateTextMarkerDatas() {
            // 更新画框弹出的文本框
            for (let i in dataVars.polygonObjs) {
                let obj = dataVars.polygonObjs[i]
                let text = dataVars.textMarkers[i]
                this.getTextMarkerContent(obj, text)
            }
            // 更新站点详情的文本框
            for (let i in dataVars.parkTargets) {
                let target = dataVars.parkTargets[i]
                this.getParkingDetail(target)
            }
        },
        setStrokeOptions(obj, options, stroke) {
            options.strokeColor = stroke && stroke.color || this.lineColors[2] // this.fillColors[Math.floor(Math.random() * this.fillColors.length)]
            options.strokeOpacity = stroke && stroke.opacity || 0.5
            options.strokeWeight = stroke && stroke.weight || 1.5
            options.strokeStyle = stroke && stroke.style || "solid"
            obj.setOptions(options)
            obj.show()
        },
        chooseSwitch(isOpen) {
            if (isOpen !== undefined) {
                this.isChooseOpen = isOpen
            } else {
                this.isChooseOpen = !this.isChooseOpen
            }
            if (!this.isChooseOpen) {
                dataVars.mouseTool && dataVars.mouseTool.close(true)
                this.isMapEditState = false
            } else {
                this.chooseSelectType(this.chooseType)
                this.isMapEditState = true
            }
        },
        chooseSelectType(type) {
            // 先移除之前的选框对象
            this.chooseType = type || this.chooseTypeArray[0]
            let options = {
                strokeColor: "#1791fc", //轮廓线颜色
                strokeOpacity: 0.3, //轮廓线透明度
                strokeWeight: 2, //轮廓线宽度
                fillColor: "#1791fc", //多边形填充颜色
                fillOpacity: 0.3, //多边形填充透明度
                strokeStyle: "solid", //线样式还支持 'dashed'
            }
            if (this.chooseType === this.chooseTypeArray[0]) {
                dataVars.mouseTool.rectangle(options)
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                dataVars.mouseTool.circle(options)
            }
        },
        chooseNeedAutoSwitch(type) {
            this.isNeedAutoSwitch = type
        },
        loadKeyDown() {
            document.addEventListener("keydown", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(true)
                }
            })
        },
        loadKeyUp() {
            document.addEventListener("keyup", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(false)
                }
            })
        },
        clearAllMarkers() {
            this.clearLabelsLayer0()
            this.clearLabelsLayer1()
            this.clearParking()
            this.clearParkingLine()
            this.clearMouseTool()
        },
        clearLabelsLayer0() {
            dataVars.labelsLayer0 && dataVars.map.remove(dataVars.labelsLayer0)
            dataVars.labelsLayer0 = null
        },
        clearLabelsLayer1() {
            dataVars.labelsLayer1 && dataVars.map.remove(dataVars.labelsLayer1)
            dataVars.labelsLayer1 = null
        },
        clearParking() {
            for (let i in dataVars.parkingMarker) {
                dataVars.map.remove(dataVars.parkingMarker[i])
            }
            dataVars.parkingMarker = []
        },
        clearParkingLine() {
            for (let i in dataVars.parkingLines) {
                dataVars.map.remove(dataVars.parkingLines[i])
            }
            dataVars.parkingLines = []
        },
        clearMouseTool() {
            this.clearPolygonMarkers()
            this.clearTextMarkers()
            dataVars.mouseTool && dataVars.mouseTool.close(true)
        },
        clearPolygonMarkers() {
            for (let i in dataVars.polygonObjs) {
                dataVars.map.remove(dataVars.polygonObjs[i])
            }
            dataVars.polygonObjs = []
        },
        clearTextMarkers() {
            // 清除画框的文本框
            for (let i in dataVars.textMarkers) {
                dataVars.map.remove(dataVars.textMarkers[i])
            }
            dataVars.textMarkers = []
            // 清除站点点击的文本框
            dataVars.parkTargets = []
            this.mxCloseCommonTextInfo(dataVars.map)
        },
        moveToCenter(item) {
            dataVars.map.setCenter(item.lnglat)
        }
    }
}
</script>